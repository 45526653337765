import * as types from "./mutation-types"

export default({
    [types.GET_CLASSES]: function(state, data){
        state.classes = data;
    },

    [types.GET_ALL_CLASSES]: function(state, data){
        state.all_classes = data;
    } 
})