/* eslint-disable no-unused-vars */
import * as rootTypes from './mutation-types'
import * as authTypes from '../auth/mutation-types'
import * as userTypes from '../users/mutation-types'
import * as classeTypes from '../classes/mutation-types'
import * as coursTypes from '../cours/mutation-types'
import * as matiereTypes from '../matieres/mutation-types'
import * as types from '../../mutation-types'

import Ls from '@/services/ls'
import { Base64 } from 'js-base64'

export const getNotes = ({commit, dispatch}, data) => {
    return new Promise((resolve, reject) => {
        let idCompte = Base64.decode(Ls.get('user.id'))
        window.axios.get('teacher/notes/'+data.idEntite+'/'+idCompte+'/'+data.idCours).then((response) =>  {
            if(!response || response.data.code != 200){
                reject(response)
            }else{
                commit('notes/' + rootTypes.ALL_EVALUATIONS, response.data.data.evaluations, {root : true})
                commit('notes/' + rootTypes.ALL_NOTES, response.data.data.notes, {root : true})
                commit('notes/' + rootTypes.ALL_SESSIONS, response.data.data.sessions, {root : true})
                commit('cours/' + coursTypes.GET_ONE_COURS, response.data.data.cours, {root : true})
                resolve(response)
            }
        }).catch(err => {
            reject(err)
        })
    })
}

export const getNotesByIntervalles = ({commit, dispatch}, value) => {
    return new Promise((resolve, reject) => {
        let idCompte = Base64.decode(Ls.get('user.id'))
        let data = {
            id_entite : value.idEntite,
            id_compte : idCompte,
            id_cours : value.idCours,
            intervalles : value.intervalles
        }
        window.axios.post('teacher/note-by-intervalle', data).then((response) =>  {
            if(!response || response.data.code != 200){
                reject(response)
            }else{
                resolve(response.data)
            }
        }).catch(err => {
            reject(err)
        })
    })
}