/* eslint-disable no-unused-vars */
import * as rootTypes from './mutation-types'
import * as authTypes from '../auth/mutation-types'
import * as userTypes from '../users/mutation-types'
import * as classeTypes from '../classes/mutation-types'
import * as coursTypes from '../cours/mutation-types'
import * as matiereTypes from '../matieres/mutation-types'
import * as types from '../../mutation-types'

import Ls from '@/services/ls'
import { Base64 } from 'js-base64'

export const getEntite = ({commit, dispatch}) => {
    return new Promise((resolve, reject) => {
        let idCompte = Ls.get('user.id')
        window.axios.get('teacher/dashboard/'+idCompte).then((response) =>  {
            if(!response || response.data.code != 200){
                reject(response)
            }else{
                commit('auth/' + authTypes.GET_USERNAME, response.data.data.enseignant, {root : true}) 
                commit('users/' + userTypes.GET_USERS, response.data.data.enseignant, {root : true}) 
                commit('etablissements/' + rootTypes.ALL_ETABLISSEMENT, response.data.data.entites, {root : true})
                commit(types.GET_MENU, response.data.data.menu, {root : true})
                commit(types.GET_ANNEE_ACADEMIQUE, response.data.annee, {root : true})
                //dispatch('getAcessEntite')
                resolve(response)
            }
        }).catch(err => {
            reject(err)
        })
    })
}

export const getAcessEntite = ({commit, dispatch}, data) => {
    return new Promise((resolve, reject) => {
        let idCompte = Base64.decode(Ls.get('user.id'))
        window.axios.get('teacher/entite/'+data+'/'+idCompte).then((response) =>  {
            if(!response || response.data.code != 200){
                reject(response)
            }else{
                commit('classes/' + classeTypes.GET_CLASSES, response.data.data.classes, {root : true}) 
                commit('matieres/' + matiereTypes.GET_MATIERES, response.data.data.matieres, {root : true}) 
                commit('users/' + userTypes.GET_USERS, response.data.data.enseignant, {root : true}) 
                commit('cours/' + coursTypes.GET_HEURE_TOTAL, response.data.data.heuresTotal, {root : true}) 
                commit('cours/' + coursTypes.GET_HEURE_EXECUTE, response.data.data.heuresExecute, {root : true}) 
                commit('cours/' + coursTypes.GET_HEURE_PAS_EXECUTE, response.data.data.heuresPasExecute, {root : true}) 
                commit('cours/' + coursTypes.GET_HEURE_PREVU, response.data.data.heurePrevue, {root : true}) 
                commit('cours/' + coursTypes.GET_COURS_JOURS, response.data.data.coursJours, {root : true})
                commit(types.GET_ANNEE_ACADEMIQUE, response.data.annee, {root : true})
                resolve(response)
            }
        }).catch(err => {
            reject(err)
        })
    })
}