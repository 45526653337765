import { createRouter, createWebHistory } from 'vue-router'
import IndexView from '../views/pages/IndexView.vue'
import EtablissementView from '../views/pages/etablissement/IndexView.vue'
import IndexLoginView from '../views/auth/IndexView.vue'
import LoginView from '../views/auth/LoginView.vue'
import ForgotPassword from '../views/auth/ForgotPassword.vue'
import ResetPassword from '../views/auth/ResetPassword.vue'
import ActivationLoginView from '../views/auth/ValidationView.vue'
import FinalisationLoginView from '../views/auth/FinalisationView.vue'
import EtablissementOneView from '@/views/pages/dashbord/IndexView.vue'
import ProfilView from '@/views/pages/user/IndexView.vue'
import ProfilUser from '@/views/pages/user/ProfilUser.vue'
import PasswordView from '@/views/pages/user/PasswordView.vue'
import CoursView from '@/views/pages/dashbord/cours/CoursView.vue'
import CoursListe from '@/views/pages/dashbord/cours/CoursListe.vue'
import MatiereView from '@/views/pages/dashbord/cours/MatiereView.vue'
import ListePresenceView from '@/views/pages/dashbord/etudiants/ListePresence.vue'
import CoursDetail from '@/views/pages/dashbord/cours/CoursDetail.vue'
import DocView from '@/views/pages/dashbord/docs/DocView.vue'
import StatistiqueView from '@/views/pages/dashbord/statistiques/StatistiqueView.vue'
import CoursJour from '@/views/pages/dashbord/cours/CoursJour.vue'
import SessionNormalView from '@/views/pages/dashbord/notes/SessionNormalView.vue'
import ClasseView from '@/views/pages/dashbord/classes/ClasseView.vue'
import ClasseCoursView from '@/views/pages/dashbord/classes/CoursView.vue'
import EmploiTemps from '@/views/pages/dashbord/cours/EmploiTemps.vue'
import NotFound from '@/views/pages/NotFound.vue'
import store from '@/store'
// import help from '@/helpers/function'


const routes = [
 {
    path: '/',
    name: 'login',
    component: IndexLoginView,
    meta : { redirectIfAuthenticated: true },
    children : [
      {
        path: '',
        name: 'index-login',
        component: LoginView
      },
      {
        path: '/login',
        name: 'index-connexion',
        component: LoginView
      },
      {
        path: '/activation',
        name: 'index-activation',
        component: ActivationLoginView
      },
      {
        path: '/finalisation/:email',
        name: 'index-finalisation',
        component: FinalisationLoginView
      },
      {
        path: '/forgot-password',
        name: 'index-forgot-password',
        component: ForgotPassword
      },
      {
        path: '/reset-password/:email',
        name: 'index-reset-password',
        component: ResetPassword
      }
    ]
  },
  {
    path: '/admin',
    name: 'index-admin',
    component: IndexView,
    meta : {requiresAuth: true},
    children:[
      {
        path: 'etablissement',
        name: 'index-etablissement',
        component: EtablissementView
      },
      {
        path: ':libelle?',
        name: 'index-show-etablissement',
        component: '',
        children : [
          {
            path: '',
            name :'show',
            component:EtablissementOneView,
            children : [
              {
                path : 'index',
                name : 'index-entite',
                component: CoursJour
              },
              {
                path : 'cours', 
                name : 'cours',
                component: CoursView
              },
              {
                path : 'liste-de-vos-cours', 
                name : 'cours-liste',
                component: CoursListe
              },
              {
                path : 'matieres', 
                name : 'matiere',
                component: MatiereView
              },
              {
                path : 'liste-de-presence', 
                name : 'liste_presence',
                component: ListePresenceView
              },
              {
                path : 'cours-details/:id/:dateDebut/:dateFin', 
                name : 'cours-details',
                component: CoursDetail
              },
              {
                path : 'notes-session-normal/:id?',
                name : 'note',
                component: SessionNormalView
              },
              {
                path : 'classe',
                name : 'classe',
                component: ClasseView
              },
              {
                path : 'classe/cours/:classeId',
                name : 'classe-cours',
                component: ClasseCoursView
              },
              {
                path : 'statistique/:coursId',
                name : 'cours-stat',
                component: StatistiqueView
              },
              {
                path : 'emploi-du-temps',
                name : 'emploi-du-temps',
                component: EmploiTemps
              },
              {
                path : 'document-cours/:id',
                name : 'document-cours',
                component: DocView
              },
            ]
          },
          {
            path: 'profil',
            name: 'user',
            component: ProfilView,
            children : [
              {
                path : 'user',
                name : 'user-profil',
                component: ProfilUser
              },
              {
                path : 'password',
                name : 'user-password',
                component: PasswordView
              }
            ]
          }
        ]
      },
    ]
  },
  {
    path : '/:pathMatch(.*)*',
    name: 'NotFound', 
    component: NotFound
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach(async (to, from, next) => {

  if(to.name == 'index-etablissement'){
    window.showBtn = false
  }else{
    window.showBtn = true
  }

  // if(to.matched.some(record => record.meta.requiresAuth)) {
  //     if(!store.getters['auth/getIsAuthenticated']) {
  //       return next('/login')
  //     }
  // }

  if(to.matched.some(record => record.meta.requiresAuth)) {
     // await store.dispatch('testApi')
      if(!store.getters['auth/getIsAuthenticated']) {
        return next('/login')
      }else {
        if(to.name === 'show'){
          return next('/admin/etablissement')
        }
      }
  }

  if(to.matched.some(record => record.meta.redirectIfAuthenticated) && store.getters['auth/getIsAuthenticated']) {
    return next('/admin/etablissement')
  }

  return next()
})

export default router
