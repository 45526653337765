<template>
    <div class="main-card mb-3 card">
        <div class="card-body">
          <div class="row">
            <div class="col-12">
                <div class="row">
                    <div class="col-3">
                        <h6 class="font-weight-bold">Code : </h6>
                    </div>
                    <div class="col-9 text-uppercase">{{ code }}</div>
                </div>
                <div class="row">
                    <div class="col-3">
                        <h6 class="font-weight-bold">Matiere : </h6>
                    </div>
                    <div class="col-9">{{ libelleMatiere }}</div>
                </div>
            </div>
          </div>
        </div>
    </div>
</template>

<script>
export default {
    props : {
        libelleMatiere : {required : true, type : String},
        code : {required : true, type : String},
       
    },
    mounted(){
        
    },
}
</script>