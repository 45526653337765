<template>
    <div class="modal-dialog w-100 mx-auto">
        <div class="modal-content">
            <div class="modal-body">
                <div class="h5 modal-title text-center">
                    <h4 class="mt-2">
                        <div class="">Nouveau mot de passe</div>
                        <span style="font-size: 12px; color:orange" ><i class="fa fa-info-circle"></i> Saisir votre mot de Passe</span>
                    </h4>
                </div>
                <form class="">
                    <div class="form-row">
                        <div class="col-md-12">
                            <label for="">Mot de passe <sup class="text-danger">*</sup> </label>
                            <div class="position-relative form-group">
                                <label for="examplePassword">Mot de Passe<sup class="text-danger">*</sup></label>
                                <input name="password" id="examplePassword"
                                    placeholder="Mot de passe" type="password"
                                    class="form-control" v-model="formFinalisation.password">
                            </div>
                        </div>
                        <div class="col-md-12">
                            <label for="">Confirmer le mot de passe <sup class="text-danger">*</sup> </label>
                            <div class="position-relative form-group">
                                <label for="exampleconfirmPassword">Confirmer le mot de passe<sup class="text-danger">*</sup></label>
                                <input name="confirmPassword" id="exampleconfirmPassword" placeholder="Confirmer le mot de passe"
                                    type="password" class="form-control" v-model="formFinalisation.confirmPassword">
                            </div>
                        </div>
                    </div>
                </form>
                <div class="divider"></div>
                <h6 class="mb-0">Allez à la page de connexion ? <a href="javascript:void(0);" @click.prevent="getConnection" class="text-primary" style="font-size: 12px;">Cliquez ici</a></h6>
            </div>
            <button-validation :isLoading="isLoading" 
                :actionSubmit="actionSubmit" 
                labelFirstButton="Modifier" 
                classFirstButton="btn-sm btn btn-danger mb-3"
                :showSecondButton="false"
                @getActionEvent="getConnection"
                ></button-validation>
        </div>
    </div>
</template>

<script>
    import ButtonValidation from '@/components/widgets/buttons/ButtonValidation.vue';
    import { mapActions } from 'vuex';
    import { Base64 } from 'js-base64';
    export default {
        components: {
            ButtonValidation
        },

        data(){
            return {
                isLoading: false,
                formFinalisation : {
                    email : Base64.decode(this.$route.params.email),
                    confirmPassword : '',
                    password : '',
                }
            }
        },

        methods: {
            ...mapActions('auth', ['finalisationCompte']),

            getConnection(){
                this.$router.push({name: 'index-connexion'})
            },

            async actionSubmit(){
                if(this.formFinalisation.password != this.formFinalisation.confirmPassword){
                    this.isLoading = true;
                    // eslint-disable-next-line no-undef
                    Swal.fire({
                        allowOutsideClick : false,
                        title: "Oops... Une erreur s'est produite.",
                        text: 'Les mots de passe ne correspondent pas.',
                        icon: "error",
                    }).then(() => {

                        this.isLoading = false;
                    })
                }else{
                    this.isLoading = true;
                    await this.finalisationCompte(this.formFinalisation).then((response) => { 
                        this.isLoading = false;
                        if(response.data.code == 200){
                             // eslint-disable-next-line no-undef
                            Swal.fire({
                                allowOutsideClick : false,
                                title: "Completed...",
                                text: 'Mot de passe modifier',
                                icon: "success",
                            }).then((ans) => {
                                if(ans.isConfirmed){
                                    this.$router.push({name:'index-connexion'})
                                }
                            })
                        }else{
                             // eslint-disable-next-line no-undef
                            Swal.fire({
                                allowOutsideClick : false,
                                title: "Oops...",
                                text: 'Un problème est survenu',
                                icon: "error",
                                })
                            }
                    }).catch(() => {
                        this.isLoading = false
                         // eslint-disable-next-line no-undef
                        Swal.fire({
                            allowOutsideClick : false,
                            title: "Oops...",
                            text: `Une erreur s'est produite`,
                            icon: "error",
                        })
                    });
                }
                
            }
        },
    }
</script>