<template>
    <div class="modal-dialog w-100 mx-auto">
        <div class="modal-content">
            <div class="modal-body">
                <div class="h5 modal-title text-center">
                    <h4 class="mt-2">
                        <div class="">Connectez-vous</div>
                        <span style="font-size: 12px; color:orange" ><i class="fa fa-info-circle"></i> Veuillez saisir les bonnes informations</span> <br>
                        <span style="font-size: 15px; color:red" v-if="errorMessage != ''"><i class="fa fa-times-circle"></i> {{ errorMessage }}</span>
                    </h4>
                </div>
                <form class="mb-3">
                    <div class="form-row">
                        <div class="col-md-12">
                            <label for="exampleEmail">Adresse mail <sup class="text-danger">*</sup> </label>
                            <div class="position-relative form-group">
                                <input name="email" id="exampleEmail" placeholder="Email"
                                    type="email" class="form-control" v-model="formLogin.email">
                            </div>
                        </div>
                        <div class="col-md-12">
                            <label for="examplePassword">Mot de passe <sup class="text-danger">*</sup> </label>
                            <div class="position-relative form-group">
                                <input name="password" id="examplePassword"
                                    placeholder="Mot de passe" type="password"
                                    class="form-control" v-model="formLogin.password">
                            </div>
                        </div>
                        <div class="col-md-6">
                            <input type="checkbox" class="mr-2" id="show" @click="showPassword"> <label for="show">Afficher le mot de passe</label> 
                        </div>
                        <div class="col-md-6" style="text-align: right;">
                            <p class="mb-0"><a href="javascript:void(0);" @click.prevent="forgot" class="text-primary" style="font-size: 12px;">Mot de passe oublié ?</a></p>
                        </div>
                    </div>
                </form>
                <div class="row mb-2">
                    <div class="col-md-12">
                        <button-validation :isLoading="isLoading" 
                        :actionSubmit="getConnection" 
                        labelFirstButton="Connexion" 
                        :showSecondButton="false"
                        classFirstButton="btn btn-danger btn-sm mb-1"
                        @getActionEvent="actionSubmit"
                        ></button-validation>
                        <h6 class="mb-0 text-center"><a href="javascript:void(0);" @click.prevent="actionSubmit" class="text-primary" style="font-size: 12px;">Activer votre compte</a></h6>
                    </div>
                </div>
               
            </div>
        </div>
    </div>
</template>

<script>
    import ButtonValidation from '@/components/widgets/buttons/ButtonValidation.vue';
    import { mapActions } from 'vuex';
    export default {
        components: {
            ButtonValidation
        },

        data(){
            return {
                isLoading: false,
                errorMessage : '',
                formLogin : {
                    email : '',
                    password : '',
                }
            }
        },

        methods: {
            ...mapActions('auth', ['connexion']),

            async getConnection(){
                // eslint-disable-next-line no-undef
                $('#exampleEmail').attr('style', 'border-color: ');
                // eslint-disable-next-line no-undef
                $('#examplePassword').attr('style', 'border-color: ');
                this.isLoading = true;
                await this.connexion(this.formLogin).then((response) => { 
                    this.isLoading = false;
                    if(response.data.code == 200){
                        // eslint-disable-next-line no-undef
                        Swal.fire({
                            allowOutsideClick : false,
                        title: "Connexion réussie",
                        text: `${response.data.message}`,
                        icon: "success",
                        }).then((ans) => {
                            if(ans.isConfirmed){
                                this.$router.push({ name: 'index-etablissement' });
                            }
                        })
                    }
               }).catch((err) => {
                   this.isLoading = false
                    if(err.data && err.data.code == 202){
                        this.errorMessage = err.data.message;
                        // eslint-disable-next-line no-undef
                        $('#exampleEmail').attr('style', 'border-color: red');
                        // eslint-disable-next-line no-undef
                        $('#examplePassword').attr('style', 'border-color: red');
                    }else{
                        // eslint-disable-next-line no-undef
                        Swal.fire({
                            allowOutsideClick : false,
                            title: "Oops...",
                            text: `Une erreur s'est produite`,
                            icon: "error",
                        })
                    }
                });
            },

            showPassword(){
                 // eslint-disable-next-line no-undef
                let type = $('#examplePassword').attr('type')
                if(type == "password"){
                    // eslint-disable-next-line no-undef
                    $('#examplePassword').attr('type', 'text')
                }else{
                    // eslint-disable-next-line no-undef
                    $('#examplePassword').attr('type', 'password')
                }
            },

            actionSubmit(){
                this.$router.push({name: 'index-activation'})
            },

            forgot(){
                this.$router.push({name: 'index-forgot-password'})
            }
        },
    }
</script>