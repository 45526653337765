<template>
    <div class="main-card mb-3 card">
        <div class="card-header" style="justify-content: space-between">
            <div>
                <i class="fa fa-graduation-cap header-icon icon-gradient bg-happy-itmeo"> </i>{{ courLibelle }}
            </div>
            <!-- <div v-if="details" class="dropdown d-inline-block">
                <button type="button" aria-haspopup="true" aria-expanded="false" data-toggle="dropdown" class="mb-2 dropdown-toggle btn btn-primary">Actions
                </button>
                <div tabindex="-1" role="menu" aria-hidden="true" class="dropdown-menu-right dropdown-menu-rounded dropdown-menu" style="">
                    <button style="width: 60%;" @click.prevent="coursCont" class="mb-1 dropdown-item">
                        <i class="fa fa-eye mr-1"></i>
                        Contenu du cours
                    </button>
                    <button style="width: 60%;"  @click.prevent="coursStat" class="mb-1 dropdown-item">
                        <i class="fa fa-signal mr-1"></i>
                        Statistique
                    </button>
                </div>
            </div> -->
            <div :class="(countProgramme != 0) ? 'text-warning' : 'text-danger'" style="font-size: 80%;">
                {{ (countProgramme != 0) ? 'Cours programmé' : 'Cours non programmé' }}
            </div>
        </div>
        <div class="card-body">
          <div class="row">
            <div :class="details ? 'col-8' : 'col-12'">
                <div class="row">
                    <div class="col-7">
                        <h6 class="font-weight-bold" style="font-size : 100%">Classe : </h6>
                    </div>
                    <div class="col-5">{{ classDataName }}</div>
                </div>
                <div class="row">
                    <div class="col-7">
                        <h6 class="font-weight-bold" style="font-size : 100%">Mode évaluation : </h6>
                    </div>
                    <div class="col-5 text-uppercase">{{ modeEva }}</div>
                </div>
                <div class="row">
                    <div class="col-7 font-weight-bold">
                        <h6 class="font-weight-bold" style="font-size : 100%">CM/TD : </h6>
                    </div>
                    <div class="col-5">{{ nbhcmtd+" Heures" }}</div>
                </div>
                <div class="row">
                    <div class="col-7 font-weight-bold">
                        <h6 class="font-weight-bold" style="font-size : 100%">TP : </h6>
                    </div>
                    <div class="col-5">{{ nbhtp+" Heures" }}</div>
                </div>
                <div class="row">
                    <div class="col-7 font-weight-bold">
                        <h6 class="font-weight-bold" style="font-size : 100%">Hrs. Totales : </h6>
                    </div>
                    <div class="col-4 badge badge-danger mb-2">{{ nbhtotal+" Heures" }}</div>
                </div>
                <div class="row">
                    <div class="col-7 font-weight-bold">
                        <h6 class="font-weight-bold" style="font-size : 100%">Hrs. Programmées : </h6>
                    </div>
                    <div class="col-4 badge badge-success mb-2">{{ heureProg+" Heures" }}</div>
                </div>
                <div class="row">
                    <div class="col-7 font-weight-bold">
                        <h6 class="font-weight-bold" style="font-size : 100%">Hrs. Exécutées : </h6>
                    </div>
                    <div class="col-4 badge badge-primary mb-2">{{ heureExe+" Heures" }}</div>
                </div>
            </div>
            <div  v-if="details" class="col-4" id="progress" :class="className"></div>
          </div>
        </div>
        <div v-if="details" class="d-block text-center card-footer">
            <div class="row">
                <div class="col-4 mb-2">
                    <button @click.prevent="programme" class="btn-icon-wrapper btn btn-primary">
                        <i class="fa fa-clock"></i>
                        Scéances programmées
                    </button>
                </div>
                <div class="col-4 mb-2">
                    <button @click.prevent="document" class="btn-icon-wrapper btn btn-secondary">
                        <i class="fa fa-book"></i>
                        Document de cours
                    </button>
                </div>
                <div class="col-4 mb-2">
                    <button @click.prevent="coursCont" class="btn-icon-wrapper btn btn-success">
                        <i class="fa fa-eye mr-1"></i>
                        Contenu du cours
                    </button>
                </div>
                <div class="col-4">
                    <button @click.prevent="coursStat" class="btn-icon-wrapper btn btn-danger">
                        <i class="fa fa-signal mr-1"></i>
                        Statistique
                    </button>
                </div>
                <div class="col-4">
                    <div class="dropdown d-inline-block show">
                        <button type="button" aria-haspopup="true" aria-expanded="true" data-toggle="dropdown" class="dropdown-toggle btn-icon-wrapper btn btn-success">
                            <i class="fa fa-sticky-note"></i>
                            Notes
                        </button>
                        <div tabindex="-1" role="menu" aria-hidden="true" class="dropdown-menu-md dropdown-menu dropdown-menu-right" style="position: absolute;transform: translate3d(-292px, 33px, 0px);top: 0px;left: 0px; will-change: transform;" x-placement="bottom-end">
                            <ul class="nav flex-column">
                                <li class="nav-item">
                                    <a href="" @click.prevent="note" class="nav-link">
                                        <span>Session Normale</span>
                                    </a>
                                </li>                            
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div v-if="details2" class="d-block text-center card-footer">
            <div class="row">
                <div class="col-lg-6">
                    <button @click.prevent="document" class="btn-icon-wrapper btn btn-warning">
                        <i class="fa fa-book"></i>
                        Document de cours
                    </button>
                </div>
                <div class="col-lg-6">
                    <button  @click.prevent="coursCont" class="btn-icon-wrapper btn btn-success">
                        <i class="fa fa-sticky-note"></i>
                       Contenu et programme par scéance
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props : {
        className : {required : true, type : String},
        classDataName : {required : true, type : String},
        courLibelle : {required : true, type : String},
        nbhtotal : {required : true, type : String},
        nbhcmtd : {required : true, type : String},
        nbhtp : {required : true, type : String},
        countProgramme : {required : true, type : Number},
        modeEva : {required : true, type : String},
        heureExe : {required : true, type : String},
        heureProg : {required : true, type : String},
        pource : {required : true, type : String},
        details : {default : true},
        details2 : {default : false},
    },
    mounted(){
        if(this.details){
        // eslint-disable-next-line no-undef
        let circle = new ProgressBar.Circle('.'+this.className, {
            color : '#008080',
            trailColor : '#d92550',
            strokeWidth: 5,
            trailWidth: 5,
            text : {
                value : new Intl.NumberFormat().format((this.heureExe*100)/this.nbhtotal)+'%'+`<br> <span style="text-weight : bold">Exécuté</span>`,
                alignToBottom: false,
                style: {
                    color: '#000000',
                    position: 'absolute',
                    left: '40%',
                    top: '20%',
                    padding: 0,
                    margin: 0,
                },
            },
            svgStyle: {
                width: '100%'
            },
        })
        circle.animate(parseFloat(this.pource), {duration:2000})
        }
       
    },

    methods : {
        document(){
            this.$emit('doc');
        },
        programme(){
            this.$emit('prog');
        },
        note(){
            this.$emit('note');
        },
        coursCont(){
            this.$emit('count');
        },
        coursStat(){
            this.$emit('statistique');
        },
    }
}
</script>