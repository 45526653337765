<template>
    <div class="row" style="margin-left: 0px; margin-right : 0px;">
        <div class="col-md-12">
            <div class="card main-card py-2 bg-info mb-2" style="opacity : 0.7;">
                <div class="card-body text-white">
                    <div class="d-flex" style="justify-content: center;">
                        <i class="fa fa-info-circle" style="width: 3%; font-size : 150%"></i>
                        <h6>Les cours en tronc commun sont comptabilisés une seule fois</h6>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="row" style="margin-left: 0px; margin-right : 0px;">
        <!-- <card-stat title="Matières" subtitle="Les matières" :count="getMatieres.length" bgColor="bg-asteroid"></card-stat> -->
        <card-stat title="Matières" subtitle="Les matières" :count="getMatieres.length"
            bgColor="bg-warning"></card-stat>
        <card-stat title="Classes" subtitle="Les classes" :count="getClasses.length" bgColor="bg-success"></card-stat>
        <!-- <card-stat title="Heure Totale" subtitle="Heure prévue" :count="(getHeuresTotalPrevu) ? getHeuresTotalPrevu + 'H' : 0" bgColor="bg-night-sky"></card-stat> -->
        <card-stat title="Heures Programmées" subtitle="Heures programmées"
            :count="(getHeuresTotal) ? getHeuresTotal + 'H' : 0" bgColor="bg-danger"></card-stat>
        <card-stat title="Heures Exécutées" subtitle="Heures exécutées"
            :count="(getHeuresExecute) ? getHeuresExecute + 'H' : 0" bgColor="bg-primary"></card-stat>
        <!-- <card-stat title="Heures Restantes" subtitle="Heures restantes" :count="(getHeuresPasExecute) ? getHeuresPasExecute + 'H' : 0" bgColor="bg-warning"></card-stat> -->
    </div>

    <!-- Présentation des statistiques des heures cummulées -->
    <h5 class="border-bottom font-weight-bold">Point des heures cummulées</h5>
    <div style="height: 10%;">
        <card-graphe title="Point des heures cummulées" idElement="heureCumul" :value="graphUpdate"
            :colorGraph="['#5470c6', '#91cc75', '#ee6666']" onGraph="circle" height="250px"></card-graphe>
    </div>

    <!-- Programme du jour -->
    <h5 class="border-bottom font-weight-bold">Programme du jour</h5>
    <table-simple :input-classe="false" :periode="false" :action="action" :isloading="isloading"
        title1="Liste de présence" title2="" :actionButton="getData"
        buttonOptionSup="Consulter l'emploi du temps de la semaine" noData="Vous n'avez aucun cours pour aujourd'hui"
        :tableTitle="tableTitle" :cle="cle" :header="header" :body="getCoursJours"></table-simple>
</template>

<script>
import TableSimple from '@/components/tables/TableSimple.vue';
import CardStat from '@/components/widgets/cards/CardStat.vue';
import CardGraphe from '@/components/widgets/cards/CardGraphe.vue';
import {
    mapGetters, mapActions
} from 'vuex'
import Ls from '@/services/ls'
import { Base64 } from 'js-base64'
export default {
    emits: ['getBreadcrumb'],
    components: {
        TableSimple,
        CardStat,
        CardGraphe
    },

    data() {
        return {
            idEnseigner: '',
            listeAbsent: '',
            isloading: false,
            bodyModal: "",
            tableTitle: 'Consultez vos cours de ce jour',
            body: [],
            grapheValue: [],
            classe: '',
            action: [
                {
                    class: 'fa fa-list-alt',
                    function: this.detailsClasse({ action: 'presence', instance: this }),
                    title: 'Liste de présence',
                    element: ["siExecute", "siAnnuler"],
                    condition: [true, false],
                    type: 'button',
                    buttonText: 'Liste de présence',
                    buttonclasse: 'btn btn-warning btn-sm',

                },
                {
                    class: 'fa fa-edit',
                    function: this.detailsClasse({ action: 'cahier', instance: this }),
                    title: 'Cahier de texte',
                    element: ["siExecute", "siAnnuler"],
                    condition: "",
                    type: 'button',
                    buttonText: 'Cahier de texte',
                    buttonclasse: 'btn btn-danger btn-sm',

                },
            ],
            cle: [
                {
                    libelle: "classe",
                    classe: 'text-center',
                    type: 'text',
                    count: 1
                },
                {
                    libelle: "libelleMatiere",
                    classe: 'text-center',
                    type: 'text',
                    count: 1
                },
                {
                    libelle: 'horaire',
                    classe: 'text-center',
                    type: 'text',
                    count: 1
                },
                {
                    libelle: "SalleName",
                    classe: 'text-center',
                    type: 'text',
                    count: 1
                },
                {
                    libelle: ["siExecute", "siAnnuler"],
                    value: ['Exécuté', 'Annulé', 'Non exécuté'],
                    type: 'bool',
                    count: 2
                },
            ],
            header: [
                {
                    classe: '',
                    libelle: 'Classes',
                },
                {
                    classe: '',
                    libelle: 'Matières',
                },
                {
                    classe: '',
                    libelle: 'Horaires',
                },
                {
                    classe: '',
                    libelle: 'Salles',
                },
                {
                    classe: '',
                    libelle: 'Statut',
                },
            ],
            objects: {
                breadcrumb: [
                    {
                        libelle: 'Etablissement',
                        name: "index-etablissement",
                        params: { libelle: this.$route.params.libelle }
                    },
                    {
                        libelle: this.$route.params.libelle,
                        name: "index-entite",
                        params: { libelle: this.$route.params.libelle }
                    },
                    {
                        libelle: 'Tableau de bord',
                        name: "index-etablissement",
                        params: { libelle: this.$route.params.libelle }
                    },
                ],
                titlePage: 'Tableau de bord (' + this.$route.params.libelle + ')',
                icon: 'fa fa-school'
            }
        }
    },

    computed: {
        ...mapGetters('etablissements', ['getEtablissement']),
        ...mapGetters('matieres', ['getMatieres']),
        ...mapGetters('etudiants', ['getEtudiants']),
        ...mapGetters(['getDataMenu']),
        ...mapGetters('classes', ['getClasses']),
        ...mapGetters('cours', ['getCoursJours', 'getHeuresTotal', 'getHeuresExecute', 'getHeuresPasExecute', 'getHeuresTotalPrevu']),

        graphUpdate() {
            return [
                { value: this.getHeuresTotal, name: 'Heure Total' },
                { value: this.getHeuresExecute, name: 'Heure Exécutée' },
                { value: this.getHeuresPasExecute, name: 'Heure Non Exécutée' },
            ];

            //return this.grapheValue;
        }
    },

    methods: {
        ...mapActions('classes', ['getClasse']),
        ...mapActions('cours', ['getCoursPeriode', 'sendDocumentCours']),
        ...mapActions('etudiants', ['getEtudiantClasse', 'listePresenceEtudiant']),
        ...mapActions('etablissements', ['getEntite']),


        async getData() {
            this.isloading = true;
            var elementHTML = this.$footerModalButton('fermer_3', 'sauvegarde_3', 'loading')
            // eslint-disable-next-line no-undef
            $('.modal-footer').html(elementHTML)
            // eslint-disable-next-line no-undef
            let date = moment().weekday(1).format("YYYY-MM-DD");
            // eslint-disable-next-line no-undef
            let date2 = moment().weekday(7).format("YYYY-MM-DD");
            let data = {
                idEntite: this.getEtablissement(this.$route.params.libelle).id,
                debutPeriode: date,
                finPeriode: date2,
                withCours: 'programme'
            }
            // eslint-disable-next-line no-undef
            $('#formModal').attr('class', 'modal-dialog modal-lg');
            // eslint-disable-next-line no-undef
            $('#exampleModalLabel').html('Emploi du temps de la semaine');
            // eslint-disable-next-line no-undef
            $("#fermer_3").html('Fermer');
            // eslint-disable-next-line no-undef
            $("#sauvegarde_3").hide();
            await this.getCoursPeriode(data).then((response) => {
                this.isloading = false;
                // eslint-disable-next-line no-undef
                $('#bodyModal').html(this.$tabTemplateForModal(this.header, response.data.data.programme, this.cle, 'cours'));
                // eslint-disable-next-line no-undef
                $("#declenche").trigger('click');
            }).catch(() => {
                this.isloading = true;
                // eslint-disable-next-line no-undef
                Swal.fire({
                    allowOutsideClick: false,
                    title: "Oops...",
                    text: `Une erreur s'est produite`,
                    icon: "error",
                })
            })

        },

        detailsClasse: (object) => (e) => {
            var elementHTML =  object.instance.$footerModalButton('fermer_2', 'sauvegarde_2', 'loading')
            // eslint-disable-next-line no-undef
            $('.modal-footer').html(elementHTML)
            let idCours = e.target.getAttribute('data-id')
            object.instance.idEnseigner = e.target.getAttribute('data-enseigner')
            // eslint-disable-next-line no-undef
            let sauvegarde = $("#sauvegarde_2");
            // eslint-disable-next-line no-undef
            let fermer = $("#fermer_2");
            // eslint-disable-next-line no-undef
            let bodyModal = $("#bodyModal");
            // eslint-disable-next-line no-undef
            let formModal = $("#formModal");
            // eslint-disable-next-line no-undef
            let declenche = $("#declenche");
            object.instance.getInfosOfClasse(idCours)
            bodyModal.html('');
            formModal.attr('class', 'modal-dialog modal-lg');
            fermer.html('Annuler');
            sauvegarde.html("").hide();
            if (object.action == "cahier") {
                // eslint-disable-next-line no-undef
                $('#exampleModalLabel').html('Cahier de texte');
                object.instance.body = object.instance.getCoursJours.filter((item) => item.idEnseigner == object.instance.idEnseigner)
                bodyModal.html(`<div class="d-flex">
                                    <h6 class="font-weight-bold">Titre du cours : </h6>
                                    <p style="margin-left : 2%">${object.instance.body[0]['libelleMatiere']}</p>
                                </div>
                                <div class="d-flex">
                                    <h6 class="font-weight-bold">Horaires du cours : </h6>
                                    <p style="margin-left : 2%">${object.instance.body[0]['horaire']}</p>
                                </div>
                                <div class="d-flex">
                                    <h6 class="font-weight-bold">Salle de déroulement du cours : </h6>
                                    <p style="margin-left : 2%">${object.instance.body[0]['SalleName']}</p>
                                </div>
                                <div>
                                    <h6 class="font-weight-bold">Contenu : </h6>
                                </div>
                            `);
                bodyModal.append(`<p style="margin-left : 3%">` + object.instance.body[0]['observations'] + `</p>`);
                declenche.trigger('click');
            } else {
                sauvegarde.html("Valider").show();
                object.instance.openModalEtudiant(bodyModal, declenche)
            }

        },

        openModalEtudiant(bodyModal, declenche) {
            this.$charging()
            let cle = [
                {
                    libelle: "matricule",
                    classe: '',
                    type: 'text',
                    count: 1
                },
                {
                    libelle: "nom",
                    classe: '',
                    type: 'text',
                    count: 1
                },
                {
                    libelle: "prenom",
                    classe: '',
                    type: 'text',
                    count: 1
                },
            ]

            let header = [
                {
                    classe: '',
                    libelle: 'Matricule',
                },
                {
                    classe: '',
                    libelle: 'Nom',
                },
                {
                    classe: '',
                    libelle: 'Prénom',
                },
            ]
            let data = {
                idClasse: this.classe.id,
                idEntite: this.getEtablissement(this.$route.params.libelle).id,
                idEnseigner: this.idEnseigner,
            }
            this.getEtudiantClasse(data).then((response) => {
                // eslint-disable-next-line no-undef
                $('#exampleModalLabel').html('Liste de présence de la classe ' + this.classe.libelle);
                bodyModal.html(this.$tabTemplateForModal(header, response.data.data.etudiants, cle, 'presence'));
                // eslint-disable-next-line no-undef
                $('#tab2').DataTable()
                declenche.trigger('click');
                this.$unCharging()
            }).catch((error) => {
                // eslint-disable-next-line no-undef
                Swal.fire({
                    allowOutsideClick: false,
                    title: "Oops...",
                    text: `${error.data.message}`,
                    icon: "error",
                })
                this.$unCharging()
            })
        },

        getInfosOfClasse(idCours) {
            this.classe = this.getClasses.filter((item) => item.libelle == this.getCoursJours.filter((element) => element.id == idCours)[0].classe)[0]
        },

        getBreadcrumb() {
            this.$emit('getBreadcrumb', this.objects)
        },

        changePresenceEtudiant() {
            // eslint-disable-next-line no-undef
            $(document).on('change', '.presence', (event) => {
                let idEtudiant = event.target.value
                this.getEtudiants.map((item) => {
                    if (item.id == idEtudiant) {
                        item.presence = !item.presence
                    }
                })
                this.listeAbsent = this.getEtudiants.filter((item) => item.presence == false).map((element) => element.idInscription)
            })
        },

        sendListControle() {
            let idCompte = Base64.decode(Ls.get('user.id'))
            // eslint-disable-next-line no-undef
            $('#sauvegarde').on('click', () => {
                this.$charging()
                let data = {
                    listAbsent: this.listeAbsent,
                    id_enseigner: this.idEnseigner,
                    id_compte: idCompte,
                    id_entite: this.getEtablissement(this.$route.params.libelle).id,
                }
                // eslint-disable-next-line no-undef
                $('#fermer').trigger('click');
                this.listePresenceEtudiant(data).then((response) => {
                    // eslint-disable-next-line no-undef
                    Swal.fire({
                        allowOutsideClick: false,
                        title: "Success",
                        text: `${response.data.message}`,
                        icon: "success",
                    })
                    this.$unCharging()
                }).catch((error) => {
                    // eslint-disable-next-line no-undef
                    Swal.fire({
                        allowOutsideClick: false,
                        title: "Oops...",
                        text: `${error.data.message}`,
                        icon: "error",
                    })
                    this.$unCharging()
                })
            })
        }
    },

    async beforeMount() {
        this.grapheValue = [
            { value: this.getHeuresTotal, name: 'Heure Total' },
            { value: this.getHeuresExecute, name: 'Heure Exécutée' },
            { value: this.getHeuresPasExecute, name: 'Heure Non Exécutée' },
        ]
    },

    async mounted() {
        // eslint-disable-next-line no-undef
        // let toast = toastr
        // toast.options.progressBar = true;
        // toast.options.positionClass = "toast-bottom-full-width"
        // toast.info('', 'Information')
        this.listeAbsent = await this.getEtudiants.filter((item) => item.presence == false).map((element) => element.idInscription)
        this.changePresenceEtudiant()
        this.sendListControle()
        // this.grapheValue = [
        //     { value: this.getHeuresTotal, name: 'Heure Total' },
        //     { value: this.getHeuresExecute, name: 'Heure Exécutée' },
        //     { value: this.getHeuresPasExecute, name: 'Heure Non Exécutée' },
        // ]
    },

    created() {
        this.getBreadcrumb()
    },
}
</script>
