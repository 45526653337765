<template>
    <div class="" style="margin-left: 15px; margin-right : 15px;">
        <div class="">
            <ul class="body-tabs body-tabs-layout tabs-animated body-tabs-animated nav" style="text-transform : uppercase">
                <li class="nav-item">
                    <a @click="activeTabs('absenceTabs')" role="tab" class="nav-link" :class="{'active' : absenceTabs}" href="#">
                        <span class="font-weight-bold">Statistique d'absence</span>
                    </a>
                </li>
                <!-- <li class="nav-item">
                    <a @click="activeTabs('noteTabs')" role="tab" class="nav-link" :class="{'active' : noteTabs}" href="#">
                        <span class=" font-weight-bold">Statistique des moyennes</span>
                    </a>
                </li> -->
                <li class="nav-item">
                    <a @click="activeTabs('coursTabs')" role="tab" class="nav-link" :class="{'active' : coursTabs}" href="#">
                        <span class="font-weight-bold">Evolution du cours</span>
                    </a>
                </li>
            </ul><hr style="margin-top : -10px">
        </div>
    </div>
    
    <Transition>
        <div class="row" v-if="absenceTabs" style="margin-left: 0px; margin-right : 0px;">
            <div class="col-12">
                <div class="card mb-3">
                    <div class="card-header" style="justify-content: space-between">
                        <div>
                            <i class="fa fa-signal header-icon icon-gradient bg-happy-itmeo"> </i>{{ titleHeader }}
                        </div>
                        <div>
                            <!-- <button @click="changeStat()" class="btn-icon-wrapper btn btn-primary mr-2 mb-1">
                                <i class="fa fa-signal mr-1"></i> {{ buttonTitle }}
                            </button> -->
                            <button @click="recapitulatifEtudiant()" class="btn-icon-wrapper btn btn-danger mb-1">
                            <i class="fa fa-eye mr-1"></i> Afficher le récapitulatif 
                        </button>
                        </div>
                        
                    </div>
                    <div class="card-body">
                        <div id="stat"></div>
                    </div>
                </div>
            </div>
        </div> 
        <div class="row" v-else-if="coursTabs" style="margin-left: 0px; margin-right : 0px;">
            <div class="col-4">
                <div class="card mb-3">
                    <div class="card-header" style="justify-content: space-between">
                        <div>
                            <i class="fa fa-signal header-icon icon-gradient bg-happy-itmeo"> </i>Heure Prévue et Heure Programmée
                        </div>
                    </div>
                    <div class="card-body">
                        <div id="stat"></div>
                    </div>
                </div>
            </div>
            <div class="col-4">
                <div class="card mb-3">
                    <div class="card-header" style="justify-content: space-between">
                        <div>
                            <i class="fa fa-signal header-icon icon-gradient bg-happy-itmeo"> </i>Heure Programmée et Heure Exécutée
                        </div>
                    </div>
                    <div class="card-body">
                        <div id="stat1"></div>
                    </div>
                </div>
            </div>
            <div class="col-4">
                <div class="card mb-3">
                    <div class="card-header" style="justify-content: space-between">
                        <div>
                            <i class="fa fa-signal header-icon icon-gradient bg-happy-itmeo"> </i>Heure Prévue et Heure Exécutée
                        </div>
                    </div>
                    <div class="card-body">
                        <div id="stat2"></div>
                    </div>
                </div>
            </div>
        </div>
        <!-- <div v-else class="row" style="margin-left: 0px; margin-right : 0px;">
            <div class="col-12">
                <div v-if="getAllNotes != null" class="card mb-3">
                    <div class="card-header" style="justify-content: space-between">
                        <div>
                            <i class="fa fa-signal header-icon icon-gradient bg-happy-itmeo"> </i>{{ titleHeader }}
                        </div>
                        <div>
                            <button @click="changeStat()" class="btn-icon-wrapper btn btn-primary mr-2 mb-1">
                                <i class="fa fa-signal mr-1"></i> {{ buttonTitle }}
                            </button>
                            <button @click="recapitulatifMoyenne()" class="btn-icon-wrapper btn btn-danger mb-1">
                            <i class="fa fa-eye mr-1"></i> Afficher le récapitulatif 
                        </button>
                        </div>
                        
                    </div>
                    <div class="card-body">
                        <div id="stat"></div>
                    </div>
                </div>
                <div v-else class="col-md-12">
                    <div class="main-card mb-3 card">
                        <div class="card-body">
                            <div class="text-center">
                            <h6 class="font-weight-bold text-danger">
                                <i class="fa fa-info-circle"></i>
                                Aucune évaluation validée</h6>
                                <p class="text-muted">Aucune évaluation n'a été validée pour cette matière</p>  
                            </div>
                        </div>
                    </div>
                </div>
            </div>    
        </div> -->
    </Transition>

</template>

<style scoped>
    .v-enter-active {
        transition: opacity 0.5s ease;
    }
    /* .v-leave-active {
        
    } */

    .v-enter-from, .v-leave-to {
        opacity: 0;
    }
</style>

<script>
import { mapGetters, mapActions } from 'vuex';
export default {
    emits: ['getBreadcrumb', 'getButtonRetour'],
    components: {
       
    },

    data() {
        return {
            moyenne : '',
            isloading: false,
            absenceTabs: true,
            coursTabs: false,
            noteTabs: false,
            titleHeader : 'Statistique Programmation/Nombre d\'absence',
            graph: true,
            response : [],
            mode : 'programmation',
            buttonTitle : 'Graph Etudiant/Absence',
            response2 : [],
            cours : '',
            objects : {
                breadcrumb: [
                    {
                        libelle: 'Etablissement',
                        name: "index-etablissement",
                        params: { libelle: this.$route.params.libelle }
                    },
                    {
                        libelle : this.$route.params.libelle,
                        name : "index-entite",
                        params : {libelle : this.$route.params.libelle}
                    },
                    {
                        libelle:  'Gestion des cours',
                        name: "cours"
                    },
                ],
                titlePage : 'Statistiques',
                icon : 'fa fa-signal'
            }
        }
    },

    async mounted() {
        this.$nextTick(async () => {
            this.$charging()
            this.getStatistiquePresenceCoursGlobal()
            this.getStatistiquePresenceCoursInscription()
            if(this.getCoursSimple.length == 0){
                let data = {
                    idEntite: this.getEtablissement(this.$route.params.libelle).id,
                    debutPeriode: 'null',
                    finPeriode: 'null',
                    withCours: 'cours'
                }

                await this.getCoursPeriode(data).then(async () => {
                    this.$unCharging()
                    this.cours = this.getCoursSimple.filter((item) => item.id == this.$route.params.coursId)[0]
                    this.objects.titlePage = "Statistique du cours "+ this.cours['libelleMatiere']
                    this.getBreadcrumb()
                });
            }else{
                this.cours = this.getCoursSimple.filter((item) => item.id == this.$route.params.coursId)[0]
                this.objects.titlePage = "Statistique du cours "+ this.cours['libelleMatiere']+" pour la classe "+this.cours['classe']
                this.getBreadcrumb()
            }
            this.$unCharging()
        })
    },

    computed: {
        ...mapGetters('cours', ['getCoursSimple']),
        ...mapGetters('etablissements', ['getEtablissement']),
        ...mapGetters('notes', ['getAllNotes']),
    },

    methods: {
        ...mapActions('cours', ['getStatPresenceCours', 'getCoursPeriode']),
        ...mapActions('notes', ['getNotes']),

        async getStatistiquePresenceCoursGlobal(){
            if(this.response.length != 0){
                this.$charging()
                setTimeout(() => {
                    this.changeStat()
                    this.$unCharging()
                }, 2000)
            }else{
                let data = {
                    idEntite: this.getEtablissement(this.$route.params.libelle).id,
                    idCours : this.$route.params.coursId,
                    variable : 'global'
                }
                await this.getStatPresenceCours(data).then((response) => {
                    this.response = response.data
                    this.changeStat()
                })
            }
        },

        async getStatistiquePresenceCoursInscription(){
            if(this.response2.length == 0){
                let data2 = {
                    idEntite: this.getEtablissement(this.$route.params.libelle).id,
                    idCours : this.$route.params.coursId,
                    variable : 'inscription'
                }

                await this.getStatPresenceCours(data2).then((response) => {
                    this.response2 = response.data
                })
            }
        },
        
        async getStatistiqueEvolutionCours(){
            this.$charging()
            setTimeout(() => {
                this.changeStat()
                this.$unCharging()
            }, 2000)
        },

        async getStatistiqueMoyenneEtudiant(){
            this.$charging()
            if(this.getAllNotes.length != 0){
                setTimeout(() => {
                    this.changeStat()
                    this.$unCharging()
                }, 2000)
            }else{
                let data = {
                    idCours : this.$route.params.coursId,
                    idEntite : this.getEtablissement(this.$route.params.libelle).id,
                }
                await this.getNotes(data).then((response) => {
                    this.tableShow = true
                    if(response.data.data.length != 0){
                        this.mode = "moyenneEtu"
                        this.changeStat()
                    }else{
                        this.getAllNotes = []
                    }
                    this.$unCharging()

                }).catch(() => {
                    this.tableShow = true
                    // eslint-disable-next-line no-undef
                    Swal.fire({
                        allowOutsideClick : false,
                        title: "Oops...",
                        text: `Une erreur s'est produite`,
                        icon: "error",
                    })
                    this.$unCharging()
                   
                })
            }
        },

        changeStat(){ 
            if(this.mode == "etudiant"){
                this.mode = "programmation"
                this.buttonTitle = "Séance/Absence"
                this.titleHeader = "Statistique d'absence"
                let nbre = this.response2['liste'].map((item) => item['nbrPresence'])
                let nom = this.response2['liste'].map((item) => item['nom']+" "+item['prenom'])
                this.$chartVertical({name: "ABSENCES",type: 'value'}, {name: "ETUDIANTS", type: 'category', data: nom}, nbre, 'stat' , "rgb(0, 128, 128, 0.2)", "STATISTIQUE D'ABSENCE D'UN ETUDIANT", null, "Etudiant/Absence")
            }else if(this.mode == "programmation"){
                //this.mode = "etudiant"
                //this.buttonTitle = "Etudiant/Absence"
                this.titleHeader = "Statistique d'absence"
                let nbre = this.response['liste'].map((item) => item['nbrPresence'])
                let cours = this.response['liste'].map((item) => this.formatDate(item['dateCours']))
                this.$chartVertical({name: "SCEANCES", type: 'category', data: cours, axisLabel: { interval: 0, rotate: 30 }}, {name: "ABSENCE", type: 'value'}, nbre, 'stat', 'rgb(0, 128, 128, 0.2)', "STATISTIQUE DES ABSENCES PAR SEANCE", null, "Séance/Absence")
            }else if(this.mode == "moyenneEtu"){
                this.mode = "moyenneNbre"
                let nbre = this.getAllNotes.map((item) => (item['moyenne'] >= item['moyenneValid']) ? parseFloat(item['moyenne'].replace(',', '.')) : {value: parseInt(item['moyenne'].replace(',', '.')), itemStyle: {color: '#d92550' }}).reverse()
                this.buttonTitle = "Moyenne/Effectif"
                this.titleHeader = "Statistique des moyennes"
                let nom = this.getAllNotes.map((item) => item['etudiant']['nom']+" "+item['etudiant']['prenom']).reverse()
                this.$chartVertical({name: "MOYENNES",type: 'value'}, {name: "ETUDIANTS", type: 'category', data: nom}, nbre, 'stat' , "rgb(0, 128, 128, 0.2)",  "STATISTIQUE DES MOYENNES OBTENU PAR ETUDIANT",null, "Etudiant/Moyenne")
            }else if(this.mode == "moyenneNbre"){
                this.mode = "moyenneEtu"
                this.buttonTitle = "Etudiant/Moyenne"
                this.titleHeader = "Statistique des moyennes"
                let moy = this.getNewTable("index")
                let nbre = this.getNewTable("data")
                this.$chartVertical({name: "MOYENNES", type: 'category', data: moy, axisLabel: { interval: 0, rotate: 30 }}, {name: "TOTAL", type: 'value'}, nbre, 'stat', 'rgb(0, 128, 128, 0.2)',  "STATISTIQUE DES MOYENNES DE LA FILIERE", null, "Moyenne/Effectif")
            }else{
                this.titleHeader = "Evolution du cours"
                let data = [
                    {value : this.cours['heureProgramme'], name : 'Heure Programmée'},
                    //{value : this.cours['nbhcmtd'] - this.cours['heureProgramme'], name : 'Heure Non Programmée'},
                    {value : this.cours['nbhcmtd'], name : 'Heure Prévue'},
                ]
                let data1 = [
                    {value : this.cours['heureProgramme'], name : 'Heure Programmée'},
                    //{value : this.cours['nbhcmtd'] - this.cours['heureProgramme'], name : 'Heure Non Programmée'},
                    {value : this.cours['heureExecute'], name : 'Heure Exécutée'},
                ]
                let data2 = [
                    //{value : this.cours['nbhcmtd'] - this.cours['heureProgramme'], name : 'Heure Non Programmée'},
                    {value : this.cours['heureExecute'], name : 'Heure Exécutée'},
                    {value : this.cours['nbhcmtd'], name : 'Heure Prévue'},
                ]
                this.$chartCircle('stat', data, ['#5470c6', '#91cc75'])
                this.$chartCircle('stat1',data1, ['#fac858', '#ee6666'])
                this.$chartCircle('stat2',data2,  ['#fc8452', '#9a60b4'])
            }
        },

        recapitulatifEtudiant(){
            // eslint-disable-next-line no-undef
            //$('#stat').html('')
            let cle = [
                {
                    libelle: "matricule",
                    classe: 'text-center',
                    type: 'text',
                    count: 1
                },
                {
                    libelle: "nom",
                    classe: 'text-center',
                    type: 'text',
                    count: 1
                },
                {
                    libelle: "prenom",
                    classe: 'text-center',
                    type: 'text',
                    count: 1
                },
                {
                    libelle: "nbrPresence",
                    classe: 'text-center',
                    type: 'text',
                    count: 1
                },
            ]

            let header = [
                {
                    classe: '',
                    libelle: 'Matricule',
                },
                {
                    classe: '',
                    libelle: 'Nom',
                },
                {
                    classe: '',
                    libelle: 'Prénom',
                },
                {
                    classe: '',
                    libelle: 'Nombre d\'absence',
                },
            ]
            // eslint-disable-next-line no-undef
            $('#sauvegarde').html("").hide();
            // eslint-disable-next-line no-undef
            $('#formModal').attr('class', 'modal-dialog modal-lg');
            // eslint-disable-next-line no-undef
            $('#fermer').html('Fermer');
            // eslint-disable-next-line no-undef
            $('#exampleModalLabel').html('Point de la présence pour chaque étudiant');
            // eslint-disable-next-line no-undef
            $("#bodyModal").html(this.$tabTemplateForModal(header, this.response2['liste'], cle, 'recapEtu'));
            // eslint-disable-next-line no-undef
            $('#tab2').DataTable()
            // eslint-disable-next-line no-undef
             $("#declenche").trigger('click');
        },

        recapitulatifMoyenne(){
            let moyenneValid = parseFloat(this.getAllNotes[0]['moyenneValid'].replace(',', '.'))
            // eslint-disable-next-line no-undef
            $('#bodyModal').html('')
            let moyenne = this.getAllNotes.map((item) => parseFloat(item['moyenne'].replace(',', '.')))
            let nbrFort = moyenne.filter((item) => item >= moyenneValid)
            let nbrFaible = moyenne.filter((item) => item < moyenneValid)
            // eslint-disable-next-line no-undef
            let min = _.min(moyenne)
            // eslint-disable-next-line no-undef
            let max = _.max(moyenne)
            // eslint-disable-next-line no-undef
            let moy = _.mean(moyenne)
            // eslint-disable-next-line no-undef
            $('#exampleModalLabel').html('Récapitulatif de la moyenne')
            // eslint-disable-next-line no-undef
            $('#fermer').html('Fermer').show()
            // eslint-disable-next-line no-undef
            $('#sauvegarde').hide()
            // eslint-disable-next-line no-undef
            $("#formModal").attr('class', 'modal-dialog modal-lg');
            // eslint-disable-next-line no-undef
            $('#bodyModal').html(`
                <table class="align-middle mb-0 table table-bordered table-striped table-hover" id="tab2">
                    <thead style="font-size : 110%">
                        <tr style="background-color: teal; color : white">
                            <th>Nombre d'étudiant n'ayant pas la moyenne</th>
                            <th>Nombre d'étudiant ayant la moyenne</th>
                            <th>Forte Moyenne</th>
                            <th>Faible Moyenne</th>
                            <th>Moyenne de la filière</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr style="font-size : 100%; font-weight : bold">
                            <td>${nbrFaible.length}/${moyenne.length} etudiants</td>
                            <td>${nbrFort.length}/${moyenne.length} etudiants</td>
                            <td>${max}/20</td>
                            <td>${min}/20</td>
                            <td>${moy}</td>

                        </tr>
                    </tbody>
                </table>
            `)
            // eslint-disable-next-line no-undef
            $('#declenche').trigger('click')
        },

        activeTabs(tabactive){
            this.coursTabs = false
            this.noteTabs = false
            this.absenceTabs = false
            if(tabactive == 'absenceTabs'){
                this.mode = 'programmation'
                this.absenceTabs = true
                this.getStatistiquePresenceCoursGlobal()
            }else if(tabactive == 'noteTabs'){
                this.mode = "moyenneEtu"
                this.noteTabs = true
                this.getStatistiqueMoyenneEtudiant()
            }else if(tabactive == 'coursTabs'){
                this.mode = 'coursEvolution'
                this.buttonTitle = "Graph des notes"
                this.coursTabs = true
                this.getStatistiqueEvolutionCours()
            }
        },

        /**
         * Breadcrumb event
         */
        getButtonRetour(data) {
            this.$emit('getButtonRetour', data)
        },

        /**
         * Breadcrumb event
         */
         getBreadcrumb() {
            this.$emit('getBreadcrumb', this.objects)
        },

         /**
         * format Date with moment.js
         * @param {String} date 
         */
         formatDate(date){
           // eslint-disable-next-line no-undef
           return moment(date).locale('fr').format('llll')
        },

        getNewTable(element){
            let tab = [];
            let array = this.getAllNotes.reduce((accumulator, currentValue) => {
                let index = currentValue['moyenne'];
                if(!accumulator[index]){
                    accumulator[index] = 0
                }
                accumulator[index] = accumulator[index] +1

                return accumulator;
            }, [])
            if(element == "index"){
                for(var index in array){
                    tab.push(index)
                }
            }else{
                for(var i in array){
                    tab.push(array[i])
                }
            }
           
            return tab
        }

         // changeStatAction(){
        //     if(this.mode == "etudiant"){
        //         this.getStatistiquePresenceCoursInscription()
        //     }else if(this.mode == "programmation"){
        //         this.getStatistiquePresenceCoursGlobal()
        //     }
        // },
    },

    created() {
        let data = {
            retour : true,
            link : "cours",
            title : "Retour",
            params : {libelle : this.$route.params.libelle}
        }
        this.getButtonRetour(data)
        this.getBreadcrumb()
    },
}
</script>