<template>
    <div class="row">
        <div class="col-md-4">
            <div class="main-card card">
                <div class="card-body" style="text-align: center;">
                    <img width="150" class="rounded-circle mb-3" src="/assets/images/avatars/profil.jpg"
                    alt="">
                    <h6 class="fs-3"> <strong>{{ getUsers.nom+" "+getUsers.prenom }}</strong></h6>
                    <p>{{ getUsers.email }}</p>
                </div>
            </div>
        </div>
        <div class="col-md-8">
            <div class="main-card card">
                <div class="card-header" style="justify-content: space-between;">
                    <div>
                        Profil de {{ getUsers.nom+" "+getUsers.prenom }}
                    </div>
                    <div>
                        <button @click.prevent="userlink('user-password')" class="btn-shadow btn btn-danger">Modifier son mot de passe</button>
                    </div>
                </div>
                <div class="card-body">
                    <div class="row">
                        <div class="col-md-4">
                            <h6 class="w-400"><strong>Matricule : </strong></h6>  
                        </div>
                        <div class="col-md-4">
                            <p>{{ getUsers.matricule }}</p>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-4">
                              <h6 class="w-400"><strong>Noms et Prénoms : </strong></h6>  
                        </div>
                        <div class="col-md-4">
                            <p>{{ getUsers.nom+" "+getUsers.prenom }}</p>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-4">
                            <h6 class="w-400"><strong>Email : </strong></h6>  
                        </div>
                        <div class="col-md-4">
                            <p>{{ getUsers.email }}</p>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-4">
                            <h6 class="w-400"><strong>Téléphone : </strong></h6>  
                        </div>
                        <div class="col-md-4">
                            <p>{{ getUsers.telephone }}</p>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-4">
                            <h6 class="w-400"><strong> Spécialité : </strong></h6>  
                        </div>
                        <div class="col-md-4">
                            <p>{{ (getUsers.specialiteprincipale) ? getUsers.specialiteprincipale : '----'}}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
    emits: ['getBreadcrumb'],
    data(){
        return {
            objects : {
                breadcrumb: [
                    {
                        libelle: 'Utilisateur',
                        name: "user-profil",
                        params : {libelle : this.$route.params.libelle}
                    },
                    {
                        libelle: "Profil de l'utilisateur",
                        name: "user-profil"
                    },
                ],
                titlePage : 'Profil de l\'utilisateur',
                icon : 'fa fa-user'
            },
            
        }
    },

    computed : {
        ...mapGetters('users', ['getUsers']),

    },

    methods: {
        getBreadcrumb() {
            this.$emit('getBreadcrumb', this.objects)
        },

        userlink(name){
            let params = this.$route.params.libelle
            this.$router.push({name : name, params : {libelle : params}})
        },
    },

    created() {
        this.getBreadcrumb()
    },
}
</script>