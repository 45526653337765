<template>
    <div class="main-card mb-3 card">
        <div class="card-body">
            <div class="row">
                <div class="col-md-7">
                    <h6 class="font-weight-bold">{{ classe }}</h6>
                    <p class="text-muted" style="font-size : 11px">{{ departement }}</p>
                </div>
                <div class="col-md-5">
                    <h6 class="btn font-weight-bold" style="background-color: gray; color: white; border-radius : 12px; padding : 4px; font-size : 12px">
                       {{ niveau }}
                    </h6> 
                </div>
            </div>
            <div class="row">
                <div class="col-md-6 font-weight-bold">
                    <i class="fa fa-lock"></i>
                    Code : 
                </div>
                <div class="col-md-6">{{ code }}</div>
            </div>
            <div class="row">
                <div class="col-md-6 font-weight-bold">
                    <i class="fa fa-user"></i>
                    Effectifs : 
                </div>
                <div class="col-md-6"> {{ effectif }} </div>
            </div>
            <div class="row">
                <div class="col-md-6 font-weight-bold">
                    <i class="fa fa-recycle"></i>
                    Cycle : 
                </div>
                <div class="col-md-6"> {{ cycle }} </div>
            </div>
        </div>
        <div class="d-block card-footer">
            <div class="row" style="margin-left: 0%;">
                <button @click.prevent="listEtudiant" class="col-5 btn-icon-wrapper btn btn-danger mr-2">
                    <i class="fa fa-users"></i>
                    Etudiants
                </button>
                <button  @click.prevent="cours" class="col-5 btn-icon-wrapper btn btn-teal">
                    <i class="fa fa-book"></i>
                    Cours
                    <span class="badge badge-pill badge-danger ml-0">{{ count }}</span>
                </button>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props : {
        classe : {required : true, type : String},
        cycle : {required : true, type : String},
        niveau : {required : true, type : String},
        departement : {required : true, type : String},
        code : {required : true, type : String},
        count : {required : true, type : String},
        effectif : {required : true, type : String},
    },
    mounted(){
       
    },

    methods : {

        listEtudiant(){
            this.$emit('liste');
        },
        cours(){
            this.$emit('cours');
        },
    }
}
</script>