<!-- eslint-disable vue/no-parsing-error -->
<template>
    <div class="app-sidebar sidebar-shadow">
        <div class="app-header__logo">
            <div class="logo-src"></div>
            <div class="header__pane ml-auto">
                <div>
                    <button @click="red()" type="button" class="hamburger close-sidebar-btn hamburger--elastic"
                        data-class="closed-sidebar">
                        <span class="hamburger-box">
                            <span class="hamburger-inner"></span>
                        </span>
                    </button>
                </div>
            </div>
        </div>
        <div class="app-header__mobile-menu">
            <div>
                <button @click="reduitAppBar()" type="button" class="hamburger hamburger--elastic mobile-toggle-nav">
                    <span class="hamburger-box">
                        <span class="hamburger-inner"></span>
                    </span>
                </button>
            </div>
        </div>
        <div class="app-header__menu">
            <span>
                <button type="button"
                    class="btn-icon btn-icon-only btn btn-primary btn-sm mobile-toggle-header-nav">
                    <span class="btn-icon-wrapper">
                        <i class="fa fa-ellipsis-v fa-w-6"></i>
                    </span>
                </button>
            </span>
        </div>
        <div class="scrollbar-sidebar">
            <div class="app-sidebar__inner">
                <ul class="vertical-nav-menu">
                    <li class="app-sidebar__heading">Navigation</li>
                    <template v-for="(item, key) in loadMenu" :key="key">
                        <li class="mm-active">
                            <a v-if="item.subTitle.length == 0" href="" :class="{'mm-active':item.linkActive }" @click.prevent="clickBigMenu(item.name)">
                                <i class="metismenu-icon"  :class="item.icon" style="font-size: inherit;"></i>{{ item.libelle }}
                            </a> 
                            <a @click.prevent="activeMenuShow(key, item.show, null)" v-else href="" :class="{'mm-active':item.linkActive }">
                                <i class="metismenu-icon "  :class="item.icon" style="font-size: inherit;"></i>{{ item.libelle }}
                                <i class="metismenu-state-icon caret-left" :class="[(item.show) ? 'fa fa-angle-up' : 'fa fa-angle-down']" style="font-size: inherit !important;"></i>
                            </a>
                            <ul v-if="item.subTitle.length != 0" class="mm-collapse" :class="{'mm-show' :item.show}" style="height:inherit;">
                                <template v-for="(data, key_1) in item.subTitle" :key="key_1">
                                    <li v-if="data.subTitle.length == 0">
                                        <a href="" @click.prevent="selectOnMenu(data.nameRoute, data.paramsRoute, data.isActive)" :class="{'mm-active':data.isActive }">
                                            <i class="metismenu-icon" ></i>{{ data.libelle }}
                                        </a>
                                    </li>
                                    <li v-else>
                                        <a  @click.prevent="activeMenuShow(key, data.show, key_1)" href="" :class="{'mm-active':data.isActive }">
                                            <i class="metismenu-icon " style="font-size: inherit;"></i>{{ data.libelle }}
                                            <i class="metismenu-state-icon caret-left" :class="[(data.show) ? 'fa fa-angle-up' : 'fa fa-angle-down']" style="font-size: inherit !important;"></i>
                                        </a>
                                    </li>
                                    <ul v-if="data.subTitle.length != 0" class="mm-collapse" :class="{'mm-show' :data.show}" style="height:inherit;">
                                        <template v-for="(value, key) in data.subTitle" :key="key">
                                            <li>
                                                <a href="" @click.prevent="selectOnMenu(value.nameRoute, value.paramsRoute, value.isActive)" :class="{'mm-active':value.isActive }">
                                                    <i class="metismenu-icon" ></i>{{ value.libelle }}
                                                </a>
                                            </li>
                                        </template>
                                    </ul>
                                </template>
                            </ul>
                        </li>
                    </template>
                    <li class="app-sidebar__heading">Utilisateur</li>
                    <li>
                        <a href="" @click.prevent="userlink('user-profil')" :class="{'mm-active' : compte }">
                            <i class="metismenu-icon fa fa-user" style="font-size: inherit;"></i> Votre compte
                        </a>
                    </li>
                    <li>
                        <a href="" @click.prevent="userlink('user-password')" :class="{'mm-active' : passe }">
                            <i class="metismenu-icon fa fa-key" style="font-size: inherit"></i> Modifier le mot de passe
                        </a>
                    </li>
                    <li style="margin-top:50px">
                        <button @click.prevent="deconnexion" class="btn btn-danger w-100 bold">
                            <i class="metismenu-icon fa fa-reply" style="color: white; font-size : inherit"></i> Déconnexion
                        </button>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions } from 'vuex'
export default {
    props : {
        menus : {type:Object, require:true}
    },

    data() {
        return {
            is_active : false,
            compte : false,
            passe : false,
            menu : []
        }
    },

    mounted(){
        this.menu = this.menus
    },

    watch : {
        menus: {
            handler() {
                this.menu = this.menus
            },
            deep: true
        }
    },

    computed : {
        loadMenu(){
            return this.menu
        }
    },

    methods: {
        ...mapActions('auth', ['logout']),

        selectOnMenu(name, params, is_active){
            this.is_active = !is_active
            this.$router.push({name : name, params : {libelle : params}})
            this.userlink('ml')
        },
        
        clickBigMenu(name){
            this.$router.push({name : name})
            this.userlink('ml')
        },

        userlink(name){
            if(name == "user-password"){
                this.compte = false
                this.passe = true
                let params = this.$route.params.libelle
                this.$router.push({name : name, params : {libelle : params}})
            }else if(name == "user-profil"){
                this.compte = true
                this.passe = false
                let params = this.$route.params.libelle
                this.$router.push({name : name, params : {libelle : params}})
            }else{
                this.compte = false
                this.passe = false
            }
            
           
        },

        reduitAppBar(){
            // eslint-disable-next-line no-undef
            let app = $('.app-container')
            //  // eslint-disable-next-line no-undef
            if(app.hasAttribute('sidebar-mobile-open')){
                app.removeClass('sidebar-mobile-open')
            }else{
                app.addClass('sidebar-mobile-open')
            }
        },

        async deconnexion(){
            this.$charging()
            await this.logout().then((response) => {
                this.$unCharging()
                // eslint-disable-next-line no-undef
                Swal.fire({
                    title: "Déconnexion",
                    text: `${response.data.message}`,
                    icon: "success",
                }).then((ans) => {
                    if(ans.isConfirmed){
                        this.$router.push({ name: 'index-connexion' });
                    }
                })
            }).catch((err) => {
                // eslint-disable-next-line no-undef
                Swal.fire({
                    allowOutsideClick : false,
                    title: "Oops...",
                    text: `${(err)}`,
                    icon: "error",
                })
                this.$unCharging()
            })
        },

        activeMenuShow(origin, show, submenu){
            if(submenu != null){
                this.menu[origin].subTitle[submenu].show = !show
            }else{
                this.menu[origin].show = !show
            }
        }
    },
}
</script>