/* eslint-disable no-unused-vars */
import * as rootTypes from './mutation-types';
import * as userTypes from '../users/mutation-types';

import Ls from '@/services/ls'
import { Base64 } from 'js-base64'

export const getClasse = ({ commit, dispatch }, data) => {
    return new Promise((resolve, reject) => {
        let idCompte = Base64.decode(Ls.get('user.id'))
        window.axios.get('teacher/classe/' + data.idEntite + '/' + idCompte).then(response => {
            if(!response || response.data.code != 200){
                reject(response)
            }else{
                commit('users/' + userTypes.GET_USERS, response.data.data.enseignant, {root : true}) 
                commit('classes/' + rootTypes.GET_CLASSES, response.data.data.classes, {root : true}) 
                resolve(response)
            }
        }).catch(err => {
            reject(err)
        })
    })
}

export const getAllClasse = ({ commit, dispatch }, data) => {
    return new Promise((resolve, reject) => {
        let idCompte = Base64.decode(Ls.get('user.id'))
        window.axios.get('teacher/all-classe/' + data.idEntite).then(response => {
            if(!response || response.data.code != 200){
                reject(response)
            }else{
                commit('classes/' + rootTypes.GET_ALL_CLASSES, response.data.data.all_classes, {root : true}) 
                resolve(response)
            }
        }).catch(err => {
            reject(err)
        })
    })
}