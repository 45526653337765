import * as types from "./mutation-types"

export default ({
    [types.ALL_NOTES] : function(state, data) {
        state.notes = data
    },
    
    [types.ALL_EVALUATIONS] : function(state, data) {
        state.evaluations = data
    },
    
    [types.ALL_SESSIONS] : function(state, data) {
        state.sessions = data
    },
})