<template>
    <div class="app-wrapper-footer">
        <div class="app-footer">
            <div class="app-footer__inner">
                <div class="app-footer-left">
                   <span class="text-muted">Copyright © 2023 </span> <span style="color:teal; font-weight:bold; margin-right:2px; margin-left:2px"> EasyLMD Teacher </span> - Tous droits réservés
                </div>
                <div class="app-footer-right">
                    
                </div>
            </div>
        </div>
    </div>
</template>