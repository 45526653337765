<template>
    <div>
        <div class="app-container app-theme-white body-tabs-shadow">
            <div class="app-container">
                <div class="h-100 bg-animation">
                    <div class="d-flex justify-content-center align-items-center" style="height : 50%">
                        <div class="mx-auto app-login-box col-md-8">
                            <div class="app-logo-inverse mx-auto mb-5">
                                <img :src="image" alt="" style="width:100%">
                            </div>
                            <div class="card">
                                <div class="card-body">
                                    <h1 style="text-align : center; font-weight : bold">
                                        <i class="fa fa-globe"></i>
                                        Page non trouvée
                                    </h1>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Logo from '../../../public/assets/images/1.png'
export default {
  data(){
    return {
        image : Logo
    }
  }
}
</script>