/* eslint-disable no-undef */
import Ls from '@/services/ls'
import * as rootTypes from './mutation-types'
import * as etablissementTypes from '../etablissements/mutation-types'
import * as userTypes from '../users/mutation-types'
import * as types from '../../mutation-types'
import { Base64 } from 'js-base64'

/* eslint-disable no-unused-vars */
export const generateKey = ({commit, dispatch}, data) => {
    return new Promise((resolve, reject) => {
        window.axios.post('auth/login', data).then((response) =>  {
            if(response){
                const tokencrypt = Base64.encode(response.data.token)
                Ls.set('auth.token', tokencrypt)
                resolve(response)
            }
        }).catch(err => {
            reject(err)
        })
    })
}

export const activationCompte = ({commit, dispatch}, data) => {
    return new Promise((resolve, reject) => {
        window.axios.post('teacher/activation-compte', data).then((response) => {
            if(!response || response.data.code == 302){
                reject(response)
            }else{
                resolve(response)
            }
        }).catch((err) => {
            reject(err)
        })
    })
}

export const validationCompte = ({commit, dispatch}, data) => {
    return new Promise((resolve, reject) => {
        window.axios.post('teacher/validation-compte', data).then((response) => {
            if(!response || response.data.code == 302){
                reject(response)
            }else{
                resolve(response)
            }
        }).catch((err) => {
            reject(err)
        })
    })
}

export const finalisationCompte = ({commit, dispatch}, data) => {
    return new Promise((resolve, reject) => {
        window.axios.post('teacher/finalisation-compte', data).then((response) => {
            if(!response || response.data.code == 302){
                reject(response)
            }else{
                resolve(response)
            }
        }).catch((err) => {
            reject(err)
        })
    })
}

export const connexion = ({commit, dispatch}, data) => {
    return new Promise((resolve, reject) => {
        window.axios.post('teacher/connexion', data).then((response) => {
            if(!response || response.data.code != 200){
                reject(response)
            }else{
                const idCrypt = Base64.encode(response.data.data.compteId)
                Ls.set('user.id', idCrypt)
                commit('auth/' + rootTypes.GET_USERNAME, response.data.data.enseignant, {root : true}) 
                commit('users/' + userTypes.GET_USERS, response.data.data.enseignant, {root : true}) 
                commit('auth/' + rootTypes.GET_IS_AUTHENTICATED, true, {root : true}) 
                commit('etablissements/' + etablissementTypes.ALL_ETABLISSEMENT, response.data.data.entites, {root : true})
                commit(types.GET_MENU, response.data.data.menu, {root : true}) 
                commit(types.GET_ANNEE_ACADEMIQUE, response.data.annee, {root : true}) 
                resolve(response)
            }
        }).catch((err) => {
            reject(err)
        })
    })
}

export const logout = ({commit, dispatch}) => {
    let data = {
        'id_compte' : Base64.decode(Ls.get('user.id')),
        'id_entite' : '',
    }
    return new Promise((resolve, reject) => {
        window.axios.post('teacher/deconnexion', data).then((response) => {
            if(!response || response.data.code != 200){
                reject(response)
            }else{
                Ls.set('auth.token', '')
                Ls.set('user.id', '')
                commit('auth/' + rootTypes.GET_IS_AUTHENTICATED, false, {root : true}) 
                resolve(response)
            }
        }).catch((err) => {
            reject(err)
        })
    })
}

export const modifPass = ({commit, dispatch}, data) => {
    let value = {
        'id_compte' : Base64.decode(Ls.get('user.id')),
        'id_entite' : '',
        'old_password' : data.old_password,
        'new_password' : data.new_password,
    }

    return new Promise((resolve, reject) => {
        window.axios.post('teacher/modif-password', value).then((response) => {
            if(!response || response.data.code!= 200){
                reject(response)
            }else{
                resolve(response)
            }
        }).catch((err) => {
            reject(err)
        })
    });
}

export const forgotPassword = ({commit, dispatch}, data) => {
    return new Promise((resolve, reject) => {
        window.axios.post('teacher/forgot-password', data).then((response) => {
            if(!response || response.data.code!= 200){
                reject(response)
            }else{
                resolve(response)
            }
        }).catch((err) => {
            reject(err)
        })
    });
}
