/* eslint-disable no-unused-vars */
import * as rootTypes from './mutation-types';
import * as userTypes from '../users/mutation-types';

import Ls from '@/services/ls'
import { Base64 } from 'js-base64'

export const getCoursPeriode = ({ commit, dispatch }, data) => {
    return new Promise((resolve, reject) => {
        let idCompte = Base64.decode(Ls.get('user.id'))
        window.axios.get('teacher/cours/' + data.idEntite + '/' + idCompte + '/' + data.debutPeriode + '/' + data.finPeriode + '/'+ data.withCours +"/"+ null).then(response => {
            if(!response || response.data.code != 200){
                reject(response)
            }else{
                commit('users/' + userTypes.GET_USERS, response.data.data.enseignant, {root : true}) 
                commit('cours/' + rootTypes.GET_COURS, response.data.data.cours, {root : true}) 
                commit('cours/' + rootTypes.GET_COURS_SIMPLE, response.data.data.coursSimple, {root : true}) 
                commit('cours/' + rootTypes.GET_EMPLOI_TEMPS, response.data.data.programme, {root : true}) 
                resolve(response)
            }
        }).catch(err => {
            reject(err)
        })
    })
}


export const getCoursPeriodeClasse = ({ commit, dispatch }, data) => {
    return new Promise((resolve, reject) => {
        let idCompte = Base64.decode(Ls.get('user.id'))
        window.axios.get('teacher/cours/' + data.idEntite + '/' + idCompte + '/' + data.debutPeriode + '/' + data.finPeriode + '/'+ data.withCours + '/' + data.idClasse).then(response => {
            if(!response || response.data.code != 200){
                reject(response)
            }else{
                commit('users/' + userTypes.GET_USERS, response.data.data.enseignant, {root : true}) 
                commit('cours/' + rootTypes.GET_COURS, response.data.data.cours, {root : true}) 
                commit('cours/' + rootTypes.GET_COURS_SIMPLE, response.data.data.coursSimple, {root : true}) 
                commit('cours/' + rootTypes.GET_EMPLOI_TEMPS, response.data.data.programme, {root : true}) 
                resolve(response)
            }
        }).catch(err => {
            reject(err)
        })
    })
}


export const sendDocumentCours = ({ commit, dispatch }, data) => {
    return new Promise((resolve, reject) => {
        let config = {'Content-Type' : 'multipart/form-data'}
        window.axios.post('teacher/depot-document', data, {headers : config}).then(response => {
            if(!response || response.data.code != 200){
                reject(response)
            }else{
                resolve(response)
            }
        }).catch(err => {
            reject(err)
        })
    })
}

export const getDocumentCours = ({ commit, dispatch }, data) => {
    return new Promise((resolve, reject) => {
        let idCompte = Base64.decode(Ls.get('user.id'))
        window.axios.get('teacher/documents/'+ data.idEntite + '/' + idCompte + '/' + data.idCours).then(response => {
            if(!response || response.data.code != 200){
                reject(response)
            }else{
                resolve(response)
            }
        }).catch(err => {
            reject(err)
        })
    })
}

export const getStatPresenceCours = ({ commit, dispatch }, data) => {
    return new Promise((resolve, reject) => {
        let idCompte = Base64.decode(Ls.get('user.id'))
        window.axios.get('teacher/cours-statistique/'+ data.idEntite + '/' + idCompte + '/' + data.idCours + '/' + data.variable).then(response => {
            if(!response || response.data.code != 200){
                reject(response)
            }else{
                resolve(response.data)
            }
        }).catch(err => {
            reject(err)
        })
    })
}


export const getPlanCours = ({ commit, dispatch }, data) => {
    return new Promise((resolve, reject) => {
        let idCompte = Base64.decode(Ls.get('user.id'))
        window.axios.get('teacher/plan-cours/'+ data.idEntite + '/' + idCompte + '/' + data.idCours).then(response => {
            if(!response || response.data.code != 200){
                reject(response)
            }else{
                resolve(response.data)
            }
        }).catch(err => {
            reject(err)
        })
    })
}

export const getEnseignerChapitre = ({ commit, dispatch }, data) => {
    return new Promise((resolve, reject) => {
        let idCompte = Base64.decode(Ls.get('user.id'))
        window.axios.get('teacher/enseigner-chapitre/'+ data.idEntite + '/' + idCompte + '/' + data.idCours).then(response => {
            if(!response || response.data.code != 200){
                reject(response)
            }else{
                resolve(response.data)
            }
        }).catch(err => {
            reject(err)
        })
    })
}

export const postEnseignerPlanCours = ({ commit, dispatch }, val) => {
    return new Promise((resolve, reject) => {
        let idCompte = Base64.decode(Ls.get('user.id'))
        let data = {
            id_entite : val.idEntite,
            id_compte : idCompte,
            tabPlanEnseigner : val.tab,
            id_cours : val.idCours
        }
        window.axios.post('teacher/send-planMatiere', data).then(response => {
            if(!response || response.data.code != 200){
                reject(response)
            }else{
                resolve(response.data)
            }
        }).catch(err => {
            reject(err)
        })
    })
}