/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */
import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import lodash from 'lodash'

// import 'vuetify/styles'
// import { createVuetify } from 'vuetify'
// import * as components from 'vuetify/components'
// import * as directives from 'vuetify/directives'

import axios from 'axios'
import Ls from './services/ls'
import helpers from './helpers/function'
import charts from './helpers/charts'
import { BASE_URL } from './services/api'
import { Base64 } from 'js-base64'

window.axios = axios
window.showBtn = true
axios.defaults.baseURL = BASE_URL
window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest'
window.axios.defaults.headers.common['Authorization'] = ''
window.axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded;application/json;multipart/form-data'

window.axios.interceptors.request.use(function(config){
    //const AUTH_TOKEN = this.$cryptoJS.AES.decrypt(tokencrypte, "token API").toString(this.$cryptoJS.enc.Utf8)
    const tokencrypt = Ls.get('auth.token')
    if(tokencrypt){
        const AUTH_TOKEN = Base64.decode(tokencrypt)
        config.headers.Authorization = `Bearer ${AUTH_TOKEN}`
    }
    return config
}, function(error){
    return Promise.reject(error)
});

global.axios.interceptors.response.use(undefined, function(err){
    if(err.response){
        if(err.response.data && err.response.status == 401){
            if(err.response.data.code == 4011 || err.response.data.code == 4012){
                Ls.set('auth.token', '')
                Ls.set('user.id', '')
                store.commit('auth/GET_IS_AUTHENTICATED', false)
                Swal.fire({
                    allowOutsideClick : false,
                    title: "Oops... Votre session à expirer.",
                    text: "Vous serez rediriger vers la page de connexion",
                    icon: "error",
                }).then((response) => {
                    window.location = '/'
                })
               
            }else{
                Swal.fire({
                    allowOutsideClick : false,
                    title: "Oops...",
                    text: `${(err.response.data.message)  ? err.response.data.message : 'Non autorisé'}`,
                    icon: "error",
                })
            }
        }else if(err.response.data && err.response.status == 404){
            Swal.fire({
                allowOutsideClick : false,
                title: "Oops...",
                text: `${(err.response.data.message)  ? err.response.data.message : 'Non autorisé'}`,
                icon: "error",
            })
              
        }else if(err.response.data.errors && err.response.status == 402){
            let errors = JSON.parse(JSON.stringify(err.response.data.errors))
            Swal.fire({
                allowOutsideClick : false,
                title: "Oops...",
                text: `${(errors)}`,
                icon: "error",
            })
        }else{
            Swal.fire({
                allowOutsideClick : false,
                title: "Oops...",
                text: `${(err.response.data.message)  ? err.response.data.message : 'Non autorisé'}`,
                icon: "error",
            })
        }
    }else{
        Swal.fire({
            allowOutsideClick : false,
            title: "Oops... Connection",
            text: `${(err.message)  ? err.message : 'Non autorisé'}`,
            icon: "error",
        }).then(() => {
            router.go(0)
        })
    }
})

// const vuetify = createVuetify({
//     components,
//     directives,
//   })

let app = createApp(App)
app.config.performance = true
app.use(store) 
    .mixin(helpers)
    .mixin(charts)
    .mixin(lodash)
    .use(router)
    .mount('#app')
