import { createStore } from 'vuex'
import * as getters from './getters'
import mutations from './mutations'
import * as actions from './actions'

import auth from './modules/auth/index'
import cours from './modules/cours/index'
import etablissements from './modules/etablissements/index'
import classes from './modules/classes/index'
import etudiants from './modules/etudiants/index'
import users from './modules/users/index'
import matieres from './modules/matieres/index'
import notes from './modules/notes/index'

export default createStore({
  state: {
    menu : [],
    annee : ""
  },
  getters,
  mutations,
  actions: actions,
  modules: {
    auth,
    cours,
    notes,
    etablissements,
    classes,
    etudiants,
    users,
    matieres
  }
})
