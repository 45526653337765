<template>
    <div class="loadTable">
        <table-simple 
            filtreActions="initialise" 
            :validateButton="true"
            :periode="true" 
            :isloading="isloading" 
            :inputClasse="true" 
            :data="getClasses"
            :action="action" 
            :buttonShow="false" 
            :tableTitle="tableTitle" 
            :cle="cle" 
            :header="header" 
            :tableau="false"
            :body_2="tabGroupBy"
            :body="body"
            @onchange="change"
            @initialise="filtre"
            noData="Vous n'avez aucun cours programmé pour cette semaine" 
            ></table-simple>
    </div>
</template>

<script>
import TableSimple from '@/components/tables/TableSimple.vue';
import { mapGetters, mapActions } from 'vuex';
export default {
    emits: ['getBreadcrumb'],
    components : {
        TableSimple,
    },

    data() {
        return {
            isloading : false,
            // eslint-disable-next-line no-undef
            debut : moment().weekday(1).format("YYYY-MM-DD"),
            // eslint-disable-next-line no-undef
            fin : moment().weekday(7).format("YYYY-MM-DD"),
            classe : '',
            tableTitle : 'Emploi du temps',
            body : [],
            action : false,
            tabGroupBy : [],
            cle: [
                {
                    libelle: "dateCours",
                    classe: 'text-center',
                    type: 'date',
                    count : 1
                },
                {
                    libelle: "classe",
                    classe: 'text-center',
                    type: 'text',
                    count : 1
                },
                {
                    libelle: "libelleMatiere",
                    classe: 'text-center',
                    type: 'text',
                    count : 1
                },
                {
                    libelle: 'horaire',
                    classe: 'text-center',
                    type: 'text',
                    count : 1
                },
                {
                    libelle: "SalleName",
                    classe: 'text-center',
                    type: 'text',
                    count : 1
                },
                {
                    libelle: ["siExecute", "siAnnuler"],
                    value: ['Exécuté', 'Annulé', 'Non exécuté'],
                    type: 'bool',
                    count: 2
                },
            ],
            header: [
                {
                    classe: '',
                    libelle: 'Date du cours',
                },
                {
                    classe: '',
                    libelle: 'Classe',
                },
                {
                    classe: '',
                    libelle: 'Matière',
                },
                {
                    classe: '',
                    libelle: 'Horaire',
                },
                {
                    classe: '',
                    libelle: 'Salle',
                },
                {
                    classe: '',
                    libelle: 'Statut',
                },
            ],
            objects : {
                breadcrumb : [
                    {
                        libelle : 'Etablissement',
                        name : "index-etablissement",
                        params : {libelle : this.$route.params.libelle}
                    },
                    {
                        libelle : this.$route.params.libelle,
                        name : "index-entite",
                        params : {libelle : this.$route.params.libelle}
                    },
                    {
                        libelle : 'Emploi du temps',
                        name : "cours"
                    },
                ],
                titlePage : 'Emploi du temps ('+this.$route.params.libelle+')',
                icon : 'fa fa-list'
            }
        }
    },

    async mounted() {
       
        let data = {
            idEntite : this.getEtablissement(this.$route.params.libelle).id,
            debutPeriode : this.debut,
            finPeriode : this.fin,
            withCours : 'programme'
        }
        this.$charging()
            await this.getCoursPeriode(data).then(() => {
                this.body = this.getEmploiTemps
                this.$unCharging()
                this.formatGroupByObject()
            }).catch((error) => {
                // eslint-disable-next-line no-undef
                Swal.fire({
                    allowOutsideClick : false,
                    title: "Oops...",
                    text: `${error.data.message}`,
                    icon: "error",
                })
                this.$unCharging()
            });
        // if(this.getEmploiTemps.length == 0){
           
        // }else{
        //     this.body = this.getEmploiTemps
        //     this.formatGroupByObject()
        // }
        
    },

    computed : {
        ...mapGetters('classes', ['getClasses']),
        ...mapGetters('cours', ['getCours', 'getEmploiTemps']),
        ...mapGetters('etablissements', ['getEtablissement']),
        ...mapGetters(['getDataMenu'])
    },

    methods : {
        ...mapActions('cours', ['getCoursPeriode', 'sendDocumentCours']),
        ...mapActions('etablissements', ['getEntite']),

        async filtreCours(){
            this.$charging()
            this.isloading = true
            let data = {
                idEntite : this.getEtablissement(this.$route.params.libelle).id,
                debutPeriode : this.debut,
                finPeriode : this.fin,
                withCours : 'programme'
            }
            await this.getCoursPeriode(data).then(() => {
                this.isloading = false
                this.body = this.getEmploiTemps
                if(this.classe != ""){
                    this.body = this.getEmploiTemps.filter((item) => item.classe == this.classe)
                }
                this.formatGroupByObject()
                this.$unCharging()
            }).catch((error) => {
                // eslint-disable-next-line no-undef
                Swal.fire({
                    allowOutsideClick : false,
                    title: "Oops...",
                    text: `${error.data.message}`,
                    icon: "error",
                })
                this.$unCharging()
            });
        },

        getBreadcrumb(){
            this.$emit('getBreadcrumb', this.objects)
        },

        async filtre(data){
            this.debut = data.debut
            this.fin = data.fin
            //this.classe = data.classe
            await this.filtreCours()
        },

        change(data){
            this.debut = data.debut
            this.fin = data.fin
            this.classe = data.classe
            if(this.classe == ""){
                this.body = this.getEmploiTemps
            }else{
                this.body = this.getEmploiTemps.filter((item) => item.classe === this.classe)
            }
            this.formatGroupByObject()

        },

        functionForReduce(accumulator, currentValue) {
            let index = currentValue['dateCours']
            if(!(index in accumulator)){
                accumulator[index] = []
            }
            let data = {
                'horaire' : currentValue['horaire'],
               'classe' : currentValue['classe'],
               'libelleMatiere' : currentValue['libelleMatiere'],
               'salleName' : currentValue['SalleName'],
               'statut' : (currentValue['siExecute']) ? "Exécuté" : ((currentValue['siAnnuler']) ? "Annulé" : "Non exécuté")
            }
            
            accumulator[index].push(data)
            return accumulator;
        },

        formatGroupByObject(){
            this.tabGroupBy = this.body.reduce(this.functionForReduce, {});
        }
    },

    created(){
       this.getBreadcrumb()
    },
}
</script>