/* eslint-disable no-unused-vars */
import * as rootTypes from './mutation-types';
import * as userTypes from '../users/mutation-types';

import Ls from '@/services/ls'
import { Base64 } from 'js-base64'

export const getEtudiantClasse = ({ commit, dispatch }, data) => {
    return new Promise((resolve, reject) => {
        let idCompte = Base64.decode(Ls.get('user.id'))
        let url = ''
        
        if(data.idEnseigner != null){
            url = 'teacher/list-etudiant-classe/'+ data.idEntite + '/' + idCompte + '/' + data.idClasse + '/' + data.idEnseigner
        }else{
            url = 'teacher/list-etudiant-classe/'+ data.idEntite + '/' + idCompte + '/' + data.idClasse + '/' + 'null'
        }
        window.axios.get(url).then(response => {
            if(!response || response.data.code != 200){
                reject(response)
            }else{
                commit('users/' + userTypes.GET_USERS, response.data.data.enseignant, {root : true}) 
                commit('etudiants/' + rootTypes.GET_ETUDIANT, response.data.data.etudiants, {root : true}) 
                resolve(response)
            }
        }).catch(err => {
            reject(err)
        })
    })
}

export const listePresenceEtudiant = ({ commit, dispatch }, data) => {
    return new Promise((resolve, reject) => {
        window.axios.post('teacher/liste-presence', data).then(response => {
            if(!response || response.data.code != 200){
                reject(response)
            }else{
                resolve(response)
            }
        }).catch(err => {
            reject(err)
        })
    })
}