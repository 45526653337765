import * as types from './mutation-types'
export default({
    [types.GET_COURS]:function(state, data){
        state.cours = data
    },
    
    [types.GET_COURS_JOURS]:function(state, data){
        state.coursJours = data
    },

    [types.GET_COURS_SIMPLE]:function(state, data){
        state.coursSimple = data
    },
    
    [types.GET_ONE_COURS]:function(state, data){
        state.oneCours = data
    },
    
    [types.GET_EMPLOI_TEMPS]:function(state, data){
        state.emploiTemps = data
    }
    
    ,[types.GET_HEURE_TOTAL]:function(state, data){
        state.heuresTotal = data
    },
    
    [types.GET_HEURE_PAS_EXECUTE]:function(state, data){
        state.heuresPasExecute = data
    },
    
    [types.GET_HEURE_PREVU]:function(state, data){
        state.heurePrevue = data
    },
    
    [types.GET_HEURE_EXECUTE]:function(state, data){
        state.heuresExecute = data
    },
})