import * as getters from './getters.js'
import mutations from './mutations.js'
import * as actions from './actions.js'

const initialState = {
    notes : [],
    evaluations : [],
    sessions : [],
}

export default {
    namespaced : true,
    state : initialState,
    getters : getters,
    mutations : mutations,
    actions : actions
}