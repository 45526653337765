import * as getters from './getters'
import mutations from './mutations'
import * as actions from './actions'

const initialState = {
    matieres : [],
}

export default {
    namespaced : true,
    state : initialState,
    getters : getters,
    mutations : mutations,
    actions : actions
}