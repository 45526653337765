import * as getters from './getters.js'
import mutations from './mutations.js'
import * as actions from './actions.js'
import Ls from '../../../services/ls'

const initialState = {
    username : '',
    isAuthenticated : (Ls.get('user.id') && (Ls.get('user.id') != '') ) ? true : false,
}

export default {
    namespaced : true,
    state : initialState,
    getters : getters,
    mutations : mutations,
    actions : actions
}