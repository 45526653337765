<template>
    <div class="card mb-4"  style="margin-left: 15px; margin-right : 15px;">
        <div class="card-header">
            <div class="text-center">
                <div role="group" class="mt-4 btn-group-lg btn-group">
                <!-- <div role="group" class="mt-4 mb-4 btn-group-lg btn-group"> -->
                    <!-- <button @click="activeTabs('matTabs')" type="button" class="btn-shadow btn btn-primary" :class="{'active' : matTabs}">
                        <span class="badge badge-pill badge-danger ml-0">{{ getMatieres.length }}</span>
                                    Liste des matieres
                    </button>
                    <button @click="activeTabs('coursTabs')" type="button" class="btn-shadow btn btn-teal" :class="{'active' : coursTabs}">
                        <span class="badge badge-pill badge-danger ml-0">{{ bodyCoursSimple.length }}</span>
                                    Cours non programmé
                    </button>
                    <button @click="activeTabs('progTabs')" :class="{'active' : progTabs}" type="button" class="btn-shadow btn btn-warning">
                        <span class="badge badge-pill badge-danger ml-0">{{ bodyCours.length }}</span>
                                    Cours programmé
                    </button> -->
                    <ul class="body-tabs body-tabs-layout tabs-animated body-tabs-animated nav mb-4" style="text-transform : uppercase">
                        <li class="nav-item">
                            <a @click.prevent="activeTabs('matTabs')" role="tab" class="nav-link" :class="{'active' : matTabs}" href="">
                                <span class="font-weight-bold">
                                    <span class="badge badge-pill badge-danger ml-0">{{ bodyCoursSimple.length }}</span>
                                    Liste de vos cours</span>
                            </a>
                        </li>
                        <li class="nav-item">
                            <a @click.prevent="activeTabs('coursTabs')" role="tab" class="nav-link" :class="{'active' : coursTabs}" href="">
                                <span class="font-weight-bold">
                                    <span class="badge badge-pill badge-danger ml-0">{{ coursNonProgramme.length }}</span>
                                    Cours non programmé</span>
                            </a>
                        </li>
                        <li class="nav-item">
                            <a @click.prevent="activeTabs('progTabs')" role="tab" class="nav-link" :class="{'active' : progTabs}" href="">
                                <span class=" font-weight-bold">
                                    <span class="badge badge-pill badge-danger ml-0">{{ bodyCours.length }}</span>
                                    Cours programmé</span>
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <div class="card-body">
            <Transition>
                <div class="row" v-if="matTabs" style="margin-left: 0px; margin-right : 0px;">
                    <template v-if="bodyCoursSimple.length == 0">
                        <div class="col-md-12">
                                <div class="main-card mb-3 card">
                                    <div class="card-body">
                                        <div class="text-center">
                                        <h6 class="font-weight-bold text-danger">
                                            <i class="fa fa-info-circle"></i>
                                            Vous n'avez aucun cours</h6>  
                                            <p class="text-muted">Vous n'êtes assigné à aucun cours</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                    </template>
                    <template v-else>
                        <template v-for="(item, key) in bodyCoursSimple" :key="key">
                                <div class="col-6">
                                    <card-cours :className="'card'+key" 
                                    :details="false"
                                    :classDataName="item.classe" 
                                    :courLibelle="item.libelleMatiere"
                                    :modeEva="item.modeval"
                                    :nbhcmtd="item.nbhcmtd"
                                    :nbhtp="item.nbhtp"
                                    :nbhtotal="item.nbhtotal"
                                    :heureExe="item.heureExecute"
                                    :heureProg="item.heureProgramme"
                                    :countProgramme="countNbreProgramme(item.id)"
                                    ></card-cours>
                                </div>             
                        </template>
                    </template>
                </div>
                <div v-else-if="coursTabs" class="coursTabs">
                    <div class="row" style="margin-left: 0px; margin-right : 0px;">
                        <template v-if="coursNonProgramme.length == 0">
                            <div class="col-md-12">
                                <div class="main-card mb-3 card">
                                    <div class="card-body">
                                        <div class="text-center">
                                        <h6 class="font-weight-bold text-danger">
                                            <i class="fa fa-info-circle"></i>
                                            Vous n'avez aucun cours</h6>  
                                            <p class="text-muted">Tous vos ont soit été programmée ou vous n'avez aucun cours</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </template>
                        <template v-else>
                            <template v-for="(item, key) in coursNonProgramme" :key="key">
                                <div class="col-6">
                                    <card-cours :className="'card'+key" 
                                    :details="false"
                                    :details2="true"
                                    :classDataName="item.classe" 
                                    :courLibelle="item.libelleMatiere"
                                    :modeEva="item.modeval"
                                    :nbhcmtd="item.nbhcmtd"
                                    :nbhtp="item.nbhtp"
                                    :nbhtotal="item.nbhtotal"
                                    :heureExe="item.heureExecute"
                                    :heureProg="item.heureProgramme"
                                    @doc="document(item.id)"
                                    @count="contenu(item.id)"
                                    :countProgramme="countNbreProgramme(item.id)"
                                    ></card-cours>
                                </div>
                            </template>
                        </template>
                    </div>
                </div>
                <div v-else class="secondTabs">
                    <!-- <div class="mb-3" style="margin-left: 15px; margin-right : 15px;">
                        <div class="">
                            <div class="row" style="width: 100%">
                                <div class="col-md-5 col-xs-5">
                                    <label for="" style="font-weight: bold;">Choisir la période</label>
                                    <div class="input-group">
                                        <div class="input-group-prepend">
                                            <div class="input-group-text">
                                                De
                                            </div>
                                        </div>
                                        <input type="date" class="form-control" name="" v-model="debut">
                                        <div class="input-group-prepend">
                                            <div class="input-group-text">
                                                A
                                            </div>
                                        </div>
                                        <input type="date" class="form-control" name="" v-model="fin">
                                    </div>
                                </div>
                                <div class="col-md-1 col-xs-2" style="margin-top : 30px">
                                    <button @click.prevent="filtreCours" v-if="!isloading" class="btn-icon btn btn-success fw-bold">
                                        <i class="fa fa-search"></i>
                                    </button>
                                    <button class="btn btn-success fw-bold" v-else disabled>
                                        <img src="/assets/images/loading_2.gif" alt="" style="width: 100%;">
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div> -->

                    <div class="row" style="margin-left: 0px; margin-right : 0px;">
                        <template v-if="bodyCours.length == 0">
                            <div class="col-md-12">
                                <div class="main-card mb-3 card">
                                    <div class="card-body">
                                        <div class="text-center">
                                        <h6 class="font-weight-bold text-danger">
                                            <i class="fa fa-info-circle"></i>
                                            Aucun cours programmé pour cette semaine</h6>  
                                            <p class="text-muted">Modifier la période pour voir les anciens cours</p>
                                            <button @click.prevent="getAllCours" class="btn btn-primary">
                                                Voir tous vos cours
                                            </button> 
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </template>
                        <template v-else>
                            <template v-for="(item, key) in bodyCours" :key="key">
                                <div class="col-6">
                                    <card-cours :className="'card'+key" 
                                    :classDataName="item.classe" 
                                    :courLibelle="item.libelleMatiere"
                                    :modeEva="item.modeval"
                                    :nbhcmtd="item.nbhcmtd"
                                    :nbhtp="item.nbhtp"
                                    :nbhtotal="item.nbhtotal"
                                    :heureExe="item.heureExecute"
                                    :heureProg="item.heureProgramme"
                                    @doc="document(item.id)"
                                    @prog="programme(item.id)"
                                    @note="grilleNote(item.id)"
                                    @count="contenu(item.id)"
                                    @statistique="statistique(item.id)"
                                    :pource="(parseInt(item.heureExecute)*1)/parseInt(item.nbhtotal)"
                                    ></card-cours>
                                </div>
                            </template>
                        </template>
                    </div>
                </div>
            </Transition>
        </div>
    </div>

</template>

<style scoped>
    .v-enter-active {
        transition: opacity 0.5s ease;
    }
    /* .v-leave-active {
        
    } */

    .v-enter-from, .v-leave-to {
        opacity: 0;
    }
</style>

<script>
import CardCours from '@/components/widgets/cards/CardCours.vue';
import { mapGetters, mapActions } from 'vuex';
import Ls from '@/services/ls'
import { Base64 } from 'js-base64'
export default {
    emits: ['getBreadcrumb', 'getButtonRetour'],
    components: {
        CardCours,
    },

    data() {
        return {
            isloading: false,
            matTabs: true,
            coursTabs: false,
            progTabs: false,
            // eslint-disable-next-line no-undef
            debut: moment().weekday(1).format("YYYY-MM-DD"),
            // eslint-disable-next-line no-undef
            fin: moment().weekday(7).format("YYYY-MM-DD"),
            tableTitle: 'Vos Cours de la semaine',
            bodyCours: [],
            coursNonProgramme: [],
            bodyCoursSimple: [],
            objects : {
                breadcrumb : [
                    {
                        libelle : 'Etablissement',
                        name : "index-etablissement",
                        params : {libelle : this.$route.params.libelle}
                    },
                    {
                        libelle : this.$route.params.libelle,
                        name : "index-entite",
                        params : {libelle : this.$route.params.libelle}
                    },
                    {
                        libelle :  'Gestion de classe',
                        name : "classe"
                    },
                ],
                titlePage : "Gestion des classes ("+this.$route.params.libelle+")",
                icon : 'fa fa-users'
            }
        }
    },

    async mounted() {

        this.$nextTick(async () => {
            this.$charging()
            let data = {
                idEntite: this.getEtablissement(this.$route.params.libelle).id,
                debutPeriode: 'null',
                finPeriode: 'null',
                withCours: 'cours',
                idClasse : this.$route.params.classeId
            }
            if(this.getClasses.length == 0){
                await this.getClasse(data).then(() => {}).catch((error) => {
                    // eslint-disable-next-line no-undef
                    Swal.fire({
                        allowOutsideClick : false,
                        title: "Oops...",
                        text: `${error.data.message}`,
                        icon: "error",
                    })
                    this.$unCharging()
                });
            }
            let libelleClasse = this.getClasses.filter((item) => item.id == this.$route.params.classeId)[0].libelle
            this.objects.titlePage = "Gestion des cours de la classe "+ libelleClasse
            this.getBreadcrumb()
            await this.getCoursPeriodeClasse(data).then(async () => {
                this.bodyCoursSimple = await this.getCoursSimple
                this.coursNonProgramme = await this.getCoursSimple.filter((item) => this.countNbreProgramme(item.id) == 0)
                this.bodyCours = this.getCours
                this.$unCharging()
            });

        })
    },

    computed: {
        ...mapGetters('classes', ['getClasses']),
        ...mapGetters('matieres', ['getMatieres']),
        ...mapGetters('cours', ['getCours', 'getEmploiTemps', 'getCoursSimple']),
        ...mapGetters('etablissements', ['getEtablissement']),
        ...mapGetters(['getDataMenu']),
    },

    methods: {
        ...mapActions('cours', ['getCoursPeriodeClasse', 'sendDocumentCours']),
        ...mapActions('etablissements', ['getEntite']),
        ...mapActions('classes', ['getClasse']),
        ...mapActions('note', ['getNotes']),

        /**
         * format Date with moment.js
         * @param {String} date 
         */
        formatDate(date){
           // eslint-disable-next-line no-undef
           return moment(date).locale('fr').format('llll')
        },

        /**
         * Get cours with filtre option
         */
        async filtreCours() {
            this.$charging()
            this.isloading = true
            this.bodyCours = [];
            let data = {
                idEntite: this.getEtablissement(this.$route.params.libelle).id,
                debutPeriode: this.debut,
                finPeriode: this.fin,
                withCours: 'cours' ,
                idClasse : this.$route.params.classeId
            }
           
            await this.getCoursPeriodeClasse(data).then(async () => {
                this.isloading = false
                this.$unCharging()
                this.bodyCours = this.getCours
            });
        },

        /** 
         * Modal to send document on cours
         * @param {Integer} idCours 
         */
        document(idCours) {
            // eslint-disable-next-line no-undef
            let sauvegarde = $("#sauvegarde");
            // eslint-disable-next-line no-undef
            let fermer = $("#fermer");
            // eslint-disable-next-line no-undef
            let bodyModal = $("#bodyModal");
            // eslint-disable-next-line no-undef
            let formModal = $("#formModal");
            // eslint-disable-next-line no-undef
            let declenche = $("#declenche");
            // eslint-disable-next-line no-undef
            $('#exampleModalLabel').html('Déposer un document');

            bodyModal.html('');
            formModal.attr('class', 'modal-dialog modal-lg');
            fermer.html('Annuler');
            sauvegarde.html("Sauvegarder").show();
            bodyModal.html(this.$formulaireDepotDocument());
            declenche.trigger('click');

            // eslint-disable-next-line no-undef
            let doc = $("#doc");
            // eslint-disable-next-line no-undef
            let select7 = $("#select7");
            select7.on('change', () => {
                doc.html('')
                if (select7.val() == "exo") {
                    doc.html('').append(`
                        <div class="row">
                            <div class="form-group col-md-6 col-xs-6">
                                <label for="datelimite" style="font-weight: bold;">Date limite</label>
                                <input id="datelimite" type="date" class="form-control form-control-solid" name="datelimite">
                            </div>
                            <div class="form-group col-md-6 col-xs-6">
                                <label for="file" style="font-weight: bold;">Fichier max : 2Mo (.doc, .docx, .pdf, .xls)</label>
                                <input id="file" type="file" class="form-control form-control-solid" name="file">
                            </div>
                        </div>
                        <div class="row">
                            <div class="form-group col-md-6 col-xs-6">
                                <label for="description" style="font-weight: bold;">Description</label>
                                <textarea id="description" class="form-control form-control-solid" cols="40" rows="4"></textarea>
                            </div>
                        </div>
                    `);
                } else {
                    doc.html('').append(`
                        <div class="row">
                            <div class="col-md-6 col-xs-6">
                                <label for="file" style="font-weight: bold;">Fichier max : 2Mo (.doc, .docx, .pdf, .xls)</label>
                                <input id="file" type="file" class="form-control form-control-solid" name="file">
                            </div>
                            <div class="col-md-6 col-xs-6">
                                <label for="description" style="font-weight: bold;">Description</label>
                                <textarea id="description" class="form-control form-control-solid" cols="40" rows="4"></textarea>
                            </div>
                        </div>
                    `);
                }

            });
            sauvegarde.on('click', async () => {
                // eslint-disable-next-line no-undef
                let file = $("#file");
                // eslint-disable-next-line no-undef
                let datelimite = $("#datelimite");
                // eslint-disable-next-line no-undef
                let libelle = $("#libelle");
                // eslint-disable-next-line no-undef
                let description = $("#description");
                if(file[0] == undefined || libelle.val().length == 0 || select7.val().length == 0){
                    // eslint-disable-next-line no-undef
                    Swal.fire({
                        allowOutsideClick : false,
                        title: "Oops...",
                        text: (select7.val().length == 0) ?  `Choisir le type de document`: (libelle.val().length == 0  ? `Entrer le libellé du document` : `Choisir le fichier et respecter la taille exigée 2Mo`),
                        icon: "error",
                    })
                }else{
                    await this.sendDocument(file, datelimite, libelle, description, select7, idCours, fermer)
                }
            })
        },

        /**
         * Send document function 
         * @param {File} file 
         * @param {Date} datelimite 
         * @param {String} libelle 
         * @param {String} description 
         * @param {String} select7 
         * @param {Integer} idCours 
         * @param {String} fermer 
         */
        async sendDocument(file, datelimite, libelle, description, select7, idCours, fermer) {
            const formData = new FormData();
            formData.append('file', file[0].files[0]);
            formData.append('datelimite', datelimite.val());
            formData.append('libelle', libelle.val());
            formData.append('description', description.val());
            formData.append('type', select7.val());
            formData.append('id_compte', Base64.decode(Ls.get('user.id')));
            formData.append('id_entite', this.getEtablissement(this.$route.params.libelle).id);
            formData.append('id_cours', idCours)

            await this.sendDocumentCours(formData).then((response) => {
                // eslint-disable-next-line no-undef
                Swal.fire({
                    allowOutsideClick : false,
                    title: "Connexion réussie",
                    text: `${response.data.message}`,
                    icon: "success",
                }).then(() => {
                    fermer.trigger('click')
                })
            }).catch((error) => {
                // eslint-disable-next-line no-undef
                Swal.fire({
                     allowOutsideClick : false,
                    title: "Oops...",
                    text: `${error.data.message}`,
                    icon: "error",
                })
            })

        },

        /**
         * Breadcrumb event
         */
        getBreadcrumb() {
            this.$emit('getBreadcrumb', this.objects)
        },

        /**
         * Breadcrumb event
         */
        getButtonRetour(data) {
            this.$emit('getButtonRetour', data)
        },

        /**
         * Get Programme of cours
         * @param {Integer} idCours 
         */
        programme(idCours) {
            this.$router.push({ name: 'cours-details', params: { libelle: this.$route.params.libelle, id: idCours, dateDebut: this.debut, dateFin: this.fin } })
        },


        activeTabs(tabactive){
            this.coursTabs = false
            this.progTabs = false
            this.matTabs = false

            if(tabactive == 'matTabs'){
                this.matTabs = true
            }else if(tabactive == 'progTabs'){
                this.progTabs = true
            }else if(tabactive == 'coursTabs'){
                this.coursTabs = true
            }
        },

        /**
         * Get All Cours without periode
         */
        async getAllCours(){
            this.$charging()
            let data = {
                idEntite: this.getEtablissement(this.$route.params.libelle).id,
                debutPeriode: null,
                finPeriode: null,
                withCours: 'cours',
                idClasse : this.$route.params.classeId
            }

            await this.getCoursPeriodeClasse(data).then(() => {
                this.bodyCours = this.getCours
                this.$unCharging()
            });
        },

        countNbreProgramme(idCours){
            let count = this.getEmploiTemps.filter((item) => item.id == idCours)
            return count.length
        },


        /**
         * Get contenu of cours
         * @param {Integer} idCours 
         */
        contenu(idCours){
            this.$router.push({ name: 'document-cours', params: { libelle: this.$route.params.libelle, id: idCours } })
        },

        /**
         * Ge tfiche de note
         * @param {Integer} idCours 
         */
        grilleNote(idCours) {
            this.$router.push({ name: 'note', params: { libelle: this.$route.params.libelle, id: idCours } })
        },

        statistique(idCours){
            this.$router.push({ name: 'cours-stat', params: { libelle: this.$route.params.libelle, coursId: idCours } })
        }
    },

    created(){
        if(!this.$route.params.classeId){
            this.getBreadcrumb()
        }else{
            let data = {
                retour : true,
                link : "classe",
                title : "Retour",
                params : {libelle : this.$route.params.libelle}
            }
            this.getButtonRetour(data)
        }
    }

}
</script>