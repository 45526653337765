import * as getters from './getters.js'
import mutations from './mutations.js'
import * as actions from './actions.js'

const initialState = {
    cours : [],
    oneCours : [],
    coursJours : [],
    emploiTemps : [],
    coursSimple : [],
    heuresTotal : 0,
    heuresExecute : 0,
    heuresPasExecute : 0,
    heurePrevue : 0,
}

export default {
    namespaced : true,
    state : initialState,
    getters : getters,
    mutations : mutations,
    actions : actions
}