<template>
    <div class="modal fade" id="exampleModal" tabindex="-1" data-backdrop="static" data-keyboard="false" role="dialog" aria-labelledby="exampleModalLabel"
        aria-hidden="true">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLabel">{{ title }}</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <div v-html="body"></div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-danger" id="fermer" data-dismiss="modal" v-if="close != true">{{ close }}</button>
                    <button type="button" @click.prevent="onSubmit" :disabled="disabled" class="btn btn-primary save">{{ save }}</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props : {
        title : {type : String, require: true},
        body : {require:true},
        close : {type : String, require : true},
        save : {type : String, require : true},
        disabled : {type : Boolean, require : false, default : false}
    },

    data() {
        return {
            show : false
        }
    },

    methods: {
        onSubmit(){
            this.$emit('submit')
        }
    },
}
</script>