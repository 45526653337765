<template>
    <div v-show="showFirstButton" class="text-center"> 
        <button style="width: 50%;" :style="firstStyle" :class="classFirstButton" v-if="!isLoading" @click.prevent="actionSubmit" type="submit">{{ labelFirstButton }}</button>
        <button :style="firstStyle" :class="classFirstButton" v-else disabled style="width: 50%;">
            <img src="/assets/images/loading_2.gif" alt="" style="width: 10%;">
            Patientez-svp...</button>
        </div>
        <div v-show="showSecondButton" class="float-right">
            <button :class="classSecondButton" :style="style" @click.prevent="actionEvent">{{ labelSecondButton }}</button>
    </div>
</template>

<script>
    export default {
        emits : ['getActionEvent'],
        props : {
            showFirstButton : {default: true, require : false},
            showSecondButton : {default: true, require : false},
            isLoading : {type : Boolean, require : true},
            actionSubmit : {type : Function, require : true},
            labelFirstButton : {type : String, require : true},
            labelSecondButton : {type : String, require : true},
            classSecondButton : {type : String, require : true},
            classFirstButton : {type : String, require : true},
            style : {type : String, require : false},
            firstStyle : {type : String, require : false},
        },

        methods: {
            actionEvent(){
                this.$emit('getActionEvent')
            }
        },
    }
</script>