<template>
    <div>
        <div class="app-container app-theme-white body-tabs-shadow">
            <div class="app-container">
                <div class="h-100  bg-animation">
                    <div class="d-flex h-100 justify-content-center align-items-center">
                        <div class="mx-auto app-login-box col-md-8">
                            <div class="app-logo-inverse mx-auto mb-4">
                                <img src="../../../public/assets/images/1.png" alt="" style="width:90%">
                            </div>
                            <router-view></router-view>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapActions } from 'vuex' 
    export default {
        async mounted() {
            await this.generateKey({
                login : 'teacher_test',
                password : 'easylmd_teacher'
            })
        },

        methods: {
            ...mapActions('auth', ['generateKey'])
        },
    }
</script>