<!-- eslint-disable no-undef -->
<template>
    <div class="modal-dialog w-100 mx-auto">
        <div class="modal-content">
            <div class="modal-body">
                <div class="h5 modal-title text-center">
                    <h4 class="mt-2">
                        <div class="">Activer votre compte</div>
                        <span style="font-size: 12px;">Un code vous sera envoyer à votre adresse mail. <br> <span style="font-size: 12px; color:orange" > <i class="fa fa-info-circle"></i> Veuillez saisir une adresse valide</span> </span>
                    </h4>
                </div>
                <form class="">
                    <div class="form-row">
                        <div class="col-md-12">
                            <label for="">Adresse mail <sup class="text-danger">*</sup> </label>
                            <div class="position-relative form-group">
                                <input name="email" id="active_email" placeholder="Email"
                                    type="email" class="form-control" v-model="formActivation.email">
                            </div>
                        </div>
                        <!-- <div class="col-md-12">
                            <div class="position-relative form-group">
                                <label for="">NIP</label>
                                <input name="nip" id="active_nip"
                                    placeholder="NIP" type="text"
                                    class="form-control" v-model="formActivation.nip">
                            </div>
                        </div> -->
                    </div>
                </form>
                <div class="divider"></div>
                <h6 class="mb-0">Allez à la page de connexion ? <a href="javascript:void(0);" @click.prevent="getActionEvent" class="text-primary" style="font-size: 12px;">Cliquez ici</a></h6>
            </div>
            <input type="hidden" name="" id="declenche" data-toggle="modal" data-target="#exampleModal">
            <button-validation :isLoading="isLoading" 
                :actionSubmit="onActivation" 
                labelFirstButton="Reçevoir mon code" 
                :showSecondButton="false"
                classFirstButton="btn-sm btn btn-danger mb-3"
                @getActionEvent="getActionEvent"
                ></button-validation>
        </div>
    </div>
    <modal-view :title="title" :save="save" :close="close" :body="body" :disabled="disabled" @submit="onSubmit"></modal-view>
</template>

<script>
import ModalView from '@/components/widgets/modals/ModalView.vue';
import ButtonValidation from '@/components/widgets/buttons/ButtonValidation.vue';
import { mapActions } from 'vuex';
import { Base64 } from 'js-base64';
export default {
    components : {
        ModalView,
        ButtonValidation
    },

    data() {
        return {
            isLoading : false,
            disabled : true,
            code : '',
            formActivation : {
                email : '',
                nip : ''
            },
            title : 'Code d\'activation',
            save : 'Confirmer',
            close : 'Fermer',
            body : ''
        }
    },

    mounted(){
        const vm = this

        // eslint-disable-next-line no-undef
        $('.code').addClass('spinner')

        // eslint-disable-next-line no-undef
        $(document).on('click', '#resend', this.resendActivation); 

        // eslint-disable-next-line no-undef
        $(document).on('blur', '.code', function(){
             // eslint-disable-next-line no-undef
             if($(this).val() == '' || $(this).val() == null){
               // eslint-disable-next-line no-undef 
                $(this).attr('style', 'border-color:red');
                // eslint-disable-next-line no-undef
                $('.save').attr('disabled', true);
            }
        }) 
        
        // eslint-disable-next-line no-undef
        $(document).on('keyup', '.code', function(){
            // eslint-disable-next-line no-undef
            if($(this).val() < 0 || $(this).val() >= 10){
               // eslint-disable-next-line no-undef 
                $(this).attr('style', 'border-color:red');
                // eslint-disable-next-line no-undef
                $('.save').attr('disabled', true);
            }else{
                // eslint-disable-next-line no-undef
                 $(this).attr('style', 'border-color:green');
            }            

            // eslint-disable-next-line no-undef
            let tab =  $('.code')
            let indice = 0
            vm.code = ''
            for (let index = 0; index < tab.length; index++) {
                // eslint-disable-next-line no-undef
                if($(tab[index]).val() == '' || $(tab[index]).val() < 0 || $(tab[index]).val() >= 10){
                    indice = indice + 1
                }else{
                    // eslint-disable-next-line no-undef
                    vm.code = vm.code+$(tab[index]).val()
                }
            }

            if(indice == 0){
                // eslint-disable-next-line no-undef
                $('.save').attr('disabled', false);
            }
        })
    },

    methods: {
        ...mapActions('auth', ['activationCompte', 'validationCompte']),

        async resendActivation(){
            this.disabled = true,
            this.body = `<div class="text-center">
                            <h6 style>
                                <img src="/assets/images/loading_2.gif" alt="" style="width: 5%;"> Patientez svp...
                            </h6> 
                        </div>`;
            await this.activationCompte(this.formActivation).then((response) => {
                this.body = `<div class="text-center mb-4">
                <span style="font-size: 15px; color:orange" id="message"><i class="fa fa-info-circle"></i> ${response.data.message}</span> 
                </div>
                <div class="row form-group">
                    <div class="col-lg-3">
                        <input type="number" min="0" max="9"  class="form-control code">
                    </div>
                    <div class="col-lg-3">
                        <input type="number" min="0" max="9"  class="form-control code">
                    </div>
                    <div class="col-lg-3">
                        <input type="number" min="0" max="9"  class="form-control code">
                    </div>
                    <div class="col-lg-3">
                        <input type="number" min="0" max="9"  class="form-control code">
                    </div>
                </div> 
                <a href="#" id="resend">Me renvoyer le code d'activation</a>`;

                
            })
        },

        async onActivation(){
            this.isLoading = true
            if(this.formActivation.email == ""){
                // eslint-disable-next-line no-undef
                Swal.fire({
                    allowOutsideClick : false,
                title: "Oops... Une erreur s'est produite.",
                text: "Veuillez renseigner le champ email",
                icon: "error",
                }).then(() => {
                    this.isLoading = false
                })
            }else{
                await this.activationCompte(this.formActivation).then((response) => {
                    this.isLoading = false
                    if(response.data.code == 202){
                        // eslint-disable-next-line no-undef
                        Swal.fire({
                            allowOutsideClick : false,
                        title: "Oops...",
                        text: `${response.data.message}`,
                        icon: "info",
                        }).then((ans) => {
                            if(ans.isConfirmed){
                                if(response.data.index == "NoPass"){
                                    this.$router.push({name:'index-finalisation', params : {email : Base64.encode(this.formActivation.email)}})
                                }else{
                                    this.$router.push({name:'index-connexion'})
                                }
                            }
                        })
                    }else if(response.data.code == 302){
                        // eslint-disable-next-line no-undef
                        Swal.fire({
                            allowOutsideClick : false,
                        title: "Oops... Une erreur s'est produite.",
                        text: `${response.data.message}`,
                        icon: "error",
                        })
                    }else {
                        this.body =  `<div class="text-center mb-4">
                            <span style="font-size: 15px; color:orange" id="message"><i class="fa fa-info-circle"></i> Vous avez reçu un code d'activation. Consulter votre boîte mail</span> 
                            </div><div class="row form-group">
                                    <div class="col-lg-3">
                                        <input type="number" min="0" max="9"  class="form-control code">
                                    </div>
                                    <div class="col-lg-3">
                                        <input type="number" min="0" max="9"  class="form-control code">
                                    </div>
                                    <div class="col-lg-3">
                                        <input type="number" min="0" max="9"  class="form-control code">
                                    </div>
                                    <div class="col-lg-3">
                                        <input type="number" min="0" max="9"  class="form-control code">
                                    </div>
                                </div> 
                                <a href="#" id="resend">Me renvoyer le code d'activation</a>`
                        
                        // eslint-disable-next-line no-undef
                        $('#declenche').trigger('click')
                    }
                    
                }).catch((error) => {
                    this.isLoading = false
                    // eslint-disable-next-line no-undef
                    Swal.fire({
                        allowOutsideClick : false,
                        title: "Oops... Une erreur s'est produite.",
                        text: `${(error.data) ? error.data.message : error}`,
                        icon: "error",
                    })
                })
            }
        },

        async onSubmit(){
            let data = {
                email : this.formActivation.email,
                code : this.code
            }
            await this.validationCompte(data).then((response) => {
                if(response.data.code == 204){
                    // eslint-disable-next-line no-undef
                    Swal.fire({
                        allowOutsideClick : false,
                        title: "Oops...",
                        text: `${response.data.message}`,
                        icon: "error",
                        })
                }else if(response.data.code == 202){
                    let text = `<i class="fa fa-times-circle"></i> ${response.data.message}`
                    // eslint-disable-next-line no-undef
                   $('#message').html(text)
                   // eslint-disable-next-line no-undef
                   $('#message').attr('style', 'font-size: 15px; color:red')
                }else{
                    // eslint-disable-next-line no-undef
                    $('#fermer').trigger('click')
                    // eslint-disable-next-line no-undef
                    Swal.fire({
                        allowOutsideClick : false,
                        title: "Completed...",
                        text: `${response.data.message}`,
                        icon: "success",
                    }).then((ans) => {
                        if(ans.isConfirmed){
                            this.$router.push({name:'index-finalisation', params : {email : Base64.encode(this.formActivation.email)}})
                        }
                    })
                }
            })
        },

        getActionEvent(){
            this.$router.push({name: 'index-login'})
        }
    },
}
</script>