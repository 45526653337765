import * as echarts from 'echarts';
export default {
    methods: {
        $chartVertical(xAxis, yAxis, yData, idElement, color, serieName, colorGraph, text){
            echarts.init(document.getElementById(idElement), null , {
                width: 'auto' ,
                height:'600px' ,
            }).setOption({
                // title : {
                //     text : text,
                //     textStyle : { 
                //         fontSize : 15
                //     } 
                // },
                title: {
                    text: text,
                },
                grid: {
                    top : '10%',
                    left: '3%',
                    right: '8%',
                    bottom: '3%',
                    containLabel: true
                  },
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                    type: 'shadow'
                    }
                },
                legend: {
                    show : true
                },
                xAxis: xAxis,
                yAxis: yAxis,
                series: [
                    {
                        name: serieName,
                        data: yData,
                        markPoint: {
                            data: [
                                { type: 'max', name: 'Max' },
                                { type: 'min', name: 'Min' }
                            ]
                        },
                        markLine: {
                            data: [{ type: 'average', name: 'Avg' }]
                        },
                        type: 'bar',
                        showBackground: true,
                        backgroundStyle: {
                            color: color
                        },
                        itemStyle : {
                            color : (colorGraph) ? colorGraph : 'auto'
                        },
                        animationDelay: function (idx) {
                            return idx * 10;
                        }
                    },
                    {
                        name: serieName,
                        data: yData,
                        type: 'line',
                        showBackground: true,
                        backgroundStyle: {
                            color: color
                        },
                        itemStyle : {
                            color : "#F71414"
                        },
                        animationDelay: function (idx) {
                            return idx * 10;
                        }
                    }
                ]
            });
        },

        $chartCircle(idElement, data, color, height = '300px'){
            echarts.init(document.getElementById(idElement), null, {
                width: 'auto' ,
                height: height,
            }).setOption({
                color,
                title: {
                    //text: text,
                    //subtext: subText,
                    left: 'center'
                },
                tooltip: {
                    trigger: 'item'
                },
                legend: {
                    orient: 'vertical',
                    left: 'left'
                },
                series: [
                    {
                        name: 'Evolution du cours',
                        type: 'pie',
                        selectedMode: 'single',
                        radius: '50%',
                        label: {
                            formatter: '{b|{b}}{abg|}\n{hr|}\n  {c|{c}}h  {per|{d}%}  ',
                            backgroundColor: '#F6F8FC',
                            borderColor: '#8C8D8E',
                            borderWidth: 1,
                            borderRadius: 4,
                            rich: {
                              a: {
                                color: '#6E7079',
                                lineHeight: 22,
                                align: 'center'
                              },
                              hr: {
                                borderColor: '#8C8D8E',
                                width: '100%',
                                borderWidth: 1,
                                height: 0
                              },
                              b: {
                                fontWeight: 'bold',
                                color: '#6E7079',
                                lineHeight: 22,
                                align: 'center'
                              },
                              c: {
                                color: '#4C5058',
                                fontSize: 14,
                                lineHeight: 33,
                                align: 'center'
                              },
                              per: {
                                color: '#fff',
                                backgroundColor: '#4C5058',
                                padding: [3, 4],
                                borderRadius: 4
                              }
                            }
                          },
                        data: data,
                        emphasis: {
                            itemStyle: {
                            shadowBlur: 10,
                            shadowOffsetX: 0,
                            shadowColor: 'rgba(0, 0, 0, 0.5)'
                            }
                        }
                    }
                ]
            });
        }
    },
}