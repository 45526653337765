import * as rootTypes from './mutation-types'

export default({
    [rootTypes.GET_MENU] : function(state, data){
        state.menu = data
    },
    
    [rootTypes.GET_ANNEE_ACADEMIQUE] : function(state, data){
        state.annee = data
    }
})