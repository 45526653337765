<template>
  <div class="app-page-title app-page-title-simple" style="margin-left: 2%;">
    <div class="page-title-wrapper">
      <div class="page-title-heading">
        <div>
          <div class="page-title-head center-elem">
            <span class="d-inline-block pr-2">
              <i :class="icon" class="font-weight-bold"></i>
            </span>
            <span class="d-inline-block font-weight-bold">{{ titlePage }}</span>
          </div>
          <div class="page-title-subheading opacity-10">
            <nav class="" aria-label="breadcrumb">
              <ol class="breadcrumb">
                <li class="breadcrumb-item">
                  <a>
                    <i aria-hidden="true" class="fa fa-home"></i>
                  </a>
                </li>
                <template v-for="(item, key) in breadcrumb" :key="key">
                    <li v-if="breadcrumb.length > key + 1" class="breadcrumb-item font-weight-bold">
                    <a
                        href="javascript:void(0);"
                        @click.prevent="pageRoute(item.name, item.params)"
                        >{{ item.libelle }}</a
                    >
                    </li>
                    <li v-else class="active breadcrumb-item font-weight-bold" aria-current="page">
                    {{ item.libelle }}
                    </li>
                </template>
              </ol>
            </nav>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- <div class="main-card" style="margin-top: -2%">
    <div class="card-body">
      <nav class="" aria-label="breadcrumb">
        <ol class="breadcrumb" style="background-color: white">
          <template v-for="(item, key) in breadcrumb" :key="key">
            <li v-if="breadcrumb.length > key + 1" class="breadcrumb-item">
              <a
                href="javascript:void(0);"
                @click.prevent="pageRoute(item.name, item.params)"
                >{{ item.libelle }}</a
              >
            </li>
            <li v-else class="active breadcrumb-item" aria-current="page">
              {{ item.libelle }}
            </li>
          </template>
        </ol>
      </nav>
    </div>
  </div> -->
</template>

<script>
export default {
  props: {
    breadcrumb: { type: Object, required: true },
    titlePage : {type: String, required : true},
    icon : {type: String, required : true},

  },

  methods: {
    pageRoute(name, params) {
      if (params) {
        return this.$router.push({ name: name, params: params });
      } else {
        return this.$router.push({ name: name });
      }
    },
  },
};
</script>