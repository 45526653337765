export const testApi = () => {
    return new Promise((resolve, reject) => {
        window.axios.get('teacher/test-api').then((response) =>  {
            if(!response || response.data.code != 200){
                reject(response)
            }else{
                resolve(response)
            }
        }).catch(err => {
            reject(err)
        })
    })
}