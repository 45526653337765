<template>
    <div class="">
        <!-- <div class="card-header">
            <div class="text-center">
                <div role="group" class="mt-4 btn-group-lg btn-group">
                    <ul class="body-tabs body-tabs-layout tabs-animated body-tabs-animated nav" style="text-transform : uppercase">
                        <li class="nav-item">
                            <a @click="activeTabs('matTabs')" role="tab" class="nav-link" :class="{'active' : matTabs}" href="#">
                                <span class="font-weight-bold">
                                    <span class="badge badge-pill badge-danger ml-0">{{ getMatieres.length }}</span>
                                    Liste des matieres</span>
                            </a>
                        </li> 
                        <li class="nav-item mb-4">
                            <a @click.prevent="activeTabs('coursTabs')" role="tab" class="nav-link" :class="{'active' : coursTabs}" href="">
                                <span class="font-weight-bold">
                                    <span class="badge badge-pill badge-danger ml-0">{{ bodyCoursSimple.length }}</span>
                                    Cours non programmé</span>
                            </a>
                        </li>
                        <li class="nav-item mb-4">
                            <a @click.prevent="activeTabs('progTabs')" role="tab" class="nav-link" :class="{'active' : progTabs}" href="">
                                <span class=" font-weight-bold">
                                    <span class="badge badge-pill badge-danger ml-0">{{ bodyCours.length }}</span>
                                    Cours programmé</span>
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </div> -->
        <div class="">
            <Transition>
                <!-- <div class="row" v-if="matTabs" style="margin-left: 0px; margin-right : 0px;">
                    <template v-if="getMatieres.length == 0">
                        <div class="col-md-12" style="">
                            <div class="main-card mb-3 card">
                                <div class="card-body">
                                    <div class="text-center">
                                    <h6 class="font-weight-bold text-danger">
                                        <i class="fa fa-info-circle"></i>
                                        Aucune matière</h6>  
                                        <p class="text-muted">Vous n'enseignez aucune matière dans cet établissement</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </template>
                    <template v-else>
                        <template v-for="(item, key) in getMatieres" :key="key">
                            <div class="col-6">
                                <card-matiere :libelleMatiere="item.libelle" :code="item.code" :idMatiere="item.id"></card-matiere>
                            </div>
                        </template>
                    </template>
                </div> -->
                <!-- <div v-if="coursTabs" class="coursTabs">
                    <div class="">
                        <div class="form-group col-lg-4" style="">
                            <label for="select" style="font-weight: bold;">Choisir la classe</label>
                            <select id="select_1"  class="form-control"  v-model="classe2" name="classe1">
                                <option value="">--- Choisir une classe ---</option>
                                <option value="all">Toutes les classes</option>
                                <template v-for="(item, key) in getClasses" :key="key">
                                    <template v-if="classe == item.libelle">
                                        <option :value="item.libelle" selected>{{ item.libelle }} </option>
                                    </template>
                                    <template v-else>
                                        <option :value="item.libelle">{{ item.libelle }} </option>
                                    </template>
                                </template>
                            </select>
                        </div>
                    </div>
                    <div class="row" style="margin-left: 0px; margin-right : 0px;">
                        <template v-if="bodyCoursSimple.length == 0">
                            <div class="col-md-12">
                                <div class="main-card mb-3 card">
                                    <div class="card-body">
                                        <div class="text-center">
                                        <h6 class="font-weight-bold text-danger">
                                            <i class="fa fa-info-circle"></i>
                                            Vous n'avez aucun cours</h6>  
                                            <p class="text-muted">Vous n'êtes assigné à aucun cours</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </template>
                        <template v-else>
                            <template v-for="(item, key) in bodyCoursSimple" :key="key">
                                <div class="col-4">
                                    <card-cours :className="'card'+key" 
                                    :details2="true"
                                    :details="false"
                                    :classDataName="item.classe" 
                                    :courLibelle="item.libelleMatiere"
                                    :modeEva="item.modeval"
                                    :nbhcmtd="item.nbhcmtd"
                                    :nbhtp="item.nbhtp"
                                    :nbhtotal="item.nbhtotal"
                                    :heureExe="item.heureExecute"
                                    @doc="document(item.id)"
                                    @count="contenu(item.id)"
                                    :countProgramme="countNbreProgramme(item.id)"
                                    ></card-cours>
                                </div>
                            </template>
                        </template>
                    </div>
                </div> -->
                <div class="secondTabs">
                    <div class=" mb-2" style="margin-left: 15px; margin-right : 15px;">
                        <div class="">
                            <div class="row" style="width: 100%">
                                <div class="form-group col-md-5 col-xs-12">
                                    <label for="select" style="font-weight: bold;">Choisir la classe</label>
                                    <select id="select"  class="form-control"  v-model="classe" name="classe">
                                        <option value="">--- Choisir une option ---</option>
                                        <option value="all">Toutes les classes</option>
                                        <template v-for="(item, key) in getClasses" :key="key">
                                            <template v-if="classe == item.libelle">
                                                <option :value="item.libelle" selected>{{ item.libelle }} </option>
                                            </template>
                                            <template v-else>
                                                <option :value="item.libelle">{{ item.libelle }} </option>
                                            </template>
                                        </template>
                                    </select>
                                </div>
                                <!-- <div class="col-md-6 col-xs-5">
                                    <label for="" style="font-weight: bold;">Choisir la période</label>
                                    <div class="d-flex">
                                        <div class="input-group">
                                            <div class="input-group-prepend">
                                                <div class="input-group-text">
                                                    De
                                                </div>
                                            </div>
                                            <input type="date" class="form-control" name="" v-model="debut">
                                            <div class="input-group-prepend">
                                                <div class="input-group-text">
                                                    A
                                                </div>
                                            </div>
                                            <input type="date" class="form-control" name="" v-model="fin">
                                        </div>
                                        <div style="margin-left:2%;">
                                            <button @click.prevent="filtreCours" v-if="!isloading" class="btn-icon btn btn-success fw-bold">
                                                <i class="fa fa-search"></i>
                                            </button>
                                            <button class="btn btn-success fw-bold" v-else disabled style="width : 45px">
                                                <img src="/assets/images/loading_2.gif" alt="" style="width: 100%;">
                                            </button>
                                        </div>
                                    </div>
                                    
                                </div> -->
                                <!-- <div class="col-md-3 col-xs-3 mt-2">
                                    <button @click.prevent="getAllCours" class="btn-icon btn btn-danger w-45 fw-bold" style="margin-left:2%; margin-top:9%">
                                        <i class="fa fa-eye"></i> Tous les cours
                                    </button> 
                                </div> -->
                            </div>
                        </div>
                    </div>
                    <div style="text-align : right">
                        
                    </div>
                    <div class="row" style="margin-left: 0px; margin-right : 0px;">
                        <template v-if="bodyCours.length == 0">
                            <div class="col-md-12">
                                <div class="main-card mb-3 card">
                                    <div class="card-body">
                                        <div class="text-center">
                                        <h6 class="font-weight-bold text-danger">
                                            <i class="fa fa-info-circle"></i>
                                            Aucun cours programmé pour cette semaine</h6>  
                                            <p class="text-muted">Modifier la période pour voir les anciens cours</p>
                                            <button @click.prevent="getAllCours" class="btn btn-primary">
                                                Voir tous vos cours
                                            </button> 
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </template>
                        <template v-else>
                            <template v-for="(item, key) in bodyCours" :key="key">
                                <div class="col-md-6 col-xs-12">
                                    <card-cours 
                                    :className="'card'+key" 
                                    :classDataName="item.classe" 
                                    :courLibelle="item.libelleMatiere"
                                    :modeEva="item.modeval"
                                    :nbhcmtd="item.nbhcmtd"
                                    :nbhtp="item.nbhtp"
                                    :nbhtotal="item.nbhtotal"
                                    :heureExe="item.heureExecute"
                                    :heureProg="item.heureProgramme"
                                    @doc="document(item.id)"
                                    @prog="programme(item.id)"
                                    @note="grilleNote(item.id)"
                                    @count="contenu(item.id)"
                                    @statistique="statistique(item.id)"
                                    :pource="(parseInt(item.heureExecute)*1)/parseInt(item.nbhtotal)"
                                    ></card-cours>
                                </div>
                            </template>
                        </template>
                    </div>
                </div>
            </Transition>
        </div>
    </div>
    
</template>

<style scoped>
    .v-enter-active {
        transition: opacity 0.5s ease;
    }
    /* .v-leave-active {
        
    } */

    .v-enter-from, .v-leave-to {
        opacity: 0;
    }
</style>

<script>
import CardCours from '@/components/widgets/cards/CardCours.vue';
import { mapGetters, mapActions } from 'vuex';
import Ls from '@/services/ls'
import { Base64 } from 'js-base64'
export default {
    emits: ['getBreadcrumb', 'getButtonRetour'],
    components: {
        CardCours,
    },

    data() {
        return {
            isloading: false,
            coursTabs: true,
            progTabs: false,
            // eslint-disable-next-line no-undef
            debut: moment().weekday(1).format("YYYY-MM-DD"),
            // eslint-disable-next-line no-undef
            fin: moment().weekday(7).format("YYYY-MM-DD"),
            classe: '',
            classe2: '',
            tableTitle: 'Vos Cours de la semaine',
            bodyCours: [],
            bodyCoursSimple: [],
            objects : {
                breadcrumb: [
                    {
                        libelle: 'Etablissement',
                        name: "index-etablissement",
                        params: { libelle: this.$route.params.libelle }
                    },
                    {
                        libelle : this.$route.params.libelle,
                        name : "index-entite",
                        params : {libelle : this.$route.params.libelle}
                    },
                    {
                        libelle:  'Gestion des cours / Cours Programmé',
                        name: "cours"
                    },
                ],
                titlePage : 'Cours Programmé('+ this.$route.params.libelle+')',
                icon : 'fa fa-book-reader'
            }
        }
    },

    async mounted() {
        this.$nextTick(async () => {
             // eslint-disable-next-line no-undef
            $(document).on('change', '#select', () => {
                // eslint-disable-next-line no-undef
                this.classe = $('#select').val()
                this.changeBody()
            })
           
            let data = {
                idEntite: this.getEtablissement(this.$route.params.libelle).id,
                debutPeriode: 'null',
                finPeriode: 'null',
                withCours: 'cours'
            }
            if(this.getClasses.length == 0){
                this.$charging()
                await this.getClasse(data).then(() => {}).catch((error) => {
                    // eslint-disable-next-line no-undef
                    Swal.fire({
                        allowOutsideClick : false,
                        title: "Oops...",
                        text: `${error.data.message}`,
                        icon: "error",
                    })
                    this.$unCharging()
                });
            }

           if(this.getCoursSimple == undefined || this.getCours == undefined || this.getCoursSimple.length == 0 || this.getCours.length == 0){
            this.$charging()    
            await this.getCoursPeriode(data).then(async () => {
                    this.$unCharging()
                    this.bodyCoursSimple = await this.getCoursSimple
                    this.bodyCours = this.getCours
                });
            }else{
                this.bodyCoursSimple = await this.getCoursSimple
                this.bodyCours = this.getCours
            }
            
            if(this.$route.params.classeId){
                let libelleClasse = this.getClasses.filter((item) => item.id == this.$route.params.classeId)[0].libelle
                this.classe = this.classe2 = libelleClasse
                this.bodyCoursSimple = this.getCoursSimple.filter((item) => item.classe === this.classe2)
                this.bodyCours = this.getCours.filter((item) => item.classe === this.classe)
                this.objects.titlePage = "Gestion des cours de la classe "+ this.classe
                this.getBreadcrumb()
            }
        })
    },

    computed: {
        ...mapGetters('classes', ['getClasses']),
        ...mapGetters('cours', ['getCours', 'getEmploiTemps', 'getCoursSimple']),
        ...mapGetters('etablissements', ['getEtablissement']),
        ...mapGetters(['getDataMenu']),
    },

    methods: {
        ...mapActions('cours', ['getCoursPeriode', 'sendDocumentCours']),
        ...mapActions('etablissements', ['getEntite']),
        ...mapActions('classes', ['getClasse']),
        ...mapActions('note', ['getNotes']),

        /**
         * format Date with moment.js
         * @param {String} date 
         */
        formatDate(date){
           // eslint-disable-next-line no-undef
           return moment(date).locale('fr').format('llll')
        },

        /**
         * Get cours with filtre option
         */
        async filtreCours() {
            this.isloading = true
            this.$charging()
            let data = {
                idEntite: this.getEtablissement(this.$route.params.libelle).id,
                debutPeriode: this.debut,
                finPeriode: this.fin,
                withCours: 'cours'
            }
            await this.getCoursPeriode(data).then(() => {
                this.isloading = false
                if (this.classe != "") {
                    this.bodyCours = this.getCours.filter((item) => item.classe === this.classe)
                }else{
                    this.bodyCours = this.getCours
                }
                this.$unCharging()
            });
        },

        /** 
         * Modal to send document on cours
         * @param {Integer} idCours 
         */
        document(idCours) {
            var elementHTML = this.$footerModalButton('fermer_5', 'sauvegarde_5', 'loading')
            // eslint-disable-next-line no-undef
            $('.modal-footer').html(elementHTML)
            // eslint-disable-next-line no-undef
            let sauvegarde = $("#sauvegarde_5");
            // eslint-disable-next-line no-undef
            let fermer = $("#fermer_5");
            // eslint-disable-next-line no-undef
            let bodyModal = $("#bodyModal");
            // eslint-disable-next-line no-undef
            let formModal = $("#formModal");
            // eslint-disable-next-line no-undef
            let declenche = $("#declenche");
            // eslint-disable-next-line no-undef
            $('#exampleModalLabel').html('Déposer un document');

            bodyModal.html('');
            formModal.attr('class', 'modal-dialog modal-lg');
            fermer.html('Annuler');
            sauvegarde.html("Sauvegarder").show();
            bodyModal.html(this.$formulaireDepotDocument());
            declenche.trigger('click');

            // eslint-disable-next-line no-undef
            let doc = $("#doc");
            // eslint-disable-next-line no-undef
            let select7 = $("#select7");
            select7.on('change', () => {
                doc.html('')
                if (select7.val() == "exo") {
                    doc.html('').append(`
                        <div class="row">
                            <div class="form-group col-md-6 col-xs-6">
                                <label for="datelimite" style="font-weight: bold;">Date limite</label>
                                <input id="datelimite" type="date" class="form-control form-control-solid" name="datelimite">
                            </div>
                            <div class="form-group col-md-6 col-xs-6">
                                <label for="file" style="font-weight: bold;">Fichier max : 2Mo (.doc, .docx, .pdf, .xls)</label>
                                <input id="file" type="file" class="form-control form-control-solid" name="file">
                            </div>
                        </div>
                        <div class="row">
                            <div class="form-group col-md-6 col-xs-6">
                                <label for="description" style="font-weight: bold;">Description</label>
                                <textarea id="description" class="form-control form-control-solid" cols="40" rows="4"></textarea>
                            </div>
                        </div>
                    `);
                } else {
                    doc.html('').append(`
                        <div class="row">
                            <div class="col-md-6 col-xs-6">
                                <label for="file" style="font-weight: bold;">Fichier max : 2Mo (.doc, .docx, .pdf, .xls)</label>
                                <input id="file" type="file" class="form-control form-control-solid" name="file">
                            </div>
                            <div class="col-md-6 col-xs-6">
                                <label for="description" style="font-weight: bold;">Description</label>
                                <textarea id="description" class="form-control form-control-solid" cols="40" rows="4"></textarea>
                            </div>
                        </div>
                    `);
                }

            });
            sauvegarde.on('click', async () => {
                // eslint-disable-next-line no-undef
                let file = $("#file");
                // eslint-disable-next-line no-undef
                let datelimite = $("#datelimite");
                // eslint-disable-next-line no-undef
                let libelle = $("#libelle");
                // eslint-disable-next-line no-undef
                let description = $("#description");
                if(file[0] == undefined || libelle.val().length == 0 || select7.val().length == 0){
                    // eslint-disable-next-line no-undef
                    Swal.fire({
                        allowOutsideClick : false,
                        title: "Oops...",
                        text: (select7.val().length == 0) ?  `Choisir le type de document`: (libelle.val().length == 0  ? `Entrer le libellé du document` : `Choisir le fichier et respecter la taille exigée 2Mo`),
                        icon: "error",
                    })
                }else{
                    await this.sendDocument(file, datelimite, libelle, description, select7, idCours, fermer)
                }
            })
        },

        /**
         * Send document function 
         * @param {File} file 
         * @param {Date} datelimite 
         * @param {String} libelle 
         * @param {String} description 
         * @param {String} select7 
         * @param {Integer} idCours 
         * @param {String} fermer 
         */
        async sendDocument(file, datelimite, libelle, description, select7, idCours, fermer) {
            const formData = new FormData();
            formData.append('file', file[0].files[0]);
            formData.append('datelimite', datelimite.val());
            formData.append('libelle', libelle.val());
            formData.append('description', description.val());
            formData.append('type', select7.val());
            formData.append('id_compte', Base64.decode(Ls.get('user.id')));
            formData.append('id_entite', this.getEtablissement(this.$route.params.libelle).id);
            formData.append('id_cours', idCours)

            await this.sendDocumentCours(formData).then((response) => {
                // eslint-disable-next-line no-undef
                Swal.fire({
                    allowOutsideClick : false,
                    title: "Connexion réussie",
                    text: `${response.data.message}`,
                    icon: "success",
                }).then(() => {
                    fermer.trigger('click')
                })
            }).catch((error) => {
                // eslint-disable-next-line no-undef
                Swal.fire({
                     allowOutsideClick : false,
                    title: "Oops...",
                    text: `${error.data.message}`,
                    icon: "error",
                })
            })

        },

        /**
         * Breadcrumb event
         */
        getBreadcrumb() {
            this.$emit('getBreadcrumb', this.objects)
        },

        /**
         * Breadcrumb event
         */
        getButtonRetour(data) {
            this.$emit('getButtonRetour', data)
        },

        /**
         * Get Programme of cours
         * @param {Integer} idCours 
         */
        programme(idCours) {
            this.$router.push({ name: 'cours-details', params: { libelle: this.$route.params.libelle, id: idCours, dateDebut: this.debut, dateFin: this.fin } })
        },

        /**
         * Change value of body
         */
         changeBody() {
            if(this.classe == "all"){
                this.bodyCours = this.getCours
            }else{
                this.bodyCours = this.getCours.filter((item) => item.classe === this.classe)
            }
        },


        activeTabs(tabactive){
            this.coursTabs = false
            this.progTabs = false

           if(tabactive == 'progTabs'){
                this.progTabs = true
            }else if(tabactive == 'coursTabs'){
                this.coursTabs = true
            }
        },

        /**
         * Get All Cours without periode
         */
        async getAllCours(){
            this.$charging()
            let data = {
                idEntite: this.getEtablissement(this.$route.params.libelle).id,
                debutPeriode: null,
                finPeriode: null,
                withCours: 'cours'
            }
            await this.getCoursPeriode(data).then(() => {
                if (this.$route.params.classeId) {
                    //this.classeId = this.$route.params.classeId
                    this.bodyCours = this.getCours.filter((item) => item.classe === this.classe)
                } else {
                    this.bodyCours = this.getCours
                }
                
                this.$unCharging()
            });
        },

        countNbreProgramme(idCours){
            let count = this.getEmploiTemps.filter((item) => item.id == idCours)
            return count.length
        },


        /**
         * Get contenu of cours
         * @param {Integer} idCours 
         */
        contenu(idCours){
            this.$router.push({ name: 'document-cours', params: { libelle: this.$route.params.libelle, id: idCours } })
        },

        /**
         * Ge tfiche de note
         * @param {Integer} idCours 
         */
        grilleNote(idCours) {
            this.$router.push({ name: 'note', params: { libelle: this.$route.params.libelle, id: idCours } })
        },

        statistique(idCours){
            this.$router.push({ name: 'cours-stat', params: { libelle: this.$route.params.libelle, coursId: idCours } })
        }
    },

    created(){
        if(!this.$route.params.classeId){
            this.getBreadcrumb()
        }else{
            let data = {
                retour : true,
                link : "cours",
                title : "Retour",
                params : {libelle : this.$route.params.libelle, classeId : this.$route.params.classeId}
            }
            this.getButtonRetour(data)
        }
    }

}
</script>