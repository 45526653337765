<template>
    <div class="row" style="margin-left: 0px; margin-right : 0px;">
        <template v-if="getMatieres.length == 0">
            <div class="col-md-12" style="">
                <div class="main-card mb-3 card">
                    <div class="card-body">
                        <div class="text-center">
                        <h6 class="font-weight-bold text-danger">
                            <i class="fa fa-info-circle"></i>
                            Aucune matière</h6>  
                            <p class="text-muted">Vous n'enseignez aucune matière dans cet établissement</p>
                        </div>
                    </div>
                </div>
            </div>
        </template>
        <template v-else>
            <template v-for="(item, key) in getMatieres" :key="key">
                <div class="col-6">
                    <card-matiere :libelleMatiere="item.libelle" :code="item.code" :idMatiere="item.id"></card-matiere>
                </div>
            </template>
        </template>
    </div>
</template>

<style scoped>
    .v-enter-active {
        transition: opacity 0.5s ease;
    }
    /* .v-leave-active {
        
    } */

    .v-enter-from, .v-leave-to {
        opacity: 0;
    }
</style>

<script>
import CardMatiere from '@/components/widgets/cards/CardMatiere.vue';
import { mapGetters, mapActions } from 'vuex';
export default {
    emits: ['getBreadcrumb'],
    components: {
        CardMatiere
    },

    data() {
        return {
            isloading: false,
            bodyMatieres: [],
            objects : {
                breadcrumb: [
                    {
                        libelle: 'Etablissement',
                        name: "index-etablissement",
                        params: { libelle: this.$route.params.libelle }
                    },
                    {
                        libelle : this.$route.params.libelle,
                        name : "index-entite",
                        params : {libelle : this.$route.params.libelle}
                    },
                    {
                        libelle:  'Gestion des cours / Matières',
                        name: "cours"
                    },
                ],
                titlePage : 'Matières ('+ this.$route.params.libelle+')',
                icon : 'fa fa-book-reader'
            }
        }
    },

    async mounted() {
        this.$nextTick(async () => {
            this.bodyMatieres = this.getMatieres;
        })
    },

    computed: {
        ...mapGetters('matieres', ['getMatieres']),
        ...mapGetters('etablissements', ['getEtablissement']),
        ...mapGetters(['getDataMenu']),
    },

    methods: {
        ...mapActions('etablissements', ['getEntite']),

        /**
         * Breadcrumb event
         */
        getBreadcrumb() {
            this.$emit('getBreadcrumb', this.objects)
        },

    },

    created(){
        this.getBreadcrumb()
    }

}
</script>