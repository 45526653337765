<template>
    <template v-if="getClasses.length != 0">
        <div class="row" style="margin-left: 0px; margin-right : -0px;">
            <template  v-for="(item, key) in body" :key="key">
                <div class="col-md-4" v-if="key < 6">
                    <card-classe
                        :departement="item.departement.libelle" 
                        :niveau="item.niveau.libelle" 
                        :effectif="item.effectif"
                        :count="item.countCours"
                        :code="item.code"
                        :cycle="item.niveau.cycle"
                        @liste="detailsClasse(item.id)"
                        @cours="getClasseCours(item.id)"
                        :classe="item.libelle">
                    </card-classe>
                </div>
            </template>
        </div>

        <pagination-tool @change-page="chargeSixValue" @init-value="chargeSixFirstValue" :get-number-page="getNumberPage"></pagination-tool>
    </template>
    <template v-else>
        <div class="col-md-12">
            <div class="main-card mb-3 card">
                <div class="card-body">
                    <div class="text-center">
                    <h6 class="font-weight-bold text-danger">
                        <i class="fa fa-info-circle"></i>
                       Vous n'intervenez dans acune classe</h6>  
                    </div>
                </div>
            </div>
        </div>
    </template>
</template>

<script>
import CardClasse from '@/components/widgets/cards/CardClasse.vue';
import PaginationTool from '@/components/tools/PaginationTool.vue';
import {mapActions, mapGetters} from 'vuex'
export default {
    emits: ['getBreadcrumb'],
    components : {
        CardClasse,
        PaginationTool
    },

    data() {
        return {
            listeAbsent : '',
            tableTitle : 'Vos classes',
            body : [],
            objects : {
                breadcrumb : [
                    {
                        libelle : 'Etablissement',
                        name : "index-etablissement",
                        params : {libelle : this.$route.params.libelle}
                    },
                    {
                        libelle : this.$route.params.libelle,
                        name : "index-entite",
                        params : {libelle : this.$route.params.libelle}
                    },
                    {
                        libelle :  'Gestion des classes',
                        name : "classe"
                    },
                ],
                titlePage : "Gestion des classes ("+this.$route.params.libelle+")",
                icon : 'fa fa-users'
            }
            
        }
    },

    async mounted() {
        this.$charging()
        let data = {
            idEntite : this.getEtablissement(this.$route.params.libelle).id,
        }
        await this.getClasse(data).then(() => {
            this.chargeSixFirstValue()

            this.$unCharging()
        }).catch((error) => {
            // eslint-disable-next-line no-undef
            Swal.fire({
                allowOutsideClick : false,
                title: "Oops...",
                text: `${error.data.message}`,
                icon: "error",
            })
            this.$unCharging()
        });
    },

    computed : {
        ...mapGetters('classes', ['getClasses']),
        ...mapGetters('etablissements', ['getEtablissement']),
        ...mapGetters('etudiants', ['getEtudiants']),
        ...mapGetters(['getDataMenu']),

        getNumberPage(){
            let nbr = this.getClasses.length / 6
            if(Number.isInteger(nbr)){
                return nbr;
            }else{
                return Math.floor(this.getClasses.length /6 ) + 1;
            }
        }

    },

    methods : {
        ...mapActions('classes', ['getClasse']),
        ...mapActions('etablissements', ['getEntite']),
        ...mapActions('etudiants', ['getEtudiantClasse']),

        getClasseCours(idClasse){
            this.$router.push({name : 'classe-cours', params : {classeId : idClasse, libelle : this.$route.params.libelle}})
        },
        
        chargeSixFirstValue(){
            this.body = this.getClasses.filter((item, id) => id < 6)
        },

        chargeSixValue(nbrElement){
            this.body = this.getClasses.filter((item, id) => id >= nbrElement)
        },

        detailsClasse(idClasse) {
            let classe = this.getClasses.filter((item) => item.id == idClasse)
            // eslint-disable-next-line no-undef
            let sauvegarde  = $("#sauvegarde");
            // eslint-disable-next-line no-undef
            let fermer = $("#fermer");
            // eslint-disable-next-line no-undef
            let bodyModal = $("#bodyModal");
            // eslint-disable-next-line no-undef
            let formModal = $("#formModal");
            // eslint-disable-next-line no-undef
            let declenche = $("#declenche");            
            bodyModal.html('');
            formModal.attr('class', 'modal-dialog modal-lg'); 
            fermer.html('Annuler');
            sauvegarde.html("").hide();
            this.openModalEtudiant(bodyModal, declenche, classe)
            
        },

        openModalEtudiant(bodyModal, declenche, classe){
            this.$charging()
            let cle = [
                {
                    libelle: "matricule",
                    classe: '',
                    type: 'text',
                    count : 1
                },
                {
                    libelle: "nom",
                    classe: '',
                    type: 'text',
                    count : 1
                },
                {
                    libelle: "prenom",
                    classe: '',
                    type: 'text',
                    count : 1
                },
            ]

            let header = [
                {
                    classe : '',
                    libelle : 'Matricule',
                },
                {
                    classe : '',
                    libelle : 'Nom',
                },
                {
                    classe : '',
                    libelle : 'Prénom',
                },
            ]
            let data = {
                idClasse : classe[0].id,
                idEntite : this.getEtablissement(this.$route.params.libelle).id,
                idEnseigner : null,
            }
            this.getEtudiantClasse(data).then((response) => {
                // eslint-disable-next-line no-undef
                $('#exampleModalLabel').html('Liste des étudiants de la classe '+ classe[0].libelle);
                bodyModal.html(this.$tabTemplateForModal(header, response.data.data.etudiants, cle, 'liste'));
                // eslint-disable-next-line no-undef
                $('#tab2').DataTable()
                this.$unCharging()
                declenche.trigger('click');
            }).catch((error) => {
                // eslint-disable-next-line no-undef
                Swal.fire({
                    allowOutsideClick : false,
                    title: "Oops...",
                    text: `${error.data.message}`,
                    icon: "error",
                })
                this.$unCharging()
            })
        },

        getBreadcrumb(){
            this.$emit('getBreadcrumb', this.objects)
        },

    },

    created(){
       this.getBreadcrumb()
    },
}
</script>