<template>
    <div class="app-container app-theme-white body-tabs-shadow fixed-header fixed-sidebar">
        <div class="app-header header-shadow">
            <Header :user="getUsers" :annee="getAnneeAcademique" :showBtn="showBtn"></Header>
        </div>
        
        <div class="app-main">
            <div class="app-sidebar sidebar-shadow">
                <aside-menu :menus="menus"></aside-menu>
            </div>
            <div class="app-main__outer">
                <div class="app-main__inner">
                    <div class="tabs-animation element-block-example">
                        <Transition>
                            <router-view @getMenu="loadMenu" :user="getUsers" :key="$route.fullPath"></router-view>
                        </Transition>
                    </div>
                </div>
                <div class="app-wrapper-footer">
                   <Footer></Footer>
                </div>
            </div>
        </div>
    </div>
    <div class="app-drawer-overlay d-none animated fadeIn"></div>
    <input type="hidden" name="" id="declenche" data-toggle="modal" data-target="#exampleModal">
    <!-- <input type="hidden" name="" id="declenche_2" data-toggle="modal" data-target="#exampleModal_2"> -->
    <div class="modal fade" id="exampleModal" tabindex="-1" data-backdrop="static" data-keyboard="false" role="dialog" aria-labelledby="exampleModalLabel"
        aria-hidden="true">
        <div class="" id="formModal" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLabel"></h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <div id="bodyModal"></div>
                </div>
                <div class="modal-footer">
                    <!-- <button type="button" class="btn btn-danger" id="fermer" data-dismiss="modal"></button>
                    <button type="button" class="btn btn-primary" id="sauvegarde"></button>
                    <button  class="btn btn-primary" id="loading" disabled style="display: none; width: 200px;">
                        <img src="/assets/images/loading_2.gif" alt="" style="width: 10%;">
                        Patientez-svp...</button> -->
                </div>
            </div>
        </div>
    </div>
    <!-- <div class="modal fade" id="exampleModal_2" tabindex="-1" data-backdrop="static" data-keyboard="false" role="dialog" aria-labelledby="exampleModalLabel"
        aria-hidden="true">
        <div class="" id="formModal_2" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLabel_2"></h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <div id="bodyModal_2"></div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-danger" id="fermer_2" data-dismiss="modal"></button>
                    <button type="button" class="btn btn-primary" id="sauvegarde_2"></button>
                    <button  class="btn btn-primary" id="loading" disabled style="display: none; width: 200px;">
                        <img src="/assets/images/loading_2.gif" alt="" style="width: 10%;">
                        Patientez-svp...</button>
                </div>
            </div>
        </div>
    </div> -->
</template>

<style scoped>
    .v-enter-active {
        transition: opacity 0.5s ease;
    }
    .v-enter-from, .v-leave-to {
        opacity: 0;
    }
</style>

<script>
import Header from '@/components/layouts/HeaderView.vue'
import Footer from '@/components/layouts/FooterView.vue'
import AsideMenu from '@/components/includes/AsideMenuView.vue'
import {mapGetters} from 'vuex'

export default {
    components : {
        Footer,
        Header,
        AsideMenu,
    },

    data : function(){
        return {
            menus : [],
            showBtn : window.showBtn
        }
    },
    updated(){
        this.showBtn = window.showBtn
    },
    
    async mounted(){

        if(/Mobi|Android/i.test(navigator.userAgent)){
            // eslint-disable-next-line no-undef
            $('.app-container').addClass('closed-sidebar-mobile closed-sidebar')
        }
        //alert(screen.orientation.type)
        //alert(/Mobi|Android/i.test(navigator.userAgent))
    },

    computed : {
        ...mapGetters('users', ['getUsers']),
        ...mapGetters(['getAnneeAcademique'])

    },

    methods : {
        loadMenu(menus){
            this.menus = menus
        },
    }
}
</script>