<template>
    <div class="loadTable">
        <table-simple 
            :validateButton="true" 
            :isloading="isloading" 
            :periode="true" 
            :inputClasse="false" 
            filtreActions="initialise"
            :actionButton="getReturnBack" 
            @initialise="filtre" 
            :action="action" 
            :buttonShow="false"
            :tableTitle="tableTitle" 
            :cle="cle" 
            :header="header" 
            :body="tbody"
            :datedebut="debut"
            :datefin="fin"
            noData="Vous n'avez aucun cours pour aujourd'hui" 
            ></table-simple>
    </div>
</template>

<script>
import TableSimple from '@/components/tables/TableSimple.vue';
import { mapGetters, mapActions } from 'vuex';
import Ls from '@/services/ls'
import { Base64 } from 'js-base64'
export default {
    emits: ['getBreadcrumb', 'getButtonRetour'],
    components: {
        TableSimple,
    }, 

    data() {
        return {
            idEnseigner : '',
            listeAbsent : [],
            tableTitle:'',
            isloading: false,
            // eslint-disable-next-line no-undef
            debut: moment(this.$route.params.dateDebut).format("YYYY-MM-DD"),
            // eslint-disable-next-line no-undef
            fin: moment(this.$route.params.dateFin).format("YYYY-MM-DD"),
            classe: "",
            body: [],
            tbody: [],
            action: [
                {
                    class: 'fa fa-list-alt',
                    function: this.detailsClasse({ action: 'presence', instance: this }),
                    title: 'Liste de présence',
                    element: ["siExecute", "siAnnuler"],
                    condition: [false, false],
                    type: 'button',
                    buttonText : 'Liste de présence',
                    buttonclasse : 'btn btn-warning btn-sm',
                },
                {
                    class: 'fa fa-edit',
                    function: this.detailsClasse({ action: 'cahier', instance: this }),
                    title: 'Cahier de texte',
                    element: ["siExecute", "siAnnuler"],
                    condition: "",
                    type: 'button',
                    buttonText : 'Cahier de texte',
                    buttonclasse : 'btn btn-danger btn-sm',
                },
            ],
            cle: [
                {
                    libelle: "dateCours",
                    classe: 'text-center',
                    type: 'date',
                    count: 1
                },
                {
                    libelle: "classe",
                    classe: 'text-center',
                    type: 'text',
                    count: 1
                },
                {
                    libelle: "libelleMatiere",
                    classe: 'text-center',
                    type: 'text',
                    count: 1
                },
                {
                    libelle: 'horaire',
                    classe: 'text-center',
                    type: 'text',
                    count: 1
                },
                {
                    libelle: "SalleName",
                    classe: 'text-center',
                    type: 'text',
                    count: 1
                },
                {
                    libelle: ["siExecute", "siAnnuler"],
                    value: ['Exécuter', 'Annuler', 'Non exécuter'],
                    type: 'bool',
                    count: 2
                },
            ],
            header: [
                {
                    classe: '',
                    libelle: 'Date du cours',
                },
                {
                    classe: '',
                    libelle: 'Classes',
                },
                {
                    classe: '',
                    libelle: 'Matières',
                },
                {
                    classe: '',
                    libelle: 'Horaires',
                },
                {
                    classe: '',
                    libelle: 'Salles',
                },
                {
                    classe: '',
                    libelle: 'Statut',
                },
            ],
            objects : {
                breadcrumb: [
                    {
                        libelle: 'Etablissement',
                        name: "index-etablissement",
                        params : {libelle : this.$route.params.libelle}
                    },
                    {
                        libelle : this.$route.params.libelle,
                        name : "index-entite",
                        params : {libelle : this.$route.params.libelle}
                    },
                    {
                        libelle: 'Gestion des cours',
                        name: "cours",
                        params: { libelle: this.$route.params.libelle }
                    },
                    {
                        libelle: 'Programmation du cours',
                        name: "cours"
                    },
                ],
                titlePage : 'Programmation des cours ('+ this.$route.params.libelle+')',
                icon : 'fa fa-clock'
            }
        }
    },

    async mounted() {
        this.$charging()
        // let coursData = this.getCours.filter(cours => cours.id == this.$route.params.id)
        let data = {
            idEntite: this.getEtablissement(this.$route.params.libelle).id,
            debutPeriode: this.debut,
            finPeriode: this.fin,
            withCours: 'programme'
        }
        await this.getCoursPeriode(data).then(() => {
            if(this.$route.params.id){
                this.tbody = this.getEmploiTemps.filter(cours => cours.id == this.$route.params.id)
                this.$unCharging()
            }else{
                this.tbody = this.getEmploiTemps
                this.$unCharging()
            }
        })
        this.tableTitle = (this.getEmploiTemps.length != 0) ? 'Programme du cours de '+this.getEmploiTemps[0].libelleMatiere : "Programme du cours"
        //this.listeAbsent.push( this.getEtudiants.filter((item) => item.presence == false).map((element) => [{idInscription : element.idInscription, motif : element.motif}]))
        this.listeAbsent = this.getEtudiants.filter((item) => item.presence == false).map((element) => element.idInscription)

        this.changePresenceEtudiant()
        this.sendListControle()

        // eslint-disable-next-line no-undef
        // $(document).on('change', '.presence', (e) => {
        //     e.stopPropagation()
        //     let key = e.target.getAttribute('data-key')
        //     if(!e.target.checked){
        //         document.getElementById('motif_'+key).disabled = false;
        //     }else{
        //         document.getElementById('motif_'+key).disabled = true; 
        //     }
          
        // })

        // // eslint-disable-next-line no-undef
        // $(document).on('keyup', '.motif', (e) => {
        //     e.stopPropagation()
        //     let key = e.target.getAttribute('data-key')
        //     let etu = this.getEtudiants.filter((item, index) => index == key)
        //     this.listeAbsent = this.getEtudiants.filter((item) => item.presence == false).map((element) => element.idInscription)

        //     //this.listeAbsent.push( this.getEtudiants.filter((item) => item.presence == false).map((element) => [{idInscription : element.idInscription, motif : element.motif}]))
        //     //this.listeAbsent.filter((item, index) => index == key)[0].motif = e.target.value
        //     etu[0].motif = e.target.value       
        // })
    },

    computed: {
        ...mapGetters('classes', ['getClasses']),
        ...mapGetters('matieres', ['getMatieres']),
        ...mapGetters('cours', ['getCours', 'getEmploiTemps']),
        ...mapGetters('etablissements', ['getEtablissement']),
        ...mapGetters('etudiants', ['getEtudiants']),
        ...mapGetters(['getDataMenu'])
    },

    methods: {
        ...mapActions('classes', ['getClasse']),
        ...mapActions('cours', ['getCoursPeriode', 'sendDocumentCours']),
        ...mapActions('etablissements', ['getEntite']),
        ...mapActions('etudiants', ['getEtudiantClasse', 'listePresenceEtudiant']),

        getInfosOfClasse(idCours){
            this.classe = this.getClasses.filter((item) => item.libelle == this.getEmploiTemps.filter((element) => element.id == idCours)[0].classe)[0]
        },

        
        async filtreCours() {
            this.isloading = true
            let data = {
                idEntite: this.getEtablissement(this.$route.params.libelle).id,
                debutPeriode: this.debut,
                finPeriode: this.fin,
                withCours: 'programme'
            }
            await this.getCoursPeriode(data).then(() => {
                this.isloading = false
                this.tbody = this.getEmploiTemps.filter((item) => item.id == this.$route.params.id)
            });
        },

        // document() {
        //     // eslint-disable-next-line no-undef
        //     let sauvegarde = $("#sauvegarde");
        //     // eslint-disable-next-line no-undef
        //     let fermer = $("#fermer");
        //     // eslint-disable-next-line no-undef
        //     let bodyModal = $("#bodyModal");
        //     // eslint-disable-next-line no-undef
        //     let formModal = $("#formModal");
        //     // eslint-disable-next-line no-undef
        //     let declenche = $("#declenche");
        //     // eslint-disable-next-line no-undef
        //     $('#exampleModalLabel').html('Déposer un document');

        //     bodyModal.html('');
        //     formModal.attr('class', 'modal-dialog modal-lg');
        //     fermer.html('Annuler');
        //     sauvegarde.html("Sauvegarder").show();
        //     bodyModal.html(this.$formulaireDepotDocument());
        //     declenche.trigger('click');

        //     // eslint-disable-next-line no-undef
        //     let doc = $("#doc");
        //     // eslint-disable-next-line no-undef
        //     let select7 = $("#select7");
        //     select7.on('change', () => {
        //         doc.html('')
        //         if (select7.val() == "exo") {
        //             doc.html('').append(`
        //                 <div class="row">
        //                     <div class="form-group col-md-6 col-xs-6">
        //                         <label for="datelimite" style="font-weight: bold;">Date limite</label>
        //                         <input id="datelimite" type="date" class="form-control form-control-solid" name="datelimite">
        //                     </div>
        //                     <div class="form-group col-md-6 col-xs-6">
        //                         <label for="file" style="font-weight: bold;">Fichiermax : 2Mo (.pdf, .doc, .docx, .xls, .txt)</label>
        //                         <input id="file" type="file" class="form-control form-control-solid" name="file">
        //                     </div>
        //                 </div>
        //                 <div class="row">
        //                     <div class="form-group col-md-6 col-xs-6">
        //                         <label for="description" style="font-weight: bold;">Description</label>
        //                         <textarea id="description" class="form-control form-control-solid" cols="40" rows="4"></textarea>
        //                     </div>
        //                 </div>
        //             `);
        //         } else {
        //             doc.html('').append(`
        //                 <div class="row">
        //                     <div class="col-md-6 col-xs-6">
        //                         <label for="file" style="font-weight: bold;">Fichiermax : 2Mo (.pdf, .doc, .docx, .xls, .txt)</label>
        //                         <input id="file" type="file" class="form-control form-control-solid" name="file">
        //                     </div>
        //                     <div class="col-md-6 col-xs-6">
        //                         <label for="description" style="font-weight: bold;">Description</label>
        //                         <textarea id="description" class="form-control form-control-solid" cols="40" rows="4"></textarea>
        //                     </div>
        //                 </div>
        //             `);
        //         }

        //     });
        //     sauvegarde.on('click', async () => {
        //         // eslint-disable-next-line no-undef
        //         let file = $("#file");
        //         // eslint-disable-next-line no-undef
        //         let datelimite = $("#datelimite");
        //         // eslint-disable-next-line no-undef
        //         let libelle = $("#libelle");
        //         // eslint-disable-next-line no-undef
        //         let description = $("#description");
        //         await this.sendDocument(file, datelimite, libelle, description, select7, fermer)
        //     })
        // },

        // async sendDocument(file, datelimite, libelle, description, select7, fermer) {
        //     const formData = new FormData();
        //     formData.append('file', file[0].files[0]);
        //     formData.append('datelimite', datelimite.val());
        //     formData.append('libelle', libelle.val());
        //     formData.append('description', description.val());
        //     formData.append('type', select7.val());
        //     formData.append('id_compte', Base64.decode(Ls.get('user.id')));
        //     formData.append('id_entite', this.getEtablissement(this.$route.params.libelle).id);
        //     formData.append('id_cours', this.$route.params.id)

        //     await this.sendDocumentCours(formData).then((response) => {
        //         // eslint-disable-next-line no-undef
        //         Swal.fire({
        //             allowOutsideClick : false,
        //             title: "Connexion réussie",
        //             text: `${response.data.message}`,
        //             icon: "success",
        //         }).then(() => {
        //             fermer.trigger('click')
        //         })
        //     }).catch((error) => {
        //         // eslint-disable-next-line no-undef
        //         Swal.fire({
        //             allowOutsideClick : false,
        //             title: "Oops...",
        //             text: `${error.data.message}`,
        //             icon: "error",
        //         }).then(() => {
        //         })
        //     })

        // },

        detailsClasse: (object) => (e) => {
            object.instance.idEnseigner = e.target.getAttribute('data-enseigner')
            var elementHTML =  object.instance.$footerModalButton('fermer_4', 'sauvegarde_4', 'loading')
            // eslint-disable-next-line no-undef
            $('.modal-footer').html(elementHTML)
            // eslint-disable-next-line no-undef
            let sauvegarde = $("#sauvegarde_4");
            // eslint-disable-next-line no-undef
            let fermer = $("#fermer_4");
            // eslint-disable-next-line no-undef
            let bodyModal = $("#bodyModal");
            // eslint-disable-next-line no-undef
            let formModal = $("#formModal");
            // eslint-disable-next-line no-undef
            let declenche = $("#declenche");
            bodyModal.html('');
            object.instance.getInfosOfClasse(object.instance.$route.params.id)
            formModal.attr('class', 'modal-dialog modal-lg w-900');
            fermer.html('Annuler');
            if (object.action == "detail") {
                sauvegarde.html("").hide();
                object.instance.openModalDetail(bodyModal, declenche)
            }else if(object.action == "cahier"){
                sauvegarde.html("").hide();
                // eslint-disable-next-line no-undef
                $('#exampleModalLabel').html('Cahier de texte');
                object.instance.body = object.instance.getEmploiTemps.filter((item) => item.idEnseigner == object.instance.idEnseigner)
                bodyModal.html(`<div class="d-flex">
                                    <h6 class="font-weight-bold">Titre du cours : </h6>
                                    <p style="margin-left : 2%">${object.instance.body[0]['libelleMatiere']}</p>
                                </div>
                                <div class="d-flex">
                                    <h6 class="font-weight-bold">Horaires du cours : </h6>
                                    <p style="margin-left : 2%">${object.instance.body[0]['horaire']}</p>
                                </div>
                                <div class="d-flex">
                                    <h6 class="font-weight-bold">Salle de déroulement du cours : </h6>
                                    <p style="margin-left : 2%">${object.instance.body[0]['SalleName']}</p>
                                </div>
                                <div>
                                    <h6 class="font-weight-bold">Contenu : </h6>
                                </div>
                            `);
                bodyModal.append(`<p style="margin-left : 3%">`+object.instance.body[0]['observations']+`</p>`);
                declenche.trigger('click');
            } else {
                sauvegarde.html("Valider").show();
                object.instance.openModalEtudiant(bodyModal, declenche)
            }

        },

        openModalEtudiant(bodyModal, declenche) {
            this.$charging()
            let cle = [
                {
                    libelle: "matricule",
                    classe: 'text-center',
                    type: 'text',
                    count: 1
                },
                {
                    libelle: "nom",
                    classe: 'text-center',
                    type: 'text',
                    count: 1
                },
                {
                    libelle: "prenom",
                    classe: 'text-center',
                    type: 'text',
                    count: 1
                },
                // {
                //     libelle: "motif",
                //     classe: 'text-center',
                //     type: 'text',
                //     count: 1
                // },
            ]

            let header = [
                {
                    classe: '',
                    libelle: 'Matricule',
                },
                {
                    classe: '',
                    libelle: 'Nom',
                },
                {
                    classe: '',
                    libelle: 'Prénom',
                },
                // {
                //     classe: '',
                //     libelle: 'Motif',
                // },
            ]
            let data = {
                idClasse: this.classe.id,
                idEntite: this.getEtablissement(this.$route.params.libelle).id,
                idEnseigner : this.idEnseigner,
            }
            this.getEtudiantClasse(data).then((response) => {
                // eslint-disable-next-line no-undef
                $('#exampleModalLabel').html('Liste de présence de la classe ' + this.classe.libelle);
                bodyModal.html(this.$tabTemplateForModal(header, response.data.data.etudiants, cle, 'presence'));
                // eslint-disable-next-line no-undef
                $('#tab2').DataTable()
                declenche.trigger('click');
                this.$unCharging()
            }).catch(() => {
                // eslint-disable-next-line no-undef
                Swal.fire({
                    allowOutsideClick : false,
                    title: "Oops...",
                    text: `Une erreur s'est produite`,
                    icon: "error",
                })
                this.$unCharging()
            })
        },

        getReturnBack(){
            this.$router.push({name : 'cours', params : {libelle: this.$route.params.libelle}})
        },

        getBreadcrumb() {
            this.$emit('getBreadcrumb', this.objects)
        },

        async filtre(data) {
            this.debut = data.debut
            this.fin = data.fin
            await this.filtreCours()
        },

        /**
         * Breadcrumb event
         */
         getButtonRetour(data) {
            this.$emit('getButtonRetour', data)
        },

        changePresenceEtudiant(){
            // eslint-disable-next-line no-undef
            $(document).on('change', '.presence', (event) => {
                let idEtudiant = event.target.value
                this.getEtudiants.map((item) => {
                    if(item.id == idEtudiant){
                        item.presence = !item.presence
                    }
                })
                // this.listeAbsent.push({
                //     idInscription : this.getEtudiants.filter((item) => item.presence == false).map((element) => element.idInscription),
                //     motif : this.getEtudiants.filter((item) => item.presence == false).map((element) => element.motif)
                // })           
                this.listeAbsent = this.getEtudiants.filter((item) => item.presence == false).map((element) => element.idInscription) 
            })
        },

        sendListControle(){
            let idCompte = Base64.decode(Ls.get('user.id'))
            // eslint-disable-next-line no-undef
            $('#sauvegarde_4').on('click', () => {
                this.$charging()
                let data = {
                    listAbsent : this.listeAbsent,
                    id_enseigner : this.idEnseigner,
                    id_compte : idCompte,
                    id_entite : this.getEtablissement(this.$route.params.libelle).id,
                }
                // eslint-disable-next-line no-undef
                $('#fermer_4').trigger('click');
                this.listePresenceEtudiant(data).then((response) => {
                    // eslint-disable-next-line no-undef
                    Swal.fire({
                        allowOutsideClick : false,
                        title: "Success",
                        text: `${response.data.message}`,
                        icon: "success",
                    })
                    this.$unCharging()
                }).catch(() => {
                    // eslint-disable-next-line no-undef
                    Swal.fire({
                        allowOutsideClick : false,
                        title: "Oops...",
                        text: `Une erreur s'est produite`,
                        icon: "error",
                    })
                    this.$unCharging()
                })
            })
        }
    },

    created() {
        let data = {
            retour : true,
            link : "cours",
            title : "Retour",
            params : {libelle : this.$route.params.libelle, classeId : this.$route.params.classeId}
        }
        this.getButtonRetour(data)
        this.getBreadcrumb()
    },

}
</script>