<template>
    <div class="row">
        <div class="col-md-4">
            <div class="main-card card">
                <div class="card-body" style="text-align: center;">
                    <img width="150" class="rounded-circle mb-3" src="/assets/images/avatars/profil.jpg"
                    alt="">
                    <h6>{{ getUsers.matricule }}</h6>
                    <h5 class="fs-3"> <strong>{{ getUsers.nom+" "+getUsers.prenom }}</strong></h5>
                    <p>{{ getUsers.email }}</p>
                    <p>{{ "Tel : "+getUsers.telephone }}</p>
                </div>
            </div>
        </div>
        <div class="col-md-8">
            <div class="main-card card">
                <div class="card-header" style="justify-content: space-between;">
                    <div>
                        Profil de {{ getUsers.nom+" "+getUsers.prenom }}
                    </div>
                    <div>
                        <button @click.prevent="userlink('user-profil')" class="btn-shadow btn btn-primary">Mon profile</button>
                    </div>
                </div>
                <div class="card-body">
                    <form action="">
                        <div class="row">
                            <div class="form-group col-md-12">
                                <label style="font-weight: bold;">Ancien mot de passe</label>
                                <input id="old_pass" v-model="old_pass" type="password" class="form-control form-control-solid" placeholder="Ancien mot de passe">
                            </div>
                        </div>
                        <div class="row">
                            <div class="form-group col-md-12">
                                <label style="font-weight: bold;">Nouveau mot de passe</label>
                                <input id="new_pass" v-model="new_pass" type="password" class="form-control form-control-solid" placeholder="Nouveau mot de passe">
                            </div>
                        </div>
                        <div class="row">
                            <div class="form-group col-md-12">
                                <label style="font-weight: bold;">Confirmer le mot de passe</label>
                                <input id="confirm_pass" v-model="confirm_pass" type="password" class="form-control form-control-solid" placeholder="Confirmer le mot de passe">
                            </div>
                        </div>
                        <div class="text-center">                                       
                            <button @click.prevent="actionButton" v-if="!isloading" class="mb-2 btn-shadow btn btn-success">Modifier le mot de passe</button>
                            <button class="mb-2 btn-shadow btn btn-primary" v-else disabled style="width: 200px;">
                                <img src="/assets/images/loading_2.gif" alt="" style="width: 10%;">
                                Patientez-svp...</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
export default {
    emits: ['getBreadcrumb'],
    data(){
        return {
            old_pass : '',
            isloading : false,
            new_pass : '',
            confirm_pass : '',
            objects : {
                breadcrumb: [
                    {
                        libelle: 'Utilisateur',
                        name: "user-profil",
                        params : {libelle : this.$route.params.libelle}
                    },
                    {
                        libelle: "Profil de l'utilisateur",
                        name: "user-profil",
                        params : {libelle : this.$route.params.libelle}
                    },
                    {
                        libelle: "Modification du mot de passe",
                        name: "user-password"
                    },
                ],
                titlePage : 'Modifier le mot de passe',
                icon : 'fa fa-key'
            },
        }
    },

    computed : {
        ...mapGetters('users', ['getUsers']),

    },

    methods: {
        ...mapActions('auth', ['modifPass', 'logout']),

        getBreadcrumb() {
            this.$emit('getBreadcrumb', this.objects)
        },

        userlink(name){
            let params = this.$route.params.libelle
            this.$router.push({name : name, params : {libelle : params}})
        },

        async actionButton(){
            this.isloading = true
            // eslint-disable-next-line no-undef
            $("#old_pass").attr('style', 'border-color : ');
            // eslint-disable-next-line no-undef
            $("#new_pass").attr('style', 'border-color : ');
            // eslint-disable-next-line no-undef
            $("#confirm_pass").attr('style', 'border-color : ');
            if(this.new_pass.length == 0 || this.confirm_pass.length == 0 || this.new_pass!= this.confirm_pass){
                 this.isloading = false
                // eslint-disable-next-line no-undef
                 Swal.fire({
                    allowOutsideClick : false,
                    title: "Oops...",
                    text: "Veuillez bien remplir chaque champs" ,
                    icon: "error",
                }).then(() => {
                    // eslint-disable-next-line no-undef
                    $("#old_pass").attr('style', 'border-color : red');
                    // eslint-disable-next-line no-undef
                    $("#new_pass").attr('style', 'border-color : red');
                    // eslint-disable-next-line no-undef
                    $("#confirm_pass").attr('style', 'border-color : red');
                })
            }else{
                let data = {
                    'old_password' : this.old_pass,
                    'new_password' : this.new_pass,
                }
                await this.modifPass(data).then((response) => {
                    this.isloading = false
                    // eslint-disable-next-line no-undef
                    Swal.fire({
                        allowOutsideClick : false,
                        title: "Succèss",
                        text: `${response.data.message}`+" Vous serez redirigé vers la page de connexion",
                        icon: "success",
                    }).then(async () => {
                        await this.logout().then(() => {
                            this.$router.push({ name: 'index-connexion' });
                        })
                    })
                }).catch((error) => {
                    this.isloading = false
                    // eslint-disable-next-line no-undef
                    Swal.fire({
                        allowOutsideClick : false,
                        title: "Oops...",
                        text: `${error.data.message}`,
                        icon: "error",
                    })
                })
            }

        }
    },

    created() {
        this.getBreadcrumb()
    },
}
</script>