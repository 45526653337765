<template>
    <div>
        <h6 class="font-weight-bold text-primary"> {{ index+". "+body.cours.matiereUe.matiere.libelle }}</h6>
        <div style="font-size: 15px; margin-left : 5px">
            <div class="mb-2">
                <span class="font-weight-bold">
                    Type de document :
                </span>
                <span class="badge badge-success">
                    {{ body.type }}
                </span> 
            </div>
            <div class="mb-2">
                <span class="font-weight-bold">
                    Date de dépôt :
                </span>
                {{ momentConert(body.datedepot) }}
            </div>
            <div class="row">
                <div class="col-md-8">
                    <span class="font-weight-bold">
                        Document : 
                    </span>
                    <i :class="getIconExtension(body.nomDoc)" style="margin-right : 2px"></i>
                    {{ formatDocName(body.nomDoc, body.libelle) }}
                </div>
                <div @click="displayFil(body.document, body.nomDoc)" class="col-md-3 btn btn-icon btn-primary" style="margin-top: -3px;">
                    <i class="fa fa-eye"></i> Afficher
                </div>
            </div>
        </div>
    </div><hr>
</template>

<script>
export default {
    props : {
        body : {type : Object},
        index : {type : String},
    },

    methods : {
        momentConert(date){
            return this.$moment(date)
        },

        formatDocName(name, libelle){
            let tab = name.split('_')
            let refactor = libelle+"."+tab[tab.length-1].split(".")[1]

            return refactor
        },

        getIconExtension(name){
            let tab = name.split('_')
            let extension = tab[tab.length-1].split(".")[1]
            let icon = ""
            if (extension == "pdf" || extension == "doc" || extension == "docx") {
                icon = "fa fa-file-pdf text-danger"
            }else if(extension == "jpg"){
                icon = "fa fa-file-image text-success"
            }

            return icon
        },

        // eslint-disable-next-line no-unused-vars
        displayFil(linkDoc, name){
            //let tab = name.split('_')
            //let extension = tab[tab.length-1].split(".")[1]
            window.open(linkDoc, '_blank');
        }
    },
    
}
</script>