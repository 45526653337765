<template>
    <div class="loadTable">
        <!-- <table-simple 
            :isloading="isloading" 
            buttonOptionSup="Retour à la programmation"
            :actionButton="getReturnBack" 
            :action="action" 
            :buttonShow="true"
            :tableTitle="tableTitle" 
            :cle="cle" 
            :header="header" 
            :body="tbody"
            :presence="true"
            noData="En attente de chargement" 
            ></table-simple> -->
    </div>
</template>

<script>
// import TableSimple from '@/components/tables/TableSimple.vue';
import { mapGetters, mapActions } from 'vuex';
import Ls from '@/services/ls'
import { Base64 } from 'js-base64'
export default {
    emits: ['getBreadcrumb'],
    // components: {
    //   TableSimple,
    // }, 

    data() {
        return {
            idEnseigner : '',
            listeAbsent : '',
            tableTitle:'',
            isloading: false,
            // eslint-disable-next-line no-undef
            debut: moment(this.$route.params.dateDebut).format("YYYY-MM-DD"),
            // eslint-disable-next-line no-undef
            fin: moment(this.$route.params.dateFin).format("YYYY-MM-DD"),
            classe: "",
            body: [],
            tbody: [],
            action: [
               
            ],
            cle : [
                {
                    libelle: "matricule",
                    classe: 'text-center',
                    type: 'text',
                    count: 1
                },
                {
                    libelle: "nom",
                    classe: 'text-center',
                    type: 'text',
                    count: 1
                },
                {
                    libelle: "prenom",
                    classe: 'text-center',
                    type: 'text',
                    count: 1
                },
            ],

            header : [
                {
                    classe: '',
                    libelle: 'Matricule',
                },
                {
                    classe: '',
                    libelle: 'Nom',
                },
                {
                    classe: '',
                    libelle: 'Prénom',
                },
            ],
            objects : {
                breadcrumb: [
                    {
                        libelle: 'Etablissement',
                        name: "index-etablissement",
                        params : {libelle : this.$route.params.libelle}
                    },
                    {
                        libelle : this.$route.params.libelle,
                        name : "index-entite",
                        params : {libelle : this.$route.params.libelle}
                    },
                    {
                        libelle: 'Gestion des cours',
                        name: "cours",
                        params: { libelle: this.$route.params.libelle }
                    },
                    {
                        libelle: 'Programmation du cours',
                        name: "cours",
                        params: { libelle: this.$route.params.libelle }
                    },
                ],
                titlePage : 'Liste de présence ('+ this.$route.params.libelle+')',
                icon : 'fa fa-user'
            }
        }
    },

    async mounted() {
        if(this.getClasses.length == 0){
            await this.getClasse().then(() => {})
        }
        // this.getListePresenceEtudiant()
        // this.changePresenceEtudiant()
    },

    computed: {
        ...mapGetters('classes', ['getClasses']),
        ...mapGetters('cours', ['getCours', 'getEmploiTemps']),
        ...mapGetters('etablissements', ['getEtablissement']),
        ...mapGetters('etudiants', ['getEtudiants']),
        ...mapGetters(['getDataMenu'])
    },

    methods: {
        ...mapActions('classes', ['getClasse']),
        ...mapActions('cours', ['getCoursPeriode', 'sendDocumentCours']),
        ...mapActions('etablissements', ['getEntite']),
        ...mapActions('etudiants', ['getEtudiantClasse', 'listePresenceEtudiant']),

        getInfosOfClasse(idCours){
            this.classe = this.getClasses.filter((item) => item.libelle == this.getEmploiTemps.filter((element) => element.id == idCours)[0].classe)[0]
        },

        getListePresenceEtudiant() {
            this.$charging()
           
            let data = {
                idClasse: this.classe.id,
                idEntite: this.getEtablissement(this.$route.params.libelle).id,
                idEnseigner : this.idEnseigner,
            }
            this.getEtudiantClasse(data).then((response) => {
                // eslint-disable-next-line no-undef
               this.body = response.data.data.etudiants
                // eslint-disable-next-line no-undef
                this.$unCharging()
            }).catch((error) => {
                // eslint-disable-next-line no-undef
                Swal.fire({
                    allowOutsideClick : false,
                    title: "Oops...",
                    text: `${error.data.message}`,
                    icon: "error",
                })
                this.$unCharging()
            })
        },

        getReturnBack(){
            this.$router.push({name : 'cours-details', params : {libelle: this.$route.params.libelle}})
        },

        getBreadcrumb() {
            this.$emit('getBreadcrumb', this.objects)
        },

        changePresenceEtudiant(){
            // eslint-disable-next-line no-undef
            $(document).on('change', '.presence', (event) => {
                let idEtudiant = event.target.value
                this.getEtudiants.map((item) => {
                    if(item.id == idEtudiant){
                        item.presence = !item.presence
                    }
                })
                this.listeAbsent = this.getEtudiants.filter((item) => item.presence == false).map((element) => element.idInscription)
            })
        },

        sendListControle(){
            let idCompte = Base64.decode(Ls.get('user.id'))
            // eslint-disable-next-line no-undef
            $(document).on('click', '#sauvegarde', () => {
                this.$charging()
                let data = {
                    listAbsent : this.listeAbsent,
                    id_enseigner : this.idEnseigner,
                    id_compte : idCompte,
                    id_entite : this.getEtablissement(this.$route.params.libelle).id,
                }
                // eslint-disable-next-line no-undef
                $('#fermer').trigger('click');
                this.listePresenceEtudiant(data).then((response) => {
                    // eslint-disable-next-line no-undef
                    Swal.fire({
                        allowOutsideClick : false,
                        title: "Success",
                        text: `${response.data.message}`,
                        icon: "success",
                    })
                    this.$unCharging()
                }).catch((error) => {
                    // eslint-disable-next-line no-undef
                    Swal.fire({
                        allowOutsideClick : false,
                        title: "Oops...",
                        text: `${error.data.message}`,
                        icon: "error",
                    })
                        this.$unCharging()
                })
            })
        }
    },

    created() {
        this.getBreadcrumb()
    },

}
</script>