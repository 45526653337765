<template>
<div class="row" style="margin-left: 0px; margin-right : 0px;">
    <div class="col-md-12">
        <div class="main-card mb-3 card" style="padding: 1%;">
            <div class="card-header" style="justify-content: space-between;">
                <h6 class="font-weight-bold" style="font-size: 13px;"><i style="font-size: 13px;" class="fa fa-list header-icon icon-gradient bg-happy-itmeo"></i> {{ tableTitle }}</h6>
                <div v-if="body.length != 0" v-show="buttonShow">
                    <button @click.prevent="actionButton" class="btn-shadow btn btn-success">{{ buttonOptionSup }}</button>
                </div>
            </div>
            <div v-show="validateButton" class="card-header py-5">
                <div class="row" style="margin-left: 6%; margin-right : 0px; width:100%">
                    <div v-show="inputClasse" class="form-group col-md-4 col-xs-3">
                        <label for="select" style="font-weight: bold;">Choisir la classe</label>
                        <select id="select"  class="form-control" name="classe">
                            <option value="" selected>--- Toutes les classes ---</option>
                            <template v-for="(item, key) in data" :key="key">
                                <option :value="item.libelle">{{ item.libelle }} </option>
                            </template>
                        </select>
                    </div>
                    <div v-show="periode" class="col-md-7 col-xs-7">
                        <label for="" style="font-weight: bold;">Choisir la période</label>
                        <div class="input-group">
                            <div class="input-group-prepend">
                                <div class="input-group-text">
                                    De
                                </div>
                            </div>
                            <input type="date" class="form-control" name="" v-model="debut">
                            <div class="input-group-prepend">
                                <div class="input-group-text">
                                    A
                                </div>
                            </div>
                            <input type="date" class="form-control" name="" v-model="fin">
                        </div>
                    </div>
                    <div v-show="validateButton" class="col-md-1 col-xs-2" style="margin-top : 30px">
                        <button @click.prevent="emitActions" v-if="!isloading" class="btn-icon btn btn-success fw-bold">
                            <i class="fa fa-search"></i>
                        </button>
                        <button class="btn btn-success fw-bold" v-else disabled>
                            <img src="/assets/images/loading_2.gif" alt="" style="width: 15px;">
                        </button>
                    </div>
                </div>
                <hr>
            </div>
            <template v-if="body.length == 0">
                <div class="col-md-12 mt-3">
                    <div class="text-center">
                        <h6 class="font-weight-bold text-danger">
                            <i class="fa fa-info-circle"></i>
                            {{ noData }}
                        </h6>  
                        <p class="text-muted">Aucune information disponible</p>
                        <div v-show="buttonShow" class="mb-3">                                       
                            <button @click.prevent="actionButton" v-if="!isloading" class="mb-2 btn-shadow btn btn-primary">{{ buttonOptionSup }}</button>
                            <button class="mb-2 btn-shadow btn btn-primary" v-else disabled style="width: 200px;">
                                <img src="/assets/images/loading_2.gif" alt="" style="width: 10%;">
                                Patientez-svp...</button>
                        </div>
                    </div>
                </div>
            </template>
            <template v-else>
                <div v-if="tableau" class="table-responsive mt-2">
                    <table class="align-middle mb-0 table table-bordered table-striped table-hover" id="tableData">
                        <thead>
                            <tr class="text-white" style="background-color: teal;">
                                <th class="text-center">#</th>
                                <template v-for="(item, key) in header" :key="key">
                                    <th class="text-center"> {{ item.libelle }} </th>
                                </template>
                                <th class="text-center" v-if="action != false"> Actions </th>
                            </tr>
                        </thead>
                        <tbody>
                            <template v-for="(item, key) in body" :key="key">
                                <tr :class="{'bg-danger text-white' : (presence && item['presence'])}">
                                    <td class="">{{ ++key }}</td>
                                    <template v-if="presence">
                                        <td v-if="!item['presence']" class="text-center"><input type="checkbox" value="{{ item['id'] }}" name="presence[]" class="presence" checked="{{ item['presence'] }}"></td>
                                        <td v-else class="text-center"><input type="checkbox" value="{{ item['id'] }}" name="presence[]" class="presence"></td>
                                    </template>
                                    <template v-for="(data, id) in cle" :key="id">
                                        <td v-if="data.type == 'text'" :class="data.classe">{{ (item[data.libelle]) ? item[data.libelle] : '-' }}</td>
                                        <template v-else-if="data.type == 'bool'">
                                            <td v-if="data.count == 2" :class="[ (item[data.libelle[0]]) ? 'm-2 font-weight-bold text-success' : (!item[data.libelle[0]] && item[data.libelle[1]]) ? 'm-2 font-weight-bold text-danger' : 'm-2 font-weight-bold text-warning']">{{ item[data.libelle[0]] ? data.value[0] : (item[data.libelle[1]]) ? data.value[1] : data.value[2]}}</td>
                                            <td v-else :class="[ (item[data.libelle]) ? 'm-2 font-weight-bold text-success' : 'm-2 font-weight-bold text-danger']">{{ (item[data.libelle]) ? item[data.libelle] : '-' }}</td>
                                        </template>
                                        <td v-else-if="data.type == 'date'" :class="data.classe">{{ (item[data.libelle]) ? moment(item[data.libelle]) : '-' }}</td>
                                        <td v-else :class="data.classe">{{ (data.date) ? item[data.libelle[0]][data.libelle[1]] : item[data.libelle[0]][data.libelle[1]] }}</td>
                                    </template>
                                    <td class="" v-if="action != false">
                                        <template  v-for="(el, key) in action" :key="key">
                                            <template v-if="el.condition == false">
                                                    <a :data-id="item.id" :data-enseigner="item.idEnseigner" href="#" @click.prevent="el.function" style="cursor: pointer;" :title="el.title">
                                                    <template v-if="el.type == 'button'">
                                                        <button class="mr-2 mb-2" :data-id="item.id" :data-enseigner="item.idEnseigner" :class="el.buttonclasse">
                                                            <i :data-id="item.id" :data-enseigner="item.idEnseigner" class="btn-icon-wrapper" :class="el.class"></i> {{ el.buttonText }}
                                                        </button>
                                                    </template>
                                                    <template v-else>
                                                        <i :data-id="item.id" :data-enseigner="item.idEnseigner" :class="el.class"></i>
                                                    </template>
                                                    </a>
                                            </template>
                                            <template v-else>
                                                <a :data-id="item.id" :data-enseigner="item.idEnseigner" v-show="condition(item, el.element, el.condition)" href="#" @click.prevent="el.function" style="cursor: pointer;" :title="el.title">
                                                    <template v-if="el.type == 'button'">
                                                        <button class="mr-2 mb-2" :data-id="item.id" :data-enseigner="item.idEnseigner" :class="el.buttonclasse">
                                                            <i :data-id="item.id" :data-enseigner="item.idEnseigner" class="btn-icon-wrapper" :class="el.class"></i> {{ el.buttonText }}
                                                        </button>
                                                    </template>
                                                    <template v-else>
                                                        <i :data-id="item.id" :data-enseigner="item.idEnseigner" :class="el.class"></i>
                                                    </template>
                                                </a>
                                            </template>
                                        </template>
                                    </td>
                                </tr>
                            </template>
                        </tbody>
                    </table>
                </div>
                <div v-else class="container">
                    <div class="vertical-timeline vertical-timeline--animate vertical-timeline--one-column">
                        <div class="vertical-timeline-item vertical-timeline-element" v-for="(item, index) in body_2" :key="index">
                            <div>
                                <span class="vertical-timeline-element-icon bounce-in">
                                    <i class="badge badge-dot badge-dot-xl badge-success"></i>
                                </span>
                                <div class="vertical-timeline-element-content bounce-in">
                                    <template v-for="(programme, index2) in item" :key="index2">
                                        <div class="card mb-3" style="margin-left : 5%; margin-right : 15%">
                                            <div class="card-body">
                                                <h4 class="timeline-title">Matière : {{ programme.libelleMatiere }}</h4>
                                                <h6 style="margin-right : 10px; font-weight : bold">Classe : {{ programme.classe }}</h6>
                                                <h6 style="margin-right : 10px; font-weight : bold">Horaire : {{ programme.horaire }}</h6>
                                                <h6 style="margin-right : 10px; font-weight : bold">Salle : {{ programme.SalleName }}</h6>
                                                <p :class="[(programme.statut == 'Exécuter') ? 'badge badge-primary' : ((programme.statut == 'Annuler') ? 'badge badge-danger' : 'badge badge-warning')]" style="color : white">
                                                   {{ programme.statut }}
                                                </p>
                                            </div>
                                        </div>
                                    </template>
                                    <span class="vertical-timeline-element-date" style="color : black; font-weight : bold">{{ moment(index) }}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </template>
        </div>
    </div>
</div>
</template>

<script>

export default {
    data() {
        return {
            // eslint-disable-next-line no-undef
            debut : (this.$route.params.dateDebut) ? this.$route.params.dateDebut : moment().weekday(1).format("YYYY-MM-DD"),
            // eslint-disable-next-line no-undef
            fin : (this.$route.params.dateFin) ? this.$route.params.dateFin : moment().weekday(7).format("YYYY-MM-DD"),
            classe : ''
        }
    },
    props: {
        body: {
            type: Object
        },
        header: {
            type: Object
        },
        cle: {
            type: Object
        },
        tableTitle: {
            type: String
        },
        noData : {
            type : String
        },  
        buttonOptionSup : {
            type : String
        },
        title1 : {
            type : String
        },
        title2 : {
            type : String
        },
        actionButton : {
            type : Function
        },
        filtreActions : {
            type : Function
        },
        isloading : {
            type : Boolean,
            default : false
        }, 
        tableau : {
            type : Boolean,
            default : true
        }, 
        buttonShow : {
            type : Boolean,
            default : true
        },
        presence : {
            type : Boolean,
            default : false
        },
        action : {
            type : Object
        }, 
        data : {
            type : Object
        },
        body_2 : {
            type : Object
        },
        periode : {
            type : Boolean,
            default : false
        },
        inputClasse : {
            type : Boolean,
            default : false
        },
        validateButton : {
            type : Boolean,
            default : false
        },
        datedebut : {
            type : String,
            required : false
        },

        datefin : {
            type : String,
            required : false
        },
    },

    async updated(){
        if(this.body.length != 0){
            // eslint-disable-next-line no-undef
            $('#tableData').DataTable()
        }
    },

    async mounted(){
        if(this.$route.params.datedebut && this.$route.params.datefin){
            this.debut = this.datedebut
            this.fin = this.datefin
        }
        // eslint-disable-next-line no-undef
        let element = $('#select')
        element.select2()
        
        element.on('change', () => {
            let val = {}
            if(this.periode && this.inputClasse){
                val = {
                    debut : this.debut,
                    fin : this.fin,
                    classe : element.val()
                }
            }else{
                val = {
                    classe : element.val()
                }
            }
            this.$emit('onchange', val)
        })
    },

    methods: {
        moment: function (date) {
            // eslint-disable-next-line no-undef
            let day =  moment(date).locale('fr').format('llll');
            const str2 = day.charAt(0).toUpperCase() + day.slice(1);
            return str2;
        },

        condition(item, element, condition) {
            let result = 1;
            element.map((value, key) => {
                let save = (item[value] == null || item[value] == undefined)? false : item[value];
                let saveCond = (condition[key] == null || condition[key] == undefined)? false : condition[key];
                if(save != saveCond){
                    result = false;
                }
            });

            return result;
        },

        emitActions(){
            let val = {}
            if(this.periode && this.inputClasse){
                val = {
                    debut : this.debut,
                    fin : this.fin,
                    classe : this.classe
                }
            }else if(this.periode && !this.inputClasse){
                val = {
                    debut : this.debut,
                    fin : this.fin,
                }
            }else{
                val = {
                    classe : this.classe
                }
            }
            this.$emit(this.filtreActions, val);
        }
    }
}
</script>
