/* eslint-disable no-undef */

export default {
    methods: {
        $tabTemplateForModal(header, data, cle, type = null){
            let thead = '';
            let tbody = '';
            thead += `<th class="text-center">#</th>`
            if(data.length != 0){
                if(data[0]['dateCours']){
                    thead += `<th class="text-center">Date</th>`
                }
                if(data[0]['presence'] && type == 'presence'){
                    thead += `<th class="text-center">Présence</th>`
                }
            }
            header.map(element => {  thead += `<th class="text-center">${element.libelle}</th>`});
            if(data.length != 0){
                data.map((element, key) => {
                    if(type == 'presence'){ 
                        if(element['presence']){
                            tbody+= `<tr>`                       
                        }else{
                            tbody+= `<tr class="bg-danger text-white">`                       
                        } 
                    }else if(type == 'recapEtu'){ 
                        if(element['nbrPresence'] == 0){
                            tbody+= `<tr>`                       
                        }else{
                            tbody+= `<tr class="bg-danger text-white">`                       
                        } 
                    }else{
                        tbody+= `<tr>`
                    }
                    tbody += `<td class="">${key+1}</td>`
                    if(element['dateCours']){ 
                        let locale = moment(element['dateCours']).locale('fr').format('llll')
                        const str2 = locale.charAt(0).toUpperCase() + locale.slice(1);
                        tbody += `<td class="text-center">${str2}</td>`
                    }
                    if(type == 'presence'){ 
                        tbody += `<td class="text-center"><input type="checkbox" data-key="${key}" value="${element['id']}" name="presence[]" class="presence" ${element['presence'] ? "checked" : ""}></td>`
                    }
                    cle.map((item) => {
                        if(item.type == "text"){
                            if(type == 'recap'){
                                tbody += `<td class="${item.classe}">${(element[item.libelle]) ? element[item.libelle] : '0'}</td>`
                            }else if(item.libelle == "motif"){
                                tbody += `<td class="${item.classe}"><textarea cols="5" rows="1" data-key="${key}" class="form-control motif" id="motif_${key}"  value="${(element[item.libelle]) ? element[item.libelle] : ""}" ${element['presence'] ? "disabled" : ""}></textarea></td>`
                            }else{
                                tbody += `<td class="${item.classe}">${(element[item.libelle]) ? element[item.libelle] : '-'}</td>`
                            }
                        }else if(item.type == "bool"){
                            if(item.count == 2){
                                if(element[item.libelle[0]]){
                                    tbody += `<td class="m-2 text-center text-success">${(item.value[0]) ? item.value[0] : '-'}</td>`
                                }else if(element[item.libelle[1]]){
                                    tbody += `<td class="m-2 text-center text-danger">${(item.value[1]) ? item.value[1] : '-'}</td>`
                                }else{
                                    tbody += `<td class="m-2 text-center text-warning">${(item.value[2]) ? item.value[2] : '-'}</td>`
                                }
                            }
                        }else{
                            tbody += `<td class="${item.classe}">${element[item.libelle[0]][item.libelle[1]]}</td>`
                        }
                    })
                    tbody+= `</tr>`
                });
            }else{
                tbody += `<tr><td colspan="6" class="text-center">Aucun cours pour cette semaine</td></tr>`
            }
            
            return `<div class="table-responsive"><table class="align-middle mb-0 table table-bordered table-striped table-hover" id="tab2">
                <thead style="background-color: teal; color : white">
                    <tr>`+ thead +`</tr>
                </thead>
                <tbody>`+tbody+`</tbody>
            </table></div>`;
        },


        $formulaireDepotDocument(){
            return `
            <form id="formDoc">
                <div class="row mb-2">
                    <div class="col-md-6 col-xs-6">
                        <label for="select7" style="font-weight: bold;">Type de document<sup style="color:red">*</sup></label>
                        <select id="select7"  class="form-control form-select-solid" name="classe">
                            <option value="">--- Choisir une option ---</option>
                            <option value="cours"> Document de cours </option>
                            <option value="exo"> Exercice </option>
                        </select>
                    </div>
                    <div class="form-group col-md-6 col-xs-6">
                        <label for="libelle" style="font-weight: bold;">Libellé du fichier<sup style="color:red">*</sup></label>
                        <input id="libelle" type="text" class="form-control form-control-solid" name="libelle">
                    </div> 
                </div>
                <div id="doc"></div>
            </form>`
        },

        $footerModalButton(idFermer, idSauvegarder, idloading){
            return `<button type="button" class="btn btn-danger" id="${idFermer}" data-dismiss="modal"></button>
                    <button type="button" class="btn btn-primary" id="${idSauvegarder}"></button>
                    <button  class="btn btn-primary" id="${idloading}" disabled style="display: none; width: 200px;">
                        <img src="/assets/images/loading_2.gif" alt="" style="width: 10%;">
                        Patientez-svp...
                    </button>`
        },

        $charging(){
            return $.blockUI(
                { 
                    message: `<h5 style="margin-top : 1%"><img src="/assets/images/loading_2.gif" alt="" style="width: 5%; margin-right: 2%">Chargement...</h5>` ,
                    css : {
                        border: 'none', 
                        padding: '1%',
                        //width : '25%',
                    }
                }
            );
        },
        
        $unCharging(){
            return $.unblockUI();
        },

        $moment(date) {
            // eslint-disable-next-line no-undef
            let day =  moment(date).locale('fr').format('llll');
            const str2 = day.charAt(0).toUpperCase() + day.slice(1);
            return str2;
        },

        $addHeadLink(href){
            let head = document.querySelector('head');
            let link = document.createElement('link');
            link.setAttribute("href", href);
            link.setAttribute("rel", "stylesheet");
            link.setAttribute("type", "text/css");
            head.appendChild(link)
        },

        $removeHeadLink(href){
            let head = document.querySelector('head');
            let link = document.querySelector("link[href='"+href+"']");
            head.removeChild(link)
        },

        $addBodyScript(script){
            let link = document.createElement('script');
            link.setAttribute("src", script);
            document.body.appendChild(link)
        },

        $addHeaderScript(script){
            let head = document.querySelector('head');
            let link = document.createElement('script');
            link.setAttribute("src", script);
            head.appendChild(link)
        },

        $removeBodyScript(script){
            let link = document.querySelector("script[src='"+script+"']")
            document.body.removeChild(link)
        },

    },
}